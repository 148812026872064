import ApiService from "../services/ApiService";

export default {

    getNotifications(user_id, net_id) {
        return ApiService.get(`/user/${user_id}/net/${net_id}/notifications`);
    },
    seen(user_id, net_id) {
        return ApiService.get(`/user/${user_id}/net/${net_id}/notifications/seen`);
    },
}
