<template src="./template.html"></template>

<script>
import Vue from 'vue';

// import services
import RequestService from "../../services/user/RequestService";
import SessionsService from "../../services/user/SessionsService";

export default {
    name: 'PublicSessionTable',
    props: ['user', 'networkId'],
    data(){
        return{
            peered_requests: [],
            peer_request_loader: false,
            showNoRequests: false,
            selectedRows: [],
            numberOfSelected: 0,
            open: false,
            selectedDisplay: 'all',
            internetIx: [],
            routerInfo: [],
            modalRouterInfo: false,
            searchQuery: '',
            timer: null,
            perPage: 50,
            currentPage: 1,
            totalRows: 0,
            interval: null,
            modalDePeerRequest: false,
            selectedDePeer: null,
            modalDePeersRequest: false,
            de_peer_note: null,
            routers_sessions: [],
            showSessionModal: false,   
            selectedEvent: null,         
            router_info_loader: false,
            opened: [],
            selected_v6_session: 0,
            selected_v4_session: 0,
            v6_session_exists: false,
            v4_session_exists: false,
            note_of_selected_sessions: '',
            sessionNoteModal: false,
            sessionNote: '',
            selectedSessionNote_id: null,
        }
    },
    watch: {
        'networkId': async function (value)  {
            this.networkId = value;
            await this.getAvailablePublicSessions(this.user.id, this.networkId, this.$route.params.id);
        },
        'searchQuery': async function() {
            // setTimeout -> when user stop typing trigger serach method after 0.5 seconds
            if(this.timer) {
                clearTimeout(this.timer);
                this.timer = null;
            }
            this.timer = setTimeout(() => {
                if(this.searchQuery.length > 2){
                    this.totalRows = 0;
                    this.searchMethod(this.user.id, this.networkId, this.selectedDisplay, this.searchQuery);
                }else if(this.searchQuery.length == 0 && this.selectedDisplay != 'all'){
                    this.searchMethod(this.user.id, this.networkId, this.selectedDisplay, this.searchQuery);
                }else if(this.searchQuery.length == 0 && this.selectedDisplay == 'all'){
                    this.getAvailablePublicSessions(this.user.id, this.networkId, this.$route.params.id);
                }
            }, 500);
        }
    },
    created(){
        this.getAvailablePublicSessions(this.user.id, this.networkId, this.$route.params.id);
        this.getIxesForPublicSessions(this.user.id, this.networkId, this.$route.params.id);
    },
    methods:{
        openSessionNoteModal(peering_id){
            this.sessionNoteModal = true;
            this.selectedSessionNote_id = peering_id;
            this.checkForIpv4AndIpv6Sessions(peering_id);
        },
        hideSessionNoteModal(){
            this.sessionNoteModal = false;
        },
        saveSessionNote(){
            const formData = new FormData;
            formData.append('note', this.sessionNote);
            formData.append('session_id', this.selectedSessionNote_id);
            formData.append('v4_session', this.selected_v4_session);
            formData.append('v6_session', this.selected_v6_session);
            if(this.sessionNote != ''){
                SessionsService.updateNote(this.user.id, this.networkId, formData).then(response => {
                    if(response.data){
                        if(this.selectedDisplay != 'all'){
                            this.searchMethod(this.user.id, this.networkId, this.selectedDisplay, 'ALL');
                        }else{
                            this.getAvailablePublicSessions(this.user.id, this.networkId, this.$route.params.id);
                        }
                        Vue.$toast.success('Session note updated!',{
                            position: 'top-right'
                        });
                        this.sessionNoteModal = false;
                        this.sessionNote = '';
                        this.selected_v6_session = 0;
                        this.selected_v4_session = 0;
                    }
                }).catch(error => {
                    if(error.response.status === 401){
                        this.$store.dispatch('logout');
                        this.$router.push('/')
                    }
                    if( (error.response.status !== 500 && error.response.status !== 400) && error.response.data.error){
                        Vue.$toast.error(error.response.data.error, {
                            position: 'top-right'
                        });
                    }else if(error.response.status !== 500 && error.response.status !== 400){
                        Vue.$toast.error(error.response.data, {
                            position: 'top-right'
                        });
                    }else if(error.response.status === 500 || error.response.status == 400){
                        Vue.$toast.error('Oooops! Something went wrong!', {
                            position: 'top-right'
                        });
                    }
                });
            }else{
                alert('Note field is required!');
            }
            
        },
        toggle(id, event) {
            const index = this.opened.indexOf(id);
            var el = event.target.parentNode;
            var open = el.getElementsByClassName('open')
            var closed = el.getElementsByClassName('closed');
            if(closed.length == 0 && open.length == 0){
                var new_el = event.target.parentNode.parentNode;
                var new_close = new_el.getElementsByClassName('closed');
                var new_open = new_el.getElementsByClassName('open');
                if(new_open.length > 0 && new_close.length > 0){
                    if(new_open[0].classList.contains('hidden')){
                        new_close[0].classList.add('hidden');
                        new_open[0].classList.remove('hidden');
                    }else{
                        new_close[0].classList.remove('hidden');
                        new_open[0].classList.add('hidden');
                    }
                }
            }else{
               if(open[0].classList.contains('hidden') == true){
                   closed[0].classList.add('hidden');
                   open[0].classList.remove('hidden');
               }else{
                   closed[0].classList.remove('hidden');
                   open[0].classList.add('hidden');
               }
            }

            if (index > -1) {
                this.opened.splice(index, 1)
            } else {
                this.opened.push(id)
            }
        },
        confirmDialog(value, event){
            if(value == true){
                var parentElement = this.selectedEvent.target.parentNode;
                if(parentElement.tagName == 'svg'){
                    parentElement = parentElement.parentNode;
                }
                parentElement.getElementsByClassName('session_loader')[0].classList.remove('hidden');
                parentElement.getElementsByClassName('dePeer_button')[0].classList.add('hidden');
                event.target.disabled = true;
                const formData = new FormData;
                formData.append('peering_id', this.selectedDePeer);
                formData.append('v4_session', this.selected_v4_session);
                formData.append('v6_session', this.selected_v6_session);
                formData.append('de_peer_note', this.de_peer_note);
                
                SessionsService.dePeerSession(this.user.id, this.networkId, formData).then(response =>{
                    this.deSelectAllCheckbox();
                    var hellper = this.peered_requests;
                    this.peered_requests = [];
                
                    hellper.map(item => {
                        if(item.id != response.data.id){
                            this.peered_requests.push(item);
                        }
                    });
                    // show user message
                    Vue.$toast.success('Session deleted!',{
                        position: 'top-right'
                    });

                    if(this.searchQuery == ''){
                        this.getAvailablePublicSessions(this.user.id, this.networkId, this.$route.params.id);
                    }else{
                        this.searchMethod(this.user.id, this.networkId, this.selectedDisplay, this.searchQuery);
                    }
                    this.$emit('change-peered', response.data.length);

                    parentElement.getElementsByClassName('session_loader')[0].classList.add('hidden');
                    parentElement.getElementsByClassName('dePeer_button')[0].classList.remove('hidden');
                    event.target.disabled = false;
                }).catch(error => {
                    if(error.response.status === 401){
                        this.$store.dispatch('logout');
                        this.$router.push('/')
                    }
                    if( (error.response.status !== 500 && error.response.status !== 400) && error.response.data.error){
                        Vue.$toast.error(error.response.data.error, {
                            position: 'top-right'
                        });
                    }else if(error.response.status !== 500 && error.response.status !== 400){
                        Vue.$toast.error(error.response.data, {
                            position: 'top-right'
                        });
                    }else if(error.response.status === 500 || error.response.status == 400){
                        Vue.$toast.error('Oooops! Something went wrong!', {
                            position: 'top-right'
                        });
                    }

                    parentElement.getElementsByClassName('session_loader')[0].classList.add('hidden');
                    parentElement.getElementsByClassName('dePeer_button')[0].classList.remove('hidden');
                    event.target.disabled = false;
                });
            }else{
                this.modalDePeerRequest = false;
                this.selectedDePeer = null;
            }
            this.modalDePeerRequest = false;
            this.selectedDePeer = null;
        },

        confirmBulkDialog(value, event){
            if(value == true){
                event.target.disabled = true;
                const formData = new FormData;
                formData.append('peering_ids', this.selectedRows);
                formData.append('de_peer_note', this.de_peer_note);
                
                SessionsService.dePeerSelectedSessions(this.user.id, this.networkId, formData).then(response =>{
                    if(response.data){
                        if(this.selectedRows.length < 15){
                            // de-select checked checkbox
                            this.deSelectAllCheckbox();
                            this.selectedRows = [];
                        }else{
                            let columns = document.querySelectorAll('.masterCheckbox');
                            this.numberOfSelected = 0;
                            columns.forEach(column => {
                                column.checked = false
                            });
                        }

                        if(this.searchQuery == ''){
                            this.getAvailablePublicSessions(this.user.id, this.networkId, this.$route.params.id);
                        }else{
                            this.searchMethod(this.user.id, this.networkId, this.selectedDisplay, this.searchQuery);
                        }
                    
                        // show user message
                        if(this.selectedRows.length > 1){
                            Vue.$toast.success('Sessions deleted!',{
                                position: 'top-right'
                            });
                        }else{
                            Vue.$toast.success('Session deleted!',{
                                position: 'top-right'
                            });
                        } 
                        event.target.disabled = false;

                    }
            
                }).catch(error => {
                    if(error.response.status === 401){
                        this.$store.dispatch('logout');
                        this.$router.push('/')
                    }
                    if( (error.response.status !== 500 && error.response.status !== 400) && error.response.data.error){
                        Vue.$toast.error(error.response.data.error, {
                            position: 'top-right'
                        });
                    }else if(error.response.status !== 500 && error.response.status !== 400){
                        Vue.$toast.error(error.response.data, {
                            position: 'top-right'
                        });
                    }else if(error.response.status === 500 || error.response.status == 400){
                        Vue.$toast.error('Oooops! Something went wrong!', {
                            position: 'top-right'
                        });
                    }

                    event.target.disabled = false;
                });
            }
            this.modalDePeersRequest = false;
        },
        //bulk dePeer
        bulkDePeer(){
            this.modalDePeersRequest = true;       
        },
        checkForIpv4AndIpv6Sessions(peering_id){
            // check does exists v4 session or v6 session for selected session
            this.peered_requests.forEach( peered => {
                if(peered.id == peering_id){
                    this.note_of_selected_sessions = peered.note;
                    if(peered.v4_session == 1){
                        var item_v4 = this.peered_requests.filter(element => (element.from_network_ixlan_id == peered.from_network_ixlan_id && element.to_network_ixlan_id == peered.to_network_ixlan_id ) );
                        this.v4_session_exists = item_v4.filter(element => (element.v6_session == 1 ) ).length > 0;
                        this.v6_session_exists = false;
                    }else if(peered.v6_session == 1){
                        var item_v6 = this.peered_requests.filter(element => (element.from_network_ixlan_id == peered.from_network_ixlan_id && element.to_network_ixlan_id == peered.to_network_ixlan_id ) );
                        this.v6_session_exists = item_v6.filter(element => (element.v4_session == 1 ) ).length > 0;
                        this.v4_session_exists = false;
                    }
                }
            })
        },
        //dePeer
        singleDePeer(peering_id, event){
            this.selectedEvent = event;
            this.selectedDePeer = peering_id;
            this.selected_v6_session = false;
            this.selected_v4_session = false;
            this.note_of_selected_sessions = '';
            this.checkForIpv4AndIpv6Sessions(peering_id);
            this.modalDePeerRequest = true;     
        },
        selectDisplay(){
            this.totalRows = 0;
            this.peered_requests = [];

            if(this.searchQuery == '' && this.selectedDisplay != 'all'){
                 this.searchMethod(this.user.id, this.networkId, this.selectedDisplay, 'ALL');
            }else{
                this.searchMethod(this.user.id, this.networkId, this.selectedDisplay, this.searchQuery);
            }
        },
        searchMethod(user_id, networkId, selectedDisplay, searchQuery){
            if(searchQuery == ''){
                searchQuery = 'ALL';
            }
            SessionsService.serachSessions(user_id, networkId, selectedDisplay, searchQuery).then(response => {
                this.peered_requests = response.data.data;
                this.totalRows = response.data.total;
            }).catch(error => {
                if(error.response.status === 401){
                    this.$store.dispatch('logout');
                    this.$router.push('/')
                }
                if( (error.response.status !== 500 && error.response.status !== 400) && error.response.data.error){
                    Vue.$toast.error(error.response.data.error, {
                        position: 'top-right'
                    });
                }else if(error.response.status !== 500 && error.response.status !== 400){
                    Vue.$toast.error(error.response.data, {
                        position: 'top-right'
                    });
                }else if(error.response.status === 500 || error.response.status == 400){
                    Vue.$toast.error('Oooops! Something went wrong!', {
                        position: 'top-right'
                    });
                }
            });
        },
        changePage(){
            this.getAvailablePublicSessions(this.user.id, this.networkId, this.$route.params.id);
        },
        closeModalRouterInfo(value){
            this.routerInfo = [];
            this.modalRouterInfo = value;
        },
        getRouterInformation(network_ixlan_id, net_id){
            this.modalRouterInfo = true;
            this.router_info_loader = true;
            RequestService.getRouter(this.user.id, net_id, network_ixlan_id).then(response =>{
                this.routerInfo = response.data;
                this.router_info_loader = false;
            }).catch(error => {
                if(error.response.status === 401){
                    this.$store.dispatch('logout');
                    this.$router.push('/')
                }
                if( (error.response.status !== 500 && error.response.status !== 400) && error.response.data.error){
                    Vue.$toast.error(error.response.data.error, {
                        position: 'top-right'
                    });
                }else if(error.response.status !== 500 && error.response.status !== 400){
                    Vue.$toast.error(error.response.data, {
                        position: 'top-right'
                    });
                }else if(error.response.status === 500 || error.response.status == 400){
                    Vue.$toast.error('Oooops! Something went wrong!', {
                        position: 'top-right'
                    });
                }
                this.router_info_loader = true;
            });
        },
        getIxesForPublicSessions(user_id, networkId, peer){
            SessionsService.getPublicSessionsIxes(user_id, networkId, peer).then(response => {
                var ixes = response.data;
                ixes.forEach(ix => {
                    if(this.internetIx.filter(element => ix.ix_name == element.ix_name).length == 0){
                        this.internetIx.push(ix);
                    }
                });
                // sort alphabeticly
                this.internetIx.sort(function(a, b) {
                    return a.ix_name.localeCompare(b.ix_name);
                });
            }).catch(error => {
                if(error.response.status === 401){
                    this.$store.dispatch('logout');
                    this.$router.push('/')
                }
                if( (error.response.status !== 500 && error.response.status !== 400) && error.response.data.error){
                    Vue.$toast.error(error.response.data.error, {
                        position: 'top-right'
                    });
                }else if(error.response.status !== 500 && error.response.status !== 400){
                    Vue.$toast.error(error.response.data, {
                        position: 'top-right'
                    });
                }else if(error.response.status === 500 || error.response.status == 400){
                    Vue.$toast.error('Oooops! Something went wrong!', {
                        position: 'top-right'
                    });
                }
            });
        },
        deSelectAllCheckbox() {
            let columns = document.querySelectorAll('.rowCheckbox');
            this.numberOfSelected = 0;
            columns.forEach(column => {
                column.checked = false
            });
        },
        selectAllCheckbox($event) {
            let columns = document.querySelectorAll('.rowCheckbox');
            this.selectedRows = [];

            if ($event.target.checked == true) {
                columns.forEach(column => {
                    column.checked = true
                    this.selectedRows.push(parseInt(column.name))
                });
            }else{
                columns.forEach(column => {
                    column.checked = false
                });
                this.selectedRows = [];
            }
            this.numberOfSelected = this.selectedRows.length;
        },
        getRowDetail($event, id){
            let rows = this.selectedRows;

            if (rows.includes(id)) {
                let index = rows.indexOf(id);
                rows.splice(index, 1);
            }else{
                rows.push(id);
            }
            this.selectedRows = rows;
            this.numberOfSelected = this.selectedRows.length;
        },
        getAvailablePublicSessions(user_id, net_id, peer){
            this.peered_requests = [];
            this.filteredPeers = [];
            this.peer_request_loader = true;
            this.showNoRequests = true;
            SessionsService.getPublicSessions(user_id, net_id, peer, this.currentPage).then(response =>{
                this.peered_requests = response.data.data;
                this.totalRows = response.data.total;
                this.filteredPeers = this.peered_requests;
                this.peer_request_loader = false;
                if(this.totalRows > 0){
                    this.showNoRequests = true;
                }else{
                    this.showNoRequests = false;
                }
                
            }).catch(error =>{
                this.peer_request_loader = false;
                this.showNoRequests = false;
                if(error.response.status === 401){
                    this.$store.dispatch('logout');
                    this.$router.push('/')
                }
                if( (error.response.status !== 500 && error.response.status !== 400) && error.response.data.error){
                    Vue.$toast.error(error.response.data.error, {
                        position: 'top-right'
                    });
                }else if(error.response.status !== 500 && error.response.status !== 400){
                    Vue.$toast.error(error.response.data, {
                        position: 'top-right'
                    });
                }else if(error.response.status === 500 || error.response.status == 400){
                    Vue.$toast.error('Oooops! Something went wrong!', {
                        position: 'top-right'
                    });
                }
            });
        },
    }
}
</script>