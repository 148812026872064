import Vue from 'vue'
import App from './App.vue'
import router from './router'
Vue.config.devtools = true //Remove it before deploying to production
import VueClipboard from 'vue-clipboard2'
import store from './store'

import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-default.css';
require('@/assets/styles/index.css')

import moment from 'moment';
import {cookieMixin} from './mixins/cookieMixin.js';

Vue.prototype.$cookiemixin = cookieMixin


Vue.filter('formatDate', function(value) {
    if (value) {
        return moment(String(value)).format('YYYY-MM-DD HH:mm:ss') + ' (UTC)'
    }
});

import { library } from '@fortawesome/fontawesome-svg-core'
import { faUserSecret } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faKey } from '@fortawesome/free-solid-svg-icons'
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons'
import { faEye } from '@fortawesome/free-solid-svg-icons'
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons'
import { faCogs } from '@fortawesome/free-solid-svg-icons'
import { faThLarge } from '@fortawesome/free-solid-svg-icons'
import { faNetworkWired } from '@fortawesome/free-solid-svg-icons'
import { faServer } from '@fortawesome/free-solid-svg-icons'
import { faFileSignature } from '@fortawesome/free-solid-svg-icons'
import { faChartPie } from '@fortawesome/free-solid-svg-icons'
import { faSlidersH } from '@fortawesome/free-solid-svg-icons'
import { faPen } from '@fortawesome/free-solid-svg-icons'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import { faGlobe } from '@fortawesome/free-solid-svg-icons'
import { faBan } from '@fortawesome/free-solid-svg-icons'
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { faWindowClose } from '@fortawesome/free-solid-svg-icons'
import { faFileImport } from '@fortawesome/free-solid-svg-icons'
import { faFileExport } from '@fortawesome/free-solid-svg-icons'
import { faHdd } from '@fortawesome/free-solid-svg-icons'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { faBroadcastTower } from '@fortawesome/free-solid-svg-icons'
import { faTh } from '@fortawesome/free-solid-svg-icons'
import { faTools } from '@fortawesome/free-solid-svg-icons'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { faChevronUp } from '@fortawesome/free-solid-svg-icons'
import { faBell } from '@fortawesome/free-solid-svg-icons'
import { faTasks } from '@fortawesome/free-solid-svg-icons'
import { faUserShield } from '@fortawesome/free-solid-svg-icons'
import { faCheck } from '@fortawesome/free-solid-svg-icons'

library.add(faCheck)
library.add(faUserShield)
library.add(faTasks)
library.add(faBell)
library.add(faChevronUp)
library.add(faChevronDown)
library.add(faTools)
library.add(faTh)
library.add(faBroadcastTower)
library.add(faTimes)
library.add(faHdd)
library.add(faFileImport)
library.add(faFileExport)
library.add(faUserSecret)
library.add(faKey)
library.add(faEye)
library.add(faExternalLinkAlt)
library.add(faPaperPlane)
library.add(faCogs)
library.add(faThLarge)
library.add(faNetworkWired)
library.add(faServer)
library.add(faFileSignature)
library.add(faChartPie)
library.add(faSlidersH)
library.add(faPen)
library.add(faInfoCircle)
library.add(faGlobe)
library.add(faBan)
library.add(faTrashAlt)
library.add(faCheckCircle)
library.add(faTimesCircle)
library.add(faWindowClose)


Vue.use(VueToast);

import TrendChart from "vue-trend-chart";
Vue.use(TrendChart);

Vue.use(VueClipboard)

import VueTailwind from 'vue-tailwind'

const settings = {
  //...
}


Vue.use(VueTailwind, settings)

Vue.component('font-awesome-icon', FontAwesomeIcon)

import('@/assets/styles/index.css');

Vue.config.productionTip = false;
Vue.config.devtools = false

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
