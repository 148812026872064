<template src="./template.html"></template>

<script>

// import components
import DashboardTopNav from "../../components/DashboardTopNavComponent/DashboardTopNavComponent";
import SideBarNavigation from "../../components/SidebarNavigationComponent/SidebarNavigationComponent";
import Footer from '../../components/FooterDashboardComponent/FooterDashboardComponent';
import PublicDevice from '../../components/PublicDeviceComponent/PublicDeviceComponent';
import PrivateDevice from '../../components/PrivateDeviceComponent/PrivateDeviceComponent';

export default {
    name: 'DeviceSettings',
    components:{
        DashboardTopNav,
        SideBarNavigation,
        Footer,
        PublicDevice,
        PrivateDevice
    },
    data(){
        return{
            page: null,
            user: {},
            networkId: null,
            network_id: null,
            private_device: false,
            my_routers: true,
            background_of_modal: false
        }
    },
    async created(){
        this.page = this.$route.name.toLowerCase();
        // get user first net_id
        if(this.$store.getters.user.id){
            if(localStorage.getItem('activeNet')){
                this.networkId = localStorage.getItem('activeNet');
            }else{
                if(this.$store.getters.user.networks){
                    this.networkId  = this.$store.getters.user.networks[0].peeringdb_network_id;
                }
            }
            this.user  = {
                id: this.$store.getters.user.id,
            };
        }

        // set Internet Exchange
        this.network_id = this.networkId;
        if(this.network_id){
            const ix = this.networkId;
            this.ixes = ix;
        }
        
    },
    methods:{
        modalChange(modal){
            if(modal){
                this.background_of_modal = true;
            }else{
                this.background_of_modal = false;
            }
        },  
        // onChange user net
        async networkChanged(network_id){
            if(network_id){
                this.network_id = network_id;
                this.networkId = network_id;
                await this.getAllRouters(this.user.id, this.networkId);
            }
            
        },

        activeTab(tab){
            if(tab == 'private_device'){

                this.private_device = true;
                this.my_routers = false;

                document.getElementsByClassName(tab)[0].classList.add('border-b');
                document.getElementsByClassName(tab)[0].classList.add('border-blue-700');

                document.getElementsByClassName('my_routers')[0].classList.remove('border-b');
                document.getElementsByClassName('my_routers')[0].classList.remove('border-blue-700');
                 
            }else if(tab == 'my_routers'){

                this.private_device = false;
                this.my_routers = true;
                 
                document.getElementsByClassName(tab)[0].classList.add('border-b');
                document.getElementsByClassName(tab)[0].classList.add('border-blue-700');

                document.getElementsByClassName('private_device')[0].classList.remove('border-b');
                document.getElementsByClassName('private_device')[0].classList.remove('border-blue-700');
                
            }
        },
    }
}
</script>