<template src="./template.html"></template>

<script>
import Vue from 'vue';

// import services
import PrivateDeviceService from '../../services/user/PrivateDeviceService';
import RouterServce from '../../services/user/RouterService';
import NetworkFacilityService from '../../services/user/NetworkFacility';

export default {
    name: 'PrivateDevices',
    props: ['user', 'networkId'],
    data(){
        return{
            private_device_modal: false,
            private_devices: [],
            private_router_type: '',
            private_router_name: null,
            router_types: [],
            private_facilities: [],
            private_facility_id: '',

        }
    },
    async created(){
        await this.routerTypes();
        await this.getAllPrivateDevices(this.user.id, this.networkId);
        await this.getPrivateNetworkFacilities(this.user.id, this.networkId);
    },
    methods:{
        getPrivateNetworkFacilities(user_id, net_id){
            NetworkFacilityService.getNetworkFacility(user_id, net_id).then(response => {
                this.private_facilities = response.data;
                this.private_facilities.sort(function(a, b) {
                    return a.name.localeCompare(b.name);
                });
            }).catch(error => {
                console.log(error);
            });
        },
        updatePrivateDevice(event, router_id){
            var router = this.private_devices.find(router => router.id === router_id);
            const formData = new FormData;
            formData.append('router_name', router.router_name);
            formData.append('router_id', router.id);
            formData.append('router_type_id', router.router_type_id);
            formData.append('facility_id', router.peeringdb_facility_id);

            if(router_id && router.router_name && router.router_type_id){
                PrivateDeviceService.editPrivateDevice(this.user.id, this.networkId, formData).then(response => {
                   if(response.status == 200){
                       Vue.$toast.success('Saved!',{
                            position: 'top-right'
                        });
                   }
                }).catch(error => {
                    if(error.response.data.error){
                        Vue.$toast.error(error.response.data.error, {
                            position: 'top-right'
                        });
                    }else{
                        Vue.$toast.error('Oooops! Something went wrong!', {
                            position: 'top-right'
                        });
                    }
                });
            }else{
                alert('All fields must be defined!');
            }

        },
        updateRouterName(router_id, event){
            this.private_devices = this.private_devices.map(router => {
                if(router.id == router_id){
                    return {...router, router_name: event.target.value};
                }
                return router;
            });
        },
        updateRouterType(router_id, event){
            this.private_devices = this.private_devices.map(router => {
                if(router.id == router_id){
                    return {...router, router_type_id: event.target.value};
                }
                return router;
            });
        },
        updatePrivateFacility(router_id, event){
            this.private_devices = this.private_devices.map(router => {
                if(router.id == router_id){
                    return {...router, peeringdb_facility_id: event.target.value};
                }
                return router;
            });
        },
        routerTypes(){
            RouterServce.getRouterTypes().then(response => {
                this.router_types = response.data;
                 this.router_types.sort(function(a, b) {
                    return a.name.localeCompare(b.name);
                });
            }).catch(error =>{
                if(error.response.status === 401){
                    this.$store.dispatch('logout');
                    this.$router.push('/')
                }
                if( (error.response.status !== 500 && error.response.status !== 400) && error.response.data.error){
                    Vue.$toast.error(error.response.data.error, {
                        position: 'top-right'
                    });
                }else if(error.response.status !== 500 && error.response.status !== 400){
                    Vue.$toast.error(error.response.data, {
                        position: 'top-right'
                    });
                }else if(error.response.status === 500 || error.response.status == 400){
                    Vue.$toast.error('Oooops! Something went wrong!', {
                        position: 'top-right'
                    });
                }
            });
        },
        getAllPrivateDevices(user_id, net_id){
            PrivateDeviceService.getPrivateDevices(user_id, net_id).then(response => {
                this.private_devices = response.data;
            }).catch(error => {
                if(error.response.status === 401){
                    this.$store.dispatch('logout');
                    this.$router.push('/')
                }
                if( (error.response.status !== 500 && error.response.status !== 400) && error.response.data.error){
                    Vue.$toast.error(error.response.data.error, {
                        position: 'top-right'
                    });
                }else if(error.response.status !== 500 && error.response.status !== 400){
                    Vue.$toast.error(error.response.data, {
                        position: 'top-right'
                    });
                }else if(error.response.status === 500 || error.response.status == 400){
                    Vue.$toast.error('Oooops! Something went wrong!', {
                        position: 'top-right'
                    });
                }
            });
        },
        addNewDevice(event){
            event.target.disabled = true;
            const formData = new FormData;

            if(this.private_router_type && this.private_router_name){
                formData.append('router_type_id', this.private_router_type);
                formData.append('router_name', this.private_router_name);
                formData.append('facility_id', this.private_facility_id);

                PrivateDeviceService.createPrivateDevice(this.user.id, this.networkId, formData).then(response => {
                    if(response.status == 200){
                       Vue.$toast.success('Saved!',{
                            position: 'top-right'
                        });

                        this.private_router_type = '';
                        this.private_router_name = '';
                        this.private_facility_id = '';
                        event.target.disabled = false;
                        this.closePrivateDeviceModal();
                        this.getAllPrivateDevices(this.user.id, this.networkId);
                   }
                }).catch(error => {
                    event.target.disabled = false;
                    if(error.response.data.error){
                        Vue.$toast.error(error.response.data.error, {
                            position: 'top-right'
                        });
                    }else{
                        Vue.$toast.error('Oooops! Something went wrong!', {
                            position: 'top-right'
                        });
                    }
                });
            }else{
                alert('All fields must be defined!');
                event.target.disabled = false;

            }
        },
        closePrivateDeviceModal(){
            this.private_device_modal = false;
            this.$emit('modal-change', this.private_device_modal);
        },
        openPrivateDeviceModal(){
            this.private_device_modal = true;
            this.$emit('modal-change', this.private_device_modal);
        }
    }
}
</script>