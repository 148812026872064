import ApiService from "../../services/ApiService";

export default {

    getNetworkSettings(user_id, net_id) {
        return ApiService.get(`user/${user_id}/net/${net_id}/networkSettings`);
    },
    saveZeroTouchPeering(user_id, net_id, data){
        return ApiService.post(`user/${user_id}/net/${net_id}/networkSettings/zeroTouchPeering/save`, data);
    },
    saveSandboxWorkflow(user_id, net_id, data){
        return ApiService.post(`user/${user_id}/net/${net_id}/networkSettings/sandboxWorkflow/save`, data);
    }
}