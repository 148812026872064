import ApiService from "../../services/ApiService";

export default {

    login(data) {
        return ApiService.post('auth/token', data);
    },
    getMe() {
        return ApiService.get('auth/me');
    }
}