import ApiService from "../../services/ApiService";

export default {
    // ignore list
    getIgnoreList(user_id, net) {
        return ApiService.get(`/user/${user_id}/net/${net}/peerSettings/ignore`);
    },
    addToIgnoreList(user_id, net, data) {
        return ApiService.post(`/user/${user_id}/net/${net}/peerSettings/ignore/create`, data);
    },
    removeFromIgnoreList(user_id, net, data) {
        return ApiService.post(`/user/${user_id}/net/${net}/peerSettings/ignore/remove`, data);
    },
    // hide list
    getHideList(user_id, net) {
        return ApiService.get(`/user/${user_id}/net/${net}/peerSettings/hide`);
    },
    addToHideList(user_id, net, data) {
        return ApiService.post(`/user/${user_id}/net/${net}/peerSettings/hide/create`, data);
    },
    removeFromHideList(user_id, net, data) {
        return ApiService.post(`/user/${user_id}/net/${net}/peerSettings/hide/remove`, data);
    },
    // blackList
    getBlacklist(user_id, net) {
        return ApiService.get(`/user/${user_id}/net/${net}/peerSettings/block`);
    },
    addToBlacklist(user_id, net, data) {
        return ApiService.post(`/user/${user_id}/net/${net}/peerSettings/block/create`, data);
    },
    removeFromBlacklist(user_id, net, data) {
        return ApiService.post(`/user/${user_id}/net/${net}/peerSettings/block/remove`, data);
    },
}