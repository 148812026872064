<template src='./template.html'></template>

<style src="./style.css"></style>

<script>
import Vue from 'vue';
import TEditor from '../../components/TEditorComponent/TEditorComponent';

// import services
import NetService from '../../services/NetService';
import RequestService from "@/services/user/RequestService";
import RouterService from "@/services/user/RouterService";
import EmailTemplateService from '../../services/user/EmailTemplateService';

export default {
    name: 'NotPeeredCard',
    props: [
        'not_peered', 
        'selectedIx', 
        'networkId',
        'selectedPolicyV4', 
        'selectedPolicyV6', 
        'selectedPolicyV4_out', 
        'selectedPolicyV6_out',
        'user',
        'ixes'
    ],
    components:{
      TEditor
    },
    data(){
        return{
            modalPeeringRequest: false,
            request_email: null,
            other_request_emails: null,
            nets_request_emails: [],
            selectedRequestEmail: null,
            selected_request_network: null,
            selected_request_net_name: null,
            otherPeerLocation: [],
            otherlocation_loader: false,
            email_loader: false,
            peering_note: null,
            manually_router_selected: null,
            manually_to_network: null,
            manuallyModal: false,
            network_routers: [],
            selected_network_router: '',
            not_allowed_modal: false,
            selectedEmailTemplate: null,
            email_templates: [],
            email_content: '',
            last_request_exists: null,
        }
    },
    methods:{
      getLastRequest(user_id, net_id, ix_id, to_network){
         return new Promise((resolve, reject)=>{  
            RequestService.getLastPeeringRequest(user_id, net_id, ix_id, to_network).then(response => {
              resolve(response.data);
            }).catch(error => {
              if(error.response.data.error){
                  Vue.$toast.error(error.response.data.error, {
                      position: 'top-right'
                  });
              }else{
                  Vue.$toast.error('Oooops! Something went wrong!', {
                      position: 'top-right'
                  });
              }
              reject(error);
            });
         });
      },
      getNetInformation(user_id, net_id){
        return new Promise((resolve, reject)=>{  
            NetService.getNetworkInfo(user_id, net_id, net_id).then(response => {
              resolve(response.data);
            }).catch(error => {
              if(error.response.data.error){
                  Vue.$toast.error(error.response.data.error, {
                      position: 'top-right'
                  });
              }else{
                  Vue.$toast.error('Oooops! Something went wrong!', {
                      position: 'top-right'
                  });
              }
              reject(error);
            });
         });
      },
       chanageEmailContent(value){
        this.email_content = value;
      },
      async onChangeEmailTemp(){
        var network = this.not_peered.filter(item => {
            if(item.id == this.selected_request_network ){
              return item;
            }
        });
        var local_network = await this.getNetInformation(this.user.id, this.networkId);
        var ix_name = this.ixes.name;
        var loc = ix_name;
        this.otherPeerLocation.forEach(location => {
          loc += location+'\n';
        })
        var content = this.selectedEmailTemplate;
        var local_company = local_network.name;
        var local_asn = local_network.asn;
        var website = local_network.website;
       
        content = content.replaceAll('{local_company_name}', local_company);
        content = content.replaceAll('{local_asn}',local_asn);
        content = content.replaceAll('{peer_company_name}', network[0].name);
        content = content.replaceAll('{peer_asn}', network[0].asn);
        content = content.replaceAll('{mutual_locations}', loc);
        content = content.replaceAll('{website}', website);

        this.email_content = content;
      },
      getEmailTemp(user_id, net_id){
          EmailTemplateService.getEmailTemplates(user_id, net_id).then(response => {
              this.email_templates = response.data;
          }).catch(error =>{
              if(error.response.data.error){
                  Vue.$toast.error(error.response.data.error, {
                      position: 'top-right'
                  });
              }else{
                  Vue.$toast.error('Oooops! Something went wrong!', {
                      position: 'top-right'
                  });
              }
          });
      },
      hideNotAllowedModal(){
        this.not_allowed_modal = false;
      },
      getCurrentNetRouter(user_id, net_id, ix_id){
        RouterService.getNetworkRouterList(user_id, net_id, ix_id).then(response => {
          this.network_routers = response.data;
          this.selected_network_router = this.network_routers[0].id;
        }).catch(error => {
          if(error.response.data.error){
              Vue.$toast.error(error.response.data.error, {
                  position: 'top-right'
              });
          }else{
              Vue.$toast.error('Oooops! Something went wrong!', {
                  position: 'top-right'
              });
          }
        });
      },
      closeManuallyAddSession(){
        this.manuallyModal = false;
        this.manually_to_network = null;
        this.manually_router_selected = null;
      },
      openManuallyAddPeer(router_id, to_network){
        this.manuallyModal = true;
        this.getCurrentNetRouter(this.user.id, this.networkId, this.selectedIx);
        this.manually_router_selected = router_id;
        this.manually_to_network = to_network;
      },
      manuallyAddPeer(event){

        var button = event.target;
        button.disabled = true;
        var button_text = document.getElementsByClassName('send_text')[0];
        button_text.classList.add('hidden');
        var animate_spiner = document.getElementsByClassName('animate-spin')[0];
        animate_spiner.classList.remove('hidden');

        const formData = new FormData;
        formData.append('router_id', this.manually_router_selected);
        formData.append('from_router_id', this.selected_network_router);
        formData.append('to_network', this.manually_to_network);
        formData.append('ix_id', this.selectedIx);
        RequestService.manuallyAddSession(this.user.id, this.networkId, formData).then(response => {

          if(response.data.status == 'error'){
            Vue.$toast.warning(response.data.message,{
              position: 'top-right',
              duration: 3000
            });

            this.manuallyModal = false;
            button_text.classList.add('hidden');
            animate_spiner.classList.add('hidden');
            this.not_allowed_modal = true;
            this.closePeeringRequest();
          }else {

            this.$emit('notPeered', response.data[0]);
            this.manuallyModal = false;
            button.disabled = true;
            button_text.classList.add('hidden');
            animate_spiner.classList.add('hidden');
            Vue.$toast.success('Manually session added!', {
              position: 'top-right'
            });
          }
        }).catch(error => {
          if(error.response.data.error){
              Vue.$toast.error(error.response.data.error, {
                  position: 'top-right'
              });
          }else{
              Vue.$toast.error('Oooops! Something went wrong!', {
                  position: 'top-right'
              });
          }
          button.disabled = false;
          button_text.classList.add('hidden');
          animate_spiner.classList.add('hidden');
        });
      },
      closePeerSettings(event){
        var parent_element = '';

        if(event.target.parentNode.parentNode.parentNode.parentNode.id == 'settings'){
          parent_element = event.target.parentNode.parentNode;
        }else if(event.target.parentNode.parentNode.parentNode.id == 'settings'){
          parent_element = event.target.parentNode.parentNode.parentNode;
        }else if(event.target.parentNode.parentNode.id == 'settings'){
          parent_element = event.target.parentNode.parentNode.parentNode;
        }

        if(parent_element != ''){
          var open_peer_settings = parent_element.getElementsByClassName('peerSettings')[0];
          var close_peer_settings = parent_element.getElementsByClassName('closePeerSettings')[0];

          if(close_peer_settings.classList.contains('hidden') == false){
            open_peer_settings.classList.remove('hidden');
            close_peer_settings.classList.add('hidden');
          }
        }

      },
      openPeerSettings(event){
        var parent_element = '';
        if(event.target.parentNode.parentNode.id == 'settings'){
          parent_element = event.target.parentNode.parentNode;
        }else if(event.target.parentNode.parentNode.parentNode.id == 'settings'){
          parent_element = event.target.parentNode.parentNode.parentNode;
        }

        if(parent_element != ''){
          var open_peer_settings = parent_element.getElementsByClassName('peerSettings')[0];
          var close_peer_settings = parent_element.getElementsByClassName('closePeerSettings')[0];

          if(open_peer_settings.classList.contains('hidden')  == false){
            open_peer_settings.classList.add('hidden');
            close_peer_settings.classList.remove('hidden');
          }
        }

      },
        getOtherLoc(to_net){
            this.otherPeerLocation = [];
            this.otherlocation_loader = true;
            NetService.getPeeringOnAllLocations(this.user.id, this.selectedIx, this.networkId, to_net)
                    .then(response => {
                        this.otherPeerLocation = response.data;
                        this.otherPeerLocation.sort(function(a, b) {
                            return a.localeCompare(b);
                        });
                        this.otherlocation_loader = false;
                    }).catch(error => {
                        this.otherlocation_loader = false;
                       if(error.response.data.error){
                            Vue.$toast.error(error.response.data.error, {
                                position: 'top-right'
                            });
                        }else{
                            Vue.$toast.error('Oooops! Something went wrong!', {
                                position: 'top-right'
                            });
                        }
                    });
        },
        selectRequestEmail(){
            this.request_email = this.selectedRequestEmail;
        },
        formatCreatedAt(date){
          const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
          const requeste_deleted_date = new Date(date);
          const today_date = new Date();

          const diffDays = Math.round(Math.abs((today_date - requeste_deleted_date) / oneDay));
          return diffDays;
        },
        async openPeeringR(net_id, net_name){
            this.selected_request_network = net_id;
            this.selected_request_net_name = net_name;
            await this.getEmailForNet(net_id);
            await this.getOtherLoc(this.selected_request_network);
            var last_made_request = await this.getLastRequest(this.user.id, this.networkId, this.selectedIx, net_id);
            if(last_made_request.deleted_at){
              var dayRange = this.formatCreatedAt(last_made_request.deleted_at);
              if(dayRange <= 30){
                this.last_request_exists = dayRange;
              }else if(dayRange > 30 && dayRange <= 60){
                this.last_request_exists = dayRange;
              }else if(dayRange > 60 && dayRange <= 90){
                this.last_request_exists = dayRange;
              }
            }
           
            this.getEmailTemp(this.user.id, this.networkId);

            this.modalPeeringRequest = true;
            this.$emit('modal-change', this.modalPeeringRequest);

        },
        closePeeringRequest(){
            this.request_email = null;
            this.selectedRequestEmail = null;
            this.selected_request_network = null;
            this.selected_request_net_name = null;
            this.modalPeeringRequest = false;
            this.peering_note = null;

            this.$emit('modal-change', this.modalPeeringRequest);
        },
        getEmailForNet(net_id){
            this.email_loader = true;
            NetService.getNetEmail(this.user.id, net_id).then(response => {
                this.nets_request_emails = response.data.data;
                var emails = this.nets_request_emails;
                emails.map(element => {
                    // if net have policy email select that email as default
                    if(element.role == 'Policy'){
                        this.selectedRequestEmail = element.email;
                    }
                });
                this.email_loader = false;
            }).catch(error => {
                this.email_loader = false;
                if(error.response.status === 401){
                    this.$store.dispatch('logout');
                    this.$router.push('/')
                }
                if( (error.response.status !== 500 && error.response.status !== 400) && error.response.data.error){
                    Vue.$toast.error(error.response.data.error, {
                        position: 'top-right'
                    });
                }else if(error.response.status !== 500 && error.response.status !== 400){
                    Vue.$toast.error(error.response.data, {
                        position: 'top-right'
                    });
                }else if(error.response.status === 500 || error.response.status == 400){
                    Vue.$toast.error('Oooops! Something went wrong!', {
                        position: 'top-right'
                    });
                }
            });
        },
        validateEmail(email){
            var re = /\S+@\S+\.\S+/;
            return re.test(email);
        },
        peeringRequest(event){

          var button = event.target;
          button.disabled = true;
          var button_text = document.getElementsByClassName('send_text')[0];
          button_text.classList.add('hidden');
          var animate_spiner = document.getElementsByClassName('animate-spin')[0];
          animate_spiner.classList.remove('hidden');
          var check_is_not_valid = [];
          if(this.other_request_emails){
            var other_emails = this.other_request_emails.split(',');
            check_is_not_valid = other_emails.filter(email => {
              if(this.validateEmail(email) == false){
                return true;
              }else{
                return false;
              }
            });
          }

          if(check_is_not_valid.length == 0 && this.validateEmail(this.selectedRequestEmail) == true){

            const formData = new FormData;
            formData.append('request_to_netID', this.selected_request_network);
            formData.append('request_from_netID', this.networkId);
            formData.append('policy_v4', this.selectedPolicyV4);
            formData.append('policy_v6', this.selectedPolicyV6);
            // formData.append('to_email', this.request_email); // for production
            formData.append('to_email', 'nesto@gmail.com');
            formData.append('ix_id', this.selectedIx);
            formData.append('md5_password', this.md5Password);
            formData.append('peering_note', this.peering_note);

            if(this.new_prefix_4){
                formData.append('new_prefix_4', this.new_prefix_4);
            }

            if(this.new_prefix_6){
                formData.append('new_prefix_6', this.new_prefix_6);
            }
          
            NetService.requestPeering(this.user.id, this.networkId, formData).then(response => {
              if(response.data.status == 'error'){
                button_text.classList.remove('hidden');
                animate_spiner.classList.add('hidden');
                this.not_allowed_modal = true;
                this.closePeeringRequest();
              }else{

                this.closePeeringRequest();
                Vue.$toast.success('Request sent!',{
                  position: 'top-right'
                });
                this.$emit('notPeered', response.data);
                button.disabled = true;
                button_text.classList.add('hidden');
                animate_spiner.classList.add('hidden');
              }

            }).catch(error => {
                if(error.response.status === 401){
                    this.$store.dispatch('logout');
                    this.$router.push('/')
                }
                if( (error.response.status !== 500 && error.response.status !== 400) && error.response.data.error){
                    Vue.$toast.error(error.response.data.error, {
                        position: 'top-right'
                    });
                }else if(error.response.status !== 500 && error.response.status !== 400){
                    Vue.$toast.error(error.response.data, {
                        position: 'top-right'
                    });
                }else if(error.response.status === 500 || error.response.status == 400){
                    Vue.$toast.error('Oooops! Something went wrong!', {
                        position: 'top-right'
                    });
                }
                button.disabled = false;
                button_text.classList.remove('hidden');
                animate_spiner.classList.add('hidden');
            });
          }else{
            button.disabled = false;
            button_text.classList.remove('hidden');
            animate_spiner.classList.add('hidden');
            if(check_is_not_valid.length == 1){
              alert(`This email is not valid "${check_is_not_valid}"`)
            }else if(check_is_not_valid.length > 1){
              alert(`This emails are not valid "${check_is_not_valid}"`)
            }
            
            if(!this.other_request_emails && !this.request_email){
              alert('Email is required!');
            }
          }
      },
    }
}
</script>