<template src="./template.html"></template>

<script>
import HomeFooter from '../../components/HomeFooterComponent/HomeFooterComponent';
import TopNavbarComponent from '../../components/TopNavbarComponent/TopNavbarComponent';

export default {
    name: 'Eula',
    components:{
        HomeFooter,
        TopNavbarComponent
    },
    data(){
        return{
            notShowMenu: true
        }
    },
    created(){
        this.scrollToTop();
    },
    methods: { 
        scrollToTop() {
            window.scrollTo(0,0);
        }
    }
}
</script>

<style scoped>
    @import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap&subset=Roboto');
    @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@700&display=swap&subset=Roboto-bold');
    /* @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@700&display=swap'); */

    p{
        font-family: 'Roboto', sans-serif;
    }
    h1, h2, h3{
        font-family: 'Roboto-bold', sans-serif;
    }
</style>