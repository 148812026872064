<template src="./template.html"></template>
<style src="./style.css" scoped></style>

<script>
import Vue from 'vue';

// import services
import RequestService from "../../services/user/RequestService";

export default {
    name: 'PeeringRequestTable',
    props: ['user', 'networkId'],
    data(){
        return{
            requests: [],
            peer_request_loader: false,
            showNoRequests: false,
            selectedRows: [],
            numberOfSelected: 0,
            perPage: 50,
            modalRouterInfo: false,
            routerInfo: [],
            currentPage: 1,
            totalRows: 0,
            interval: null,
            searchQuery: '',
            timer: null,
            selectedDisplay: 'all',
            internetIx: [],
            showSessionModal: false,
            routers_sessions: [],
        }
    },
    watch: {
        'networkId': async function (value)  {
            if(value){
                this.networkId = value;
                await this.getRequests(this.user.id, this.networkId);
            }
        },
        'searchQuery': async function() {
            // setTimeout -> when user stop typing trigger serach method after 0.5 seconds
            if(this.timer) {
                clearTimeout(this.timer);
                this.timer = null;
            }
            this.timer = setTimeout(() => {
                if(this.searchQuery.length > 2){
                    this.totalRows = 0;
                    this.searchMethod(this.user.id, this.networkId, this.selectedDisplay, this.searchQuery);
                }else if(this.searchQuery.length == 0 && this.selectedDisplay != 'all'){
                    this.searchMethod(this.user.id, this.networkId, this.selectedDisplay, this.searchQuery);
                }else if(this.searchQuery.length == 0 && this.selectedDisplay == 'all'){
                    this.getRequests(this.user.id, this.networkId);
                }
            }, 500);
        }
    },
    created(){
        if(this.networkId){
            this.getRequests(this.user.id, this.networkId);
        }
    },
    methods:{
        rejectPeering(peering_id, event){

            var parentElement = event.target.parentNode;
            if(parentElement.tagName == 'svg'){
                parentElement = parentElement.parentNode;
            }
            parentElement.getElementsByClassName('reject_session_loader')[0].classList.remove('hidden');
            parentElement.getElementsByClassName('reject_button')[0].classList.add('hidden');
            event.target.disabled = true;

            const formData = new FormData;
            formData.append('peering_id', peering_id);
            
            RequestService.rejectRequest(this.user.id, this.networkId, formData).then(response =>{
                if(response.data){
                    this.getRequests(this.selectedIx, this.networkId);
                    Vue.$toast.danger('Peering request rejected!', {
                        position: 'top-right'
                    });
                }
                
            }).catch(error => {
                if(error.response.status === 401){
                    this.$store.dispatch('logout');
                    this.$router.push('/')
                }
                if( (error.response.status !== 500 && error.response.status !== 400) && error.response.data.error){
                    Vue.$toast.error(error.response.data.error, {
                        position: 'top-right'
                    });
                }else if(error.response.status !== 500 && error.response.status !== 400){
                    Vue.$toast.error(error.response.data, {
                        position: 'top-right'
                    });
                }else if(error.response.status === 500 || error.response.status == 400){
                    Vue.$toast.error('Oooops! Something went wrong!', {
                        position: 'top-right'
                    });
                }
            });
        },
        selectAllCheckbox($event) {
            let columns = document.querySelectorAll('.rowCheckbox');
            this.selectedRows = [];

            if ($event.target.checked == true) {
                columns.forEach(column => {
                    column.checked = true
                    this.selectedRows.push(parseInt(column.name))
                });
            }else{
                columns.forEach(column => {
                    column.checked = false
                });
                this.selectedRows = [];
            }
            this.numberOfSelected = this.selectedRows.length;
        },
        deSelectAllCheckbox() {
            let columns = document.querySelectorAll('.rowCheckbox');
            this.numberOfSelected = 0;
            columns.forEach(column => {
                column.checked = false
            });
        },
        getRowDetail($event, id){
            let rows = this.selectedRows;

            if (rows.includes(id)) {
                let index = rows.indexOf(id);
                rows.splice(index, 1);
            }else{
                rows.push(id);
            }
            this.selectedRows = rows;
            this.numberOfSelected = this.selectedRows.length;
        },
       
        selectDisplay(){
            this.totalRows = 0;
            this.requests = [];

            if(this.searchQuery == '' && this.selectedDisplay != 'all'){
                 this.searchMethod(this.user.id, this.networkId, this.selectedDisplay, 'ALL');
            }else{
                this.searchMethod(this.user.id, this.networkId, this.selectedDisplay, this.searchQuery);
            }
        },
        searchMethod(user_id, networkId, selectedDisplay, searchQuery){
            if(searchQuery == ''){
                searchQuery = 'ALL';
            }
            RequestService.serachPeeringRequests(user_id, networkId, selectedDisplay, searchQuery).then(response => {
                this.requests = response.data.data;
                this.totalRows = response.data.total;
            }).catch(error => {
                if(error.response.status === 401){
                    this.$store.dispatch('logout');
                    this.$router.push('/')
                }
                if( (error.response.status !== 500 && error.response.status !== 400) && error.response.data.error){
                    Vue.$toast.error(error.response.data.error, {
                        position: 'top-right'
                    });
                }else if(error.response.status !== 500 && error.response.status !== 400){
                    Vue.$toast.error(error.response.data, {
                        position: 'top-right'
                    });
                }else if(error.response.status === 500 || error.response.status == 400){
                    Vue.$toast.error('Oooops! Something went wrong!', {
                        position: 'top-right'
                    });
                }
            });
        },
        changePage(){
            this.getRequests(this.user.id, this.networkId);
        },
        closeModalRouterInfo(value){
            this.modalRouterInfo = value;
        },
        getRequests(user_id, net_id){
            this.peer_request_loader = true;
            this.showNoRequests = false;
            this.requests = [];
            RequestService.getRequest(user_id, net_id, this.currentPage).then(response =>{
                this.requests = response.data.data;
                this.totalRows = response.data.total;
                if(this.requests.length === 0){
                  this.showNoRequests = true;
                }
                this.peer_request_loader = false;
            }).catch(error =>{
                this.peer_request_loader = false;
                if(error.response.status === 401){
                    this.$store.dispatch('logout');
                    this.$router.push('/')
                }
                if( (error.response.status !== 500 && error.response.status !== 400) && error.response.data.error){
                    Vue.$toast.error(error.response.data.error, {
                        position: 'top-right'
                    });
                }else if(error.response.status !== 500 && error.response.status !== 400){
                    Vue.$toast.error(error.response.data, {
                        position: 'top-right'
                    });
                }else if(error.response.status === 500 || error.response.status == 400){
                    Vue.$toast.error('Oooops! Something went wrong!', {
                        position: 'top-right'
                    });
                }
            });
        },
        approve(peering_id, event){
            var parentElement = event.target.parentNode;
            if(parentElement.tagName == 'svg'){
                parentElement = parentElement.parentNode;
            }
            parentElement.getElementsByClassName('approve_session_loader')[0].classList.remove('hidden');
            parentElement.getElementsByClassName('approve_button')[0].classList.add('hidden');
            event.target.disabled = true;
            var formData = new FormData;
            formData.append('for_net', this.networkId);
            formData.append('peering_id', peering_id);

            RequestService.approveRequest(this.user.id,this.networkId, formData).then(response =>{
                if(response.data){
                    this.requests = [];
                    this.getRequests(this.selectedIx, this.networkId);
                    Vue.$toast.success('Peering request approved!', {
                        position: 'top-right'
                    });
                }

                if(this.searchQuery == ''){
                    this.getRequests(this.user.id, this.networkId);
                }else{
                    this.searchMethod(this.user.id, this.networkId, this.selectedDisplay, this.searchQuery);
                }

                this.$emit('change-peering', 1);
                parentElement.getElementsByClassName('approve_session_loader')[0].classList.add('hidden');
                parentElement.getElementsByClassName('approve_button')[0].classList.remove('hidden');
                event.target.disabled = false;
            }).catch(error =>{
                if(error.response.status === 401){
                    this.$store.dispatch('logout');
                    this.$router.push('/')
                }
                if( (error.response.status !== 500 && error.response.status !== 400) && error.response.data.error){
                    Vue.$toast.error(error.response.data.error, {
                        position: 'top-right'
                    });
                }else if(error.response.status !== 500 && error.response.status !== 400){
                    Vue.$toast.error(error.response.data, {
                        position: 'top-right'
                    });
                }else if(error.response.status === 500 || error.response.status == 400){
                    Vue.$toast.error('Oooops! Something went wrong!', {
                        position: 'top-right'
                    });
                }
                parentElement.getElementsByClassName('approve_session_loader')[0].classList.add('hidden');
                parentElement.getElementsByClassName('approve_button')[0].classList.remove('hidden');
                event.target.disabled = false;
            });
        },

        bulkApprove(event){
            document.getElementsByClassName('bulk_session_loader')[0].classList.remove('hidden');
            document.getElementsByClassName('bulk_session_btn')[0].classList.add('hidden');
            event.target.disabled = true;
            const formData = new FormData;
            formData.append('for_net', this.networkId);
            formData.append('peering_ids', this.selectedRows);

            RequestService.bulkApproveRequest(this.user.id, this.networkId, formData).then(response =>{
                if(response.data){
                    this.requests = [];
                    if(this.selectedRows.length < 15){
                        // de-select checked checkbox
                        this.deSelectAllCheckbox();
                        this.selectedRows = [];
                    }else{
                        let columns = document.querySelectorAll('.masterCheckbox');
                        this.numberOfSelected = 0;
                        columns.forEach(column => {
                            column.checked = false
                        });
                    }
                    this.getRequests(this.selectedIx, this.networkId);
                    Vue.$toast.success('Peering request approved!', {
                        position: 'top-right'
                    });

                    this.$emit('change-peering', this.selectedRows.length);

                    event.target.disabled = false;
                    document.getElementsByClassName('bulk_session_loader')[0].classList.add('hidden');
                    document.getElementsByClassName('bulk_session_btn')[0].classList.remove('hidden');
                }
            }).catch(error =>{
                if(error.response.status === 401){
                    this.$store.dispatch('logout');
                    this.$router.push('/')
                }
                if( (error.response.status !== 500 && error.response.status !== 400) && error.response.data.error){
                    Vue.$toast.error(error.response.data.error, {
                        position: 'top-right'
                    });
                }else if(error.response.status !== 500 && error.response.status !== 400){
                    Vue.$toast.error(error.response.data, {
                        position: 'top-right'
                    });
                }else if(error.response.status === 500 || error.response.status == 400){
                    Vue.$toast.error('Oooops! Something went wrong!', {
                        position: 'top-right'
                    });
                }

                document.getElementsByClassName('bulk_session_loader')[0].classList.add('hidden');
                document.getElementsByClassName('bulk_session_btn')[0].classList.remove('hidden');

                event.target.disabled = false;
            });
        }
    }

}
</script>