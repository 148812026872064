import ApiService from "../ApiService";

export default {
    getRouters(user_id, net_id, currentPage) {
        return ApiService.get(`user/${user_id}/net/${net_id}/router?page=${currentPage}`);
    },
    addRouterName(user_id, net_id, data) {
        return ApiService.post(`user/${user_id}/net/${net_id}/router/add`, data);
    },
    getRouterTypes() {
        return ApiService.get(`routerType`);
    },
    getNetworkRouterList(user_id, net_id, ix_id){
        return ApiService.get(`user/${user_id}/net/${net_id}/netix/${ix_id}/router/list`);
    }
}