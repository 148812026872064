<template src="./template.html"></template>
<style src="./style.css" scoped></style>

<script>
import Vue from 'vue';

// import component
import DashboardTopNav from "../../components/DashboardTopNavComponent/DashboardTopNavComponent";
import SideBarNavigation from "../../components/SidebarNavigationComponent/SidebarNavigationComponent";
import IternetExchange from "../../components/InternetExchangeComponent/InternetExchangeComponent";
import PeeringRequestTable from "../../components/PeeringRequestTableComponent/PeeringRequestTableComponent";
import Footer from '../../components/FooterDashboardComponent/FooterDashboardComponent';

// import services
import NetService from '../../services/NetService';
import RequestService from "../../services/user/RequestService";

export default {
    name: "PeeringRequest",
    components:{
        DashboardTopNav,
        SideBarNavigation,
        IternetExchange,
        PeeringRequestTable,
        Footer
    },
    data(){
        return {
            page: null,
            user: {},
            networkId: null,
            selectedIx: null,
            ixes: [],
            network_id: null,
            total_requests: 0,
            background_of_modal: false,

        }
    },
    async created(){
        this.page = this.$route.name.toLowerCase();
        // get user first net_id
        if(this.$store.getters.user.id){
            if(localStorage.getItem('activeNet')){
                this.networkId = localStorage.getItem('activeNet');
            }else{
                if(this.$store.getters.user.networks){
                    this.networkId = this.$store.getters.user.networks[0].peeringdb_network_id;
                }   
            }
            this.user  = {
                id: this.$store.getters.user.id,
            };
        }

        // set Internet Exchange
        this.network_id = this.networkId;
        if(this.network_id){
            const ix = this.networkId;
            this.ixes = ix;
            await this.getNewRequest(this.user.id, this.networkId);
        }
        
    },
    methods:{
        modalChange(modal){
            if(modal){
                this.background_of_modal = true;
            }else{
                this.background_of_modal = false;
            }
        },
        async updatePeering(value){
            // if(value){
                console.log(value);
                await this.allRequests(this.user.id, this.networkId);
            // }
        },
        getNewRequest(user_id, net){
            RequestService.getNewRequestsDashboard(user_id, net).then(response => {
                this.total_requests = 0;
                if(response.data.length > 0){
                    response.data.forEach((item, index) => {
                        // this.dataRequsts.push({value: index},{value: index + 1});
                        this.total_requests += index + 1;
                    });
                }
            });
        },
        // onChange user net
        async networkChanged(network_id){
            this.network_id = network_id;
            this.networkId = network_id;
            await this.getNewRequest(this.user.id, this.networkId);
        },
        // Get internet Exchanges for given user net_id
        getInternetExchange(user_net_id){
            return new Promise((resolve, reject)=>{
                NetService.getAllIx(user_net_id).then(response => {
                    resolve(response.data);
                }).catch(error => {
                    if(error.response.data.error){
                        Vue.$toast.error(error.response.data.error, {
                            position: 'top-right'
                        });
                    }else{
                        Vue.$toast.error('Oooops! Something went wrong!', {
                            position: 'top-right'
                        });
                    }
                    reject(error);
                });     
            });      
        }
    }
}
</script>