<template src="./template.html"></template>
<style src="./style.css" scoped></style>

<script>
import Vue from 'vue';

import emailService from '../../services/EmailService';

export default {
    name: 'FooterDashboard',
    props: ['networkId'],
    data(){
        return{
            user: this.$store.getters.user,
            wishForm: false,
            company: '',
            current_page: '',
            subject: '',
            message: '',
            error_message: '',
        }
    },
    created(){
        var networks = this.$store.getters.user.networks;

        if(networks){
            networks.forEach(network => {
                if(network.peeringdb_network_id == this.networkId){
                    this.company = network.name;
                }
            });
        }
        
        this.current_page = this.$route.path;
    },
    methods:{
        sendWish(){
            if(this.company != '' && this.subject != '' && this.message != ''){

                const formData = new FormData;
                formData.append('company', this.company);
                formData.append('page', this.current_page);
                formData.append('subject', this.subject);
                formData.append('message', this.message);

                emailService.wishMessage(this.user.id, this.networkId, formData).then(response =>{
                    if(response.data){
                        Vue.$toast.success('Message sent!', {
                            position: 'bottom-right'
                        });

                        this.subject = '';
                        this.message = '';
                        this.error_message = '';
                        this.wishForm = false;
                    }
                }).catch(error => {
                    if(error.response.data.error){
                        Vue.$toast.error(error.response.data.error, {
                            position: 'top-right'
                        });
                    }else{
                        if(error.response.status == 500){
                            Vue.$toast.error('Oooops! Something went wrong!', {
                                position: 'top-right'
                            });
                        }else{
                            if(error.response.data.errors){
                                Vue.$toast.error(error.response.data.errors, {
                                    position: 'top-right'
                                });
                            }
                        }
                    }
                });
            }else{
                this.error_message = 'All fields are required!';
            }
        },
        openWishForm(){
            this.wishForm = true;
        },
        closeWishForm(){
            this.wishForm = false;
        }
    }
}
</script>