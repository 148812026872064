<template src="./template.html"></template>

<script>
import Vue from 'vue';
import PolicyService from '../../services/user/PolicyService';

export default {
    name: "EditPolicy",
    props: ['policy_id', 'networkId', 'user', 'ixes'],
    data(){
        return {
            edit_name: null,
            edit_import_policy: 0,
            edit_export_policy: 0,
            edit_peer_group: null,
            edit_ipv4_global_policy: 0,
            edit_ipv6_global_policy: 0,
            edit_private_policy: 0,
            edit_selected_ix: '',
        }
    },
    created(){
        this.getPolicy();
    },
    methods:{
        updatePolicy(){
            this.edit_name = this.edit_name.replaceAll(/\s/g,'').toUpperCase();
            var formData = new FormData;

            this.edit_import_policy = (this.edit_import_policy) ? 1 : 0;
            this.edit_export_policy = (this.edit_export_policy) ? 1 : 0;
            this.edit_private_policy = (this.edit_private_policy) ? 1 : 0;
            this.edit_ipv4_global_policy = (this.ediedit_ipv4_global_policyt_private_policy) ? 1 : 0;
            this.edit_ipv6_global_policy = (this.edit_ipv6_global_policy) ? 1 : 0;
            this.edit_selected_ix = (this.edit_selected_ix) ? this.edit_selected_ix : 0;

            formData.append('name', this.edit_name);
            formData.append('import_policy', this.edit_import_policy);
            formData.append('export_policy', this.edit_export_policy);
            formData.append('peer_group', this.edit_peer_group);
            formData.append('ipv4_global_policy', this.edit_ipv4_global_policy);
            formData.append('ipv6_global_policy', this.edit_ipv6_global_policy);
            formData.append('private_policy', this.edit_private_policy);
            formData.append('ix_id', this.edit_selected_ix);

            PolicyService.updatePolicy(this.user.id, this.$props.networkId, this.$props.policy_id,formData)
                .then(response => {
                    if(response.data){
                        this.$emit('update-policy', response.data);
                         Vue.$toast.success('Policy Updated!',{
                            position: 'top-right'
                        });
                    }
                }).catch(error => {
                    if(error.response.data.error){
                        Vue.$toast.error(error.response.data.error, {
                            position: 'top-right'
                        });
                    }else{
                        Vue.$toast.error('Oooops! Something went wrong!', {
                            position: 'top-right'
                        });
                    }
                })
        },
        getPolicy(){
            PolicyService.getPolicyById(this.user.id, this.$props.networkId, this.$props.policy_id).then(response => {
                this.edit_name = response.data.policy_name;
                this.edit_selected_ix = response.data.ix_id;
                if(response.data.import_policy == 1){
                    this.edit_import_policy = true;
                }else{
                    this.edit_import_policy = false;
                }
                if(response.data.export_policy == 1){
                    this.edit_export_policy = true;
                }else{
                    this.edit_export_policy = false;
                }
                if(response.data.global_v4_policy == 1){
                    this.edit_ipv4_global_policy = true;
                }else{
                    this.edit_ipv4_global_policy = false;
                }

                if(response.data.global_v6_policy == 1){
                    this.edit_ipv6_global_policy = true;
                }else{
                    this.edit_ipv6_global_policy = false;
                }

                if(response.data.private == 1){
                    this.edit_private_policy = true;
                }else{
                    this.edit_private_policy = false;
                }
                
            }).catch(error => {
                if(error.response.status === 401){
                    this.$store.dispatch('logout');
                    this.$router.push('/')
                }
                if( (error.response.status !== 500 && error.response.status !== 400) && error.response.data.error){
                    Vue.$toast.error(error.response.data.error, {
                        position: 'top-right'
                    });
                }else if(error.response.status !== 500 && error.response.status !== 400){
                    Vue.$toast.error(error.response.data, {
                        position: 'top-right'
                    });
                }else if((error.response.status == 400)){
                    Vue.$toast.error(error.response.data.error, {
                        position: 'top-right'
                    });
                }
                else if((error.response.status === 500 || error.response.status == 400)){
                    Vue.$toast.error('Oooops! Something went wrong!', {
                        position: 'top-right'
                    });
                }
            });
        },
        closeEditPolicy(){
            this.$emit('close-policy', false);
        },
        changeImportPolicy(){
            this.edit_export_policy = false;
        },
         changeExportPolicy(){
            this.edit_import_policy = false;
        },
    }
}
</script>