import ApiService from "../ApiService";

export default {

    getPrivateDevices(user_id, net_id) {
        return ApiService.get(`user/${user_id}/net/${net_id}/privateDevice/list`);
    },
    createPrivateDevice(user_id, net_id, data){
        return ApiService.post(`user/${user_id}/net/${net_id}/privateDevice/create`, data);
    },
    editPrivateDevice(user_id, net_id, data){
        return ApiService.post(`user/${user_id}/net/${net_id}/privateDevice/edit`, data);
    }
}   