<template src="./template.html"></template>
<style src="./style.css" scoped></style>

<script>
import Vue from 'vue';
// components
import DashboardTopNav from "../../components/DashboardTopNavComponent/DashboardTopNavComponent";
import SideBarNavigation from "../../components/SidebarNavigationComponent/SidebarNavigationComponent";
import EditPolicy from "../../components/EditPolicyComponent/EditPolicyComponent";
import Footer from '../../components/FooterDashboardComponent/FooterDashboardComponent';
import TextEditor from '../../components/TextEditorComponent/TextEditorComponent';
import EditTextEditor from '../../components/EditTextEditorComponent/EditTextEditorComponent';
import EditSandboxPolicy from '../../components/EditSandboxPolicyComponent/EditSandboxPolicyComponent';
import PublicPolicyTable from '../../components/PublicPolicyTableComponent/PublicPolicyTableComponent';
import PrivatePolicyTable from '../../components/PrivatePolicyTableComponent/PrivatePolicyTableComponent';

// serivces
import PolicyService from '../../services/user/PolicyService';
import EmailTemplateService from '../../services/user/EmailTemplateService';
import NetService from '../../services/NetService';

export default {
    name: "Template",
    components:{
        DashboardTopNav,
        SideBarNavigation,
        EditPolicy,
        Footer,
        TextEditor,
        EditTextEditor,
        EditSandboxPolicy,
        PublicPolicyTable,
        PrivatePolicyTable
    },
    data(){
        return {
            page:null,
            user: {},
            public_policies: [],
            private_policies: [],
            networkId: null,
            name: '',
            import_policy: 0,
            export_policy: 0,
            peer_group: null,
            ipv4_global_policy: 0,
            ipv6_global_policy: 0,
            modalEditPolicy: false,
            policy_id: null,
            peer_request_loader: false,
            policyGroupName: '',
            private_policy_group_name: '',
            private_policy: 0,
            email_templates: [],
            email_template_modal:false,
            edit_email_name: '',
            edit_email_content: '',
            selected_email_template_id: null,
            sandbox_name: null,
            selected_sandbox_policy: {},
            edit_sandbox_modal: false,
            sandbox_policy_default: false,
            ixes: [],
            selectedIx: '',
            show_create_policy_modal: false,
            sandbox_policies: [],
        }
    },
    async created(){
        this.page = this.$route.name.toLowerCase();
         // get user first net_id
         if(this.$store.getters.user.id){
            if(localStorage.getItem('activeNet')){
                this.networkId = localStorage.getItem('activeNet');
            }else{
                if(this.$store.getters.user.networks){
                    this.networkId  = this.$store.getters.user.networks[0].peeringdb_network_id;
                }
            }
            this.user  = {
                id: this.$store.getters.user.id,
            };
        }

        this.network_id = this.networkId;
        if(this.network_id){
            this.getAllPublicPolices(this.user.id, this.networkId, 1);
            this.getAllPrivatePolices(this.user.id, this.networkId, 1);
            this.getPolicyGroup(this.user.id, this.networkId);
            this.getPrivatePeerGroup(this.user.id, this.networkId);
            this.getEmailTemp(this.user.id, this.networkId);
            this.getAllSandboxPolicy(this.user.id, this.networkId);

            const ix = await this.getInternetExchange(this.networkId);
            this.ixes = ix;
            this.ixes.sort(function(a, b) {
                return a.name.localeCompare(b.name);
            });
        }
    },
    methods:{
        closeCreatePolicyModal(){
            this.show_create_policy_modal = false;
        },
        openCreatePolicyModal(){
            this.show_create_policy_modal = true;
        },
        getAllSandboxPolicy(user_id, net_id){
            if(net_id){
                PolicyService.getSandboxPolices(user_id, net_id).then(response => {
                    this.sandbox_policies = response.data.data;
                }).catch(error => {
                    if(error.response.status === 401){
                        this.$store.dispatch('logout');
                        this.$router.push('/')
                    }
                    if( (error.response.status !== 500 && error.response.status !== 400) && error.response.data.error){
                        Vue.$toast.error(error.response.data.error, {
                            position: 'top-right'
                        });
                    }else if(error.response.status !== 500 && error.response.status !== 400){
                        Vue.$toast.error(error.response.data, {
                            position: 'top-right'
                        });
                    }else if((error.response.status == 400)){
                        Vue.$toast.error(error.response.data.error, {
                            position: 'top-right'
                        });
                    }
                    else if((error.response.status === 500 || error.response.status == 400)){
                        Vue.$toast.error('Oooops! Something went wrong!', {
                            position: 'top-right'
                        });
                    }
                }); 
            }
        },
        // Get internet Exchanges for given user net_id
        getInternetExchange(user_net_id){
            return new Promise((resolve, reject)=>{
                NetService.getAllIx(this.user.id, user_net_id).then(response => {
                    resolve(response.data);
                }).catch(error => {
                    if(error.response.status === 401){
                        this.$store.dispatch('logout');
                        this.$router.push('/')
                    }

                    if(error.response.data.error){
                        Vue.$toast.error(error.response.data.error, {
                            position: 'top-right'
                        });
                    }else{
                        Vue.$toast.error('Oooops! Something went wrong!', {
                            position: 'top-right'
                        });
                    }
                    reject(error);
                });     
            });      
        },
        sandboxUpdated(sandbox){
            this.sandbox_policies.map(policy => {
                if(policy.id == sandbox.id){
                    policy.name = sandbox.name;
                    policy.sandbox_default = sandbox.sandbox_default;
                }
            })
            this.edit_sandbox_modal = false;
        },
        closeSandboxPolicyModal(value){
            if(value){
                this.selected_sandbox_policy = {};
                this.edit_sandbox_modal = false;
            }
        },
        editSandboxPolicy(policy){
            this.edit_sandbox_modal = true;
            this.selected_sandbox_policy = policy;
        },
        createSandboxPolicy(){
            if(this.networkId){
                const formData = new FormData();
                this.sandbox_name = this.sandbox_name.replaceAll(/\s/g,'').toUpperCase();
                this.sandbox_policy_default = (this.sandbox_policy_default) ? 1 : 0;
                formData.append('sandbox_name', this.sandbox_name);
                formData.append('network_id', this.networkId);
                formData.append('sandbox_default', this.sandbox_policy_default);

                PolicyService.createSandboxPolicy(this.user.id, this.networkId, formData).then(response => {
                    this.sandbox_policies.push(response.data);
                    this.sandbox_policies.sort(function(a, b) {
                        return a.policy_name.localeCompare(b.policy_name);
                    });
                    Vue.$toast.success('Sandbox policy created!',{
                            position: 'top-right'
                    });
                    this.sandbox_name = null;
                    this.sandbox_policy_default = false;
                }).catch(error => {
                    if(error.response.status === 401){
                        this.$store.dispatch('logout');
                        this.$router.push('/')
                    }
                    if( (error.response.status !== 500 && error.response.status !== 400) && error.response.data.error){
                        Vue.$toast.error(error.response.data.error, {
                            position: 'top-right'
                        });
                    }else if(error.response.status !== 500 && error.response.status !== 400){
                        Vue.$toast.error(error.response.data, {
                            position: 'top-right'
                        });
                    }else if((error.response.status == 400)){
                        Vue.$toast.error(error.response.data.error, {
                            position: 'top-right'
                        });
                    }
                    else if((error.response.status === 500 || error.response.status == 400)){
                        Vue.$toast.error('Oooops! Something went wrong!', {
                            position: 'top-right'
                        });
                    }
                });
            }
        },
        deleteEmailTemp(email_template_id){
            const formData = new FormData;
            formData.append('email_template_id', email_template_id);

            EmailTemplateService.deleteEmailTemplate(this.user.id, this.networkId, formData).then(response => {
                var hellper = [];
                this.email_templates.forEach(temp =>{
                    if(temp.id != response.data.id){
                       hellper.push(temp);
                    }
                });
                this.email_templates = hellper;
                 Vue.$toast.success('Template deleted!',{
                        position: 'top-right'
                });
            }).catch(error =>{
                if(error.response.status === 401){
                    this.$store.dispatch('logout');
                    this.$router.push('/')
                }
                if( (error.response.status !== 500 && error.response.status !== 400) && error.response.data.error){
                    Vue.$toast.error(error.response.data.error, {
                        position: 'top-right'
                    });
                }else if(error.response.status !== 500 && error.response.status !== 400){
                    Vue.$toast.error(error.response.data, {
                        position: 'top-right'
                    });
                }else if((error.response.status == 400)){
                    Vue.$toast.error(error.response.data.error, {
                        position: 'top-right'
                    });
                }
                else if((error.response.status === 500 || error.response.status == 400)){
                    Vue.$toast.error('Oooops! Something went wrong!', {
                        position: 'top-right'
                    });
                }
            });

        },
        editEmailTemp(value){
            const formData = new FormData;
            formData.append('email_name', value.email_name);
            formData.append('email_content', value.email_content);
            formData.append('email_template_id', this.selected_email_template_id);

            EmailTemplateService.editEmailTemplate(this.user.id, this.networkId, formData).then(response => {
                this.email_templates.forEach(temp =>{
                    if(temp.id == this.selected_email_template_id){
                        temp.name = response.data.name;
                        temp.content = response.data.content;
                    }
                });
                this.selected_email_template_id = null;
                 Vue.$toast.success('Template saved!',{
                        position: 'top-right'
                });
                this.email_template_modal = false;
            }).catch(error =>{
                if(error.response.status === 401){
                    this.$store.dispatch('logout');
                    this.$router.push('/')
                }
                if( (error.response.status !== 500 && error.response.status !== 400) && error.response.data.error){
                    Vue.$toast.error(error.response.data.error, {
                        position: 'top-right'
                    });
                }else if(error.response.status !== 500 && error.response.status !== 400){
                    Vue.$toast.error(error.response.data, {
                        position: 'top-right'
                    });
                }else if((error.response.status == 400)){
                    Vue.$toast.error(error.response.data.error, {
                        position: 'top-right'
                    });
                }
                else if((error.response.status === 500 || error.response.status == 400)){
                    Vue.$toast.error('Oooops! Something went wrong!', {
                        position: 'top-right'
                    });
                }
            });
        },
        openEmailTempModal(template_id){
            
            this.email_templates.forEach( temp => {
                if(temp.id == template_id){
                    this.selected_email_template_id = temp.id;
                    this.edit_email_name = temp.name;
                    this.edit_email_content = temp.content;
                }
            })
            this.email_template_modal = true;
        },
        closeEmailTempModal(){
            this.selected_email_template_id = null;
            this.email_template_modal = false;
        },
        getEmailTemp(user_id, net_id){
            EmailTemplateService.getEmailTemplates(user_id, net_id).then(response => {
                this.email_templates = response.data;
            }).catch(error =>{
                console.log(error);
            });
        },
        createEmailTemp(value){
            if(this.networkId){
                const formData = new FormData;
                formData.append('email_name', value.email_name);
                formData.append('email_content', value.email_content);

                EmailTemplateService.createEmailTemplate(this.user.id, this.networkId, formData).then(response => {
                    this.email_templates.push(response.data);
                    Vue.$toast.success('Email template created!',{
                            position: 'top-right'
                    });
                }).catch(error =>{
                    if(error.response.status === 401){
                        this.$store.dispatch('logout');
                        this.$router.push('/')
                    }
                    if( (error.response.status !== 500 && error.response.status !== 400) && error.response.data.error){
                        Vue.$toast.error(error.response.data.error, {
                            position: 'top-right'
                        });
                    }else if(error.response.status !== 500 && error.response.status !== 400){
                        Vue.$toast.error(error.response.data, {
                            position: 'top-right'
                        });
                    }else if((error.response.status == 400)){
                        Vue.$toast.error(error.response.data.error, {
                            position: 'top-right'
                        });
                    }
                    else if((error.response.status === 500 || error.response.status == 400)){
                        Vue.$toast.error('Oooops! Something went wrong!', {
                            position: 'top-right'
                        });
                    }
                });
            }
        },
        getPrivatePeerGroup(user_id, net_id){
            PolicyService.getPrivatePeerGroup(user_id, net_id).then(response => {
                this.private_policy_group_name = response.data.group_name;
            }).catch(error => {
                if(error.response.status === 401){
                    this.$store.dispatch('logout');
                    this.$router.push('/')
                }
                if( (error.response.status !== 500 && error.response.status !== 400) && error.response.data.error){
                    Vue.$toast.error(error.response.data.error, {
                        position: 'top-right'
                    });
                }else if(error.response.status !== 500 && error.response.status !== 400){
                    Vue.$toast.error(error.response.data, {
                        position: 'top-right'
                    });
                }else if((error.response.status == 400)){
                    Vue.$toast.error(error.response.data.error, {
                        position: 'top-right'
                    });
                }
                else if((error.response.status === 500 || error.response.status == 400)){
                    Vue.$toast.error('Oooops! Something went wrong!', {
                        position: 'top-right'
                    });
                }
            });
        },
        createPrivatePolicyGroup(){
            if(this.networkId){
                const formData = new FormData;
                this.private_policy_group_name = this.private_policy_group_name.replaceAll(/\s/g,'').toUpperCase();
                formData.append('group_name', this.private_policy_group_name);

                PolicyService.createPrivateGroupPolicy(this.user.id, this.networkId, formData).then(response =>{
                    if(response.data){
                        Vue.$toast.success('Private Peer Group Saved!',{
                            position: 'top-right'
                        });
                    }
                    
                }).catch(error=>{
                    if(error.response.status === 401){
                        this.$store.dispatch('logout');
                        this.$router.push('/')
                    }
                    if( (error.response.status !== 500 && error.response.status !== 400) && error.response.data.error){
                        Vue.$toast.error(error.response.data.error, {
                            position: 'top-right'
                        });
                    }else if(error.response.status !== 500 && error.response.status !== 400){
                        Vue.$toast.error(error.response.data, {
                            position: 'top-right'
                        });
                    }else if((error.response.status == 400)){
                        Vue.$toast.error(error.response.data.error, {
                            position: 'top-right'
                        });
                    }
                    else if((error.response.status === 500 || error.response.status == 400)){
                        Vue.$toast.error('Oooops! Something went wrong!', {
                            position: 'top-right'
                        });
                    }
                });
            }
        },
        deletePolicy(policy_id){
            var formData = new FormData;
            formData.append('policy_id', policy_id);
            PolicyService.delete(this.user.id, this.networkId, formData).then(response => {
                if(response.data){
                    
                    if(response.data.private == 0){
                        this.getAllPublicPolices(this.user.id, this.networkId, 1);
                    }else{
                        this.getAllPrivatePolices(this.user.id, this.networkId, 1);
                    }
                    Vue.$toast.success('Policy deleted!',{
                        position: 'top-right'
                    });
                }
            }).catch(error => {
                if(error.response.status === 401){
                    this.$store.dispatch('logout');
                    this.$router.push('/')
                }
                if( (error.response.status !== 500 && error.response.status !== 400) && error.response.data.error){
                    Vue.$toast.error(error.response.data.error, {
                        position: 'top-right'
                    });
                }else if(error.response.status !== 500 && error.response.status !== 400){
                    Vue.$toast.error(error.response.data, {
                        position: 'top-right'
                    });
                }else if((error.response.status == 400)){
                    Vue.$toast.error(error.response.data.error, {
                        position: 'top-right'
                    });
                }
                else if((error.response.status === 500 || error.response.status == 400)){
                    Vue.$toast.error('Oooops! Something went wrong!', {
                        position: 'top-right'
                    });
                }
            });
        },
        updatePolicy(policy){
            if(policy.private == 0){
               this.getAllPublicPolices(this.user.id, this.networkId, 1);
            }else{
                this.getAllPrivatePolices(this.user.id, this.networkId, 1);
            }

            this.closeEditPolicy(false);
        },
        openEditPolicy(policy_id){
            this.policy_id = policy_id;
            this.modalEditPolicy = true;
        },
        closeEditPolicy(modal){
            if(!modal){
                this.modalEditPolicy = false;
            }
        },
        changeImportPolicy(){
            this.export_policy = false;
        },
         changeExportPolicy(){
            this.import_policy = false;
        },
        onPrivateChange(){
            this.ipv4_global_policy = 0;
            this.ipv6_global_policy = 0;
        },
        onChangeGlobalPolicy(){
            this.private_policy = 0;
        },
        // onChange user net
        networkChanged(network_id){
            this.networkId = network_id;
            this.getAllPublicPolices(this.user.id, this.networkId, 1);
            this.getAllPrivatePolices(this.user.id, this.networkId, 1);
            this.getPolicyGroup(this.user.id, this.networkId);
            this.getPrivatePeerGroup(this.user.id, this.networkId);
            this.getEmailTemp(this.user.id, this.networkId);
            this.getAllSandboxPolicy(this.user.id, this.networkId);
        },
        getAllPublicPolices(user_id, net_id, currentPage){
            this.peer_request_loader = true;
            PolicyService.getPublicPolices(user_id, net_id, currentPage).then(response => {
                this.public_policies = response.data.data;
                this.public_policies.map(policy => policy.private == 0);
                this.public_policies.sort(function(a, b) {
                    return a.policy_name.localeCompare(b.policy_name);
                });
                this.peer_request_loader = false;
            }).catch(error => {
                if(error.response.status === 401){
                    this.$store.dispatch('logout');
                    this.$router.push('/')
                }
                Vue.$toast.error(error.response.data.error,{
                    position: 'top-right'
                });
                this.peer_request_loader = false;
            });
        },
        getAllPrivatePolices(user_id, net_id, currentPage){
            this.peer_request_loader = true;
            PolicyService.getPrivatePolices(user_id, net_id, currentPage).then(response => {
                this.private_policies = response.data.data;
                this.private_policies.map(policy => policy.private == 0);
                this.private_policies.sort(function(a, b) {
                    return a.policy_name.localeCompare(b.policy_name);
                });
                this.peer_request_loader = false;
            }).catch(error => {
                if(error.response.status === 401){
                    this.$store.dispatch('logout');
                    this.$router.push('/')
                }
                Vue.$toast.error(error.response.data.error,{
                    position: 'top-right'
                });
                this.peer_request_loader = false;
            });
        },
        createPolicy(){
            if(this.networkId){
                var name_error_element = document.getElementById('policy_name_error');
                name_error_element.classList.add('hidden');

                var import_export_error_element = document.getElementById('policy_import_export_error');
                import_export_error_element.classList.add('hidden');
                if(this.name == ''){
                    name_error_element = document.getElementById('policy_name_error');
                    name_error_element.classList.remove('hidden');
                    var error_message_element = document.getElementById('policy_name_error_message');
                    error_message_element.innerText = 'Name field is required!';
                    return false;
                }

                if(!this.import_policy && !this.export_policy){
                    import_export_error_element = document.getElementById('policy_import_export_error');
                    import_export_error_element.classList.remove('hidden');
                    return false;
                }
                this.name = this.name.replaceAll(/\s/g,'').toUpperCase();
                this.import_policy = (this.import_policy) ? 1 : 0;
                this.export_policy = (this.export_policy) ? 1 : 0;
                this.selectedIx = (this.selectedIx) ? this.selectedIx : 0;
                this.private_policy = (this.private_policy) ? 1 : 0;
                this.ipv4_global_policy = (this.ipv4_global_policy) ? 1 : 0;
                this.ipv6_global_policy = (this.ipv6_global_policy) ? 1 : 0;

                var formData = new FormData;
                formData.append('name', this.name);
                formData.append('import_policy', this.import_policy);
                formData.append('export_policy', this.export_policy);
                formData.append('peer_group', this.peer_group);
                formData.append('ipv4_global_policy', this.ipv4_global_policy);
                formData.append('ipv6_global_policy', this.ipv6_global_policy);
                formData.append('net_id', this.networkId);
                formData.append('private_policy', this.private_policy);
                formData.append('ix_id', this.selectedIx);

                if( (this.name && this.import_policy) || (this.name && this.export_policy) ){
                    
                    PolicyService.createPolicy(this.user.id, formData).then(response => {
                        if(response.data){

                            if(response.data.private == 0){
                                this.public_policies.push(response.data);
                                this.public_policies.sort(function(a, b) {
                                    return a.policy_name.localeCompare(b.policy_name);
                                });
                            }else{
                                this.private_policies.push(response.data);
                                this.private_policies.sort(function(a, b) {
                                    return a.policy_name.localeCompare(b.policy_name);
                                });
                            }
                            this.name = null;
                            this.import_policy = 0;
                            this.export_policy = 0;
                            this.ipv4_global_policy = 0;
                            this.ipv6_global_policy = 0;
                            this.private_policy = 0;
                            this.selectedIx = '';

                            Vue.$toast.success('Policy Added!',{
                                position: 'top-right'
                            });
                        }
                        
                    }).catch(error => {
                    if(error.response.status === 401){
                            this.$store.dispatch('logout');
                            this.$router.push('/')
                        }
                        if( (error.response.status !== 500 && error.response.status !== 400) && error.response.data.error){
                            Vue.$toast.error(error.response.data.error, {
                                position: 'top-right'
                            });
                        }else if(error.response.status !== 500 && error.response.status !== 400){
                            Vue.$toast.error(error.response.data, {
                                position: 'top-right'
                            });
                        }else if((error.response.status == 400)){
                            Vue.$toast.error(error.response.data.error, {
                                position: 'top-right'
                            });
                        }
                        else if((error.response.status === 500 || error.response.status == 400)){
                            Vue.$toast.error('Oooops! Something went wrong!', {
                                position: 'top-right'
                            });
                        }
                    });
                }else{
                    alert('You missed some fields');
                }
            }
        },
        createPolicyGroup(){
            if(this.networkId){
                const formData = new FormData;
                this.policyGroupName = this.policyGroupName.replaceAll(/\s/g,'').toUpperCase();
                formData.append('group_name', this.policyGroupName);

                PolicyService.createGroupPolicy(this.user.id, this.networkId, formData).then(response =>{
                    if(response.data){
                        Vue.$toast.success('Peer Group Saved!',{
                            position: 'top-right'
                        });
                    }
                    
                }).catch(error => {
                    if(error.response.status === 401){
                        this.$store.dispatch('logout');
                        this.$router.push('/')
                    }
                    if( (error.response.status !== 500 && error.response.status !== 400) && error.response.data.error){
                            Vue.$toast.error(error.response.data.error, {
                                position: 'top-right'
                            });
                        }else if(error.response.status !== 500 && error.response.status !== 400){
                            Vue.$toast.error(error.response.data, {
                                position: 'top-right'
                            });
                        }else if((error.response.status == 400)){
                            Vue.$toast.error(error.response.data.error, {
                                position: 'top-right'
                            });
                        }
                        else if((error.response.status === 500 || error.response.status == 400)){
                            Vue.$toast.error('Oooops! Something went wrong!', {
                                position: 'top-right'
                            });
                        }
                });
            }
        },
        getPolicyGroup(user_id, net_id){

            PolicyService.getPeerGroup(user_id, net_id).then(response => {
                this.policyGroupName = response.data.group_name;
            }).catch(error => {
               if(error.response.status === 401){
                    this.$store.dispatch('logout');
                    this.$router.push('/')
                }
                 if( (error.response.status !== 500 && error.response.status !== 400) && error.response.data.error){
                        Vue.$toast.error(error.response.data.error, {
                            position: 'top-right'
                        });
                    }else if(error.response.status !== 500 && error.response.status !== 400){
                        Vue.$toast.error(error.response.data, {
                            position: 'top-right'
                        });
                    }else if((error.response.status == 400)){
                        Vue.$toast.error(error.response.data.error, {
                            position: 'top-right'
                        });
                    }
                    else if((error.response.status === 500 || error.response.status == 400)){
                        Vue.$toast.error('Oooops! Something went wrong!', {
                            position: 'top-right'
                        });
                    }
            });
        }
    }
}
</script>