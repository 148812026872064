<template src="./template.html"></template>

<style src="./style.css" scoped></style>

<script>
import Vue from 'vue';

import NetworkSettingsService from '../../../services/user/NetworkSettings';

export default {
    name: 'ZeroTouchPeering',
     props: [
        'user',
        'networkId',
        'networks'
    ],
    data(){
        return{
            zero_touch_peering: false,
            update_modal: false,
            current_settings_zero: false,
        }
    },
    watch:{
        'networkId': function() {
            if(this.networkId){
                this.getNetworkSettings(this.user.id, this.networkId);
            }
        }
    },
    created(){
        if(this.networkId){
            this.getNetworkSettings(this.user.id, this.networkId);
        }
    },
    methods:{
        getNetworkSettings(user_id, net_id){
            if(net_id){
                NetworkSettingsService.getNetworkSettings(user_id, net_id).then(response => {
                    if(response.data){
                        this.current_settings_zero = (response.data.zero_touch_peering == 1) ? true : false;
                        this.zero_touch_peering = (response.data.zero_touch_peering == 1) ? true : false;
                    }else{
                        this.current_settings_zero = false;
                    }
                }).catch(error => {
                    if(error.response.status === 401){
                        this.$store.dispatch('logout');
                        this.$router.push('/')
                    }
                    if( (error.response.status !== 500 && error.response.status !== 400) && error.response.data.error){
                        Vue.$toast.error(error.response.data.error, {
                            position: 'top-right'
                        });
                    }else if(error.response.status !== 500 && error.response.status !== 400){
                        Vue.$toast.error(error.response.data, {
                            position: 'top-right'
                        });
                    }else if(error.response.status === 500 || error.response.status == 400){
                        Vue.$toast.error('Oooops! Something went wrong!', {
                            position: 'top-right'
                        });
                    }
                });
            }
            
        },
        enableZeroTouchPeering(){

            if(this.networkId){
                const formData = new FormData();
                formData.append('zero_touch_peering', this.zero_touch_peering);

                NetworkSettingsService.saveZeroTouchPeering(this.user.id, this.networkId, formData).then(response => {
                    if(response.data){
                        this.zero_touch_peering = response.data.zero_touch_peering;
                    }
                    this.update_modal = false;
                
                }).catch(error => {
                    if(error.response.status === 401){
                        this.$store.dispatch('logout');
                        this.$router.push('/')
                    }
                    if( (error.response.status !== 500 && error.response.status !== 400) && error.response.data.error){
                        Vue.$toast.error(error.response.data.error, {
                            position: 'top-right'
                        });
                    }else if(error.response.status !== 500 && error.response.status !== 400){
                        Vue.$toast.error(error.response.data, {
                            position: 'top-right'
                        });
                    }else if(error.response.status === 500 || error.response.status == 400){
                        Vue.$toast.error('Oooops! Something went wrong!', {
                            position: 'top-right'
                        });
                    }
                });
            }
        },
        openUpdateModal(){
            this.update_modal = true;
        },
        hideUpdateModal(){
            this.update_modal = false;
            this.zero_touch_peering = this.current_settings_zero;
        }
    }
}
</script>