<template src="./template.html"></template>

<script>
import Vue from 'vue';

// import services
import RouterServce from '../../services/user/RouterService';

export default {
    name: "PrivatePeering",
    props: ['user', 'networkId'],
    data(){
        return{
            userId: this.user.id,
            routers: [],
            router_types: [],
            router_type_id: null,
        }
    },
    watch: {
        'networkId': async function (value)  {
            this.networkId = value;
            await this.getAllRouters(this.userId, this.networkId);
        },
    },
    async created(){
        // set Internet Exchange
        this.network_id = this.networkId;
        const ix = this.networkId;
        this.ixes = ix;

        await this.routerTypes();
        await this.getAllRouters(this.userId, this.networkId);
    },
    methods:{
        updateRouterName(router_id, event){
            this.routers = this.routers.map(router => {
                if(router.id == router_id){
                    return {...router, router_name: event.target.value};
                }
                return router;
            });
        },
        updateRouterType(router_id, event){
            this.routers = this.routers.map(router => {
                if(router.id == router_id){
                    return {...router, router_type_id: event.target.value};
                }
                return router;
            });
        },
        
        routerTypes(){
            RouterServce.getRouterTypes().then(response => {
                this.router_types = response.data;
                 this.router_types.sort(function(a, b) {
                    return a.name.localeCompare(b.name);
                });
            }).catch(error =>{
                if(error.response.status === 401){
                    this.$store.dispatch('logout');
                    this.$router.push('/')
                }
                if( (error.response.status !== 500 && error.response.status !== 400) && error.response.data.error){
                    Vue.$toast.error(error.response.data.error, {
                        position: 'top-right'
                    });
                }else if(error.response.status !== 500 && error.response.status !== 400){
                    Vue.$toast.error(error.response.data, {
                        position: 'top-right'
                    });
                }else if(error.response.status === 500 || error.response.status == 400){
                    Vue.$toast.error('Oooops! Something went wrong!', {
                        position: 'top-right'
                    });
                }
            });
        },
        insertOrUpdateName(event, router_id){

            var router = this.routers.find(router => router.id === router_id);

            const formData = new FormData;
            formData.append('router_name', router.router_name);
            formData.append('router_id', router.id);
            formData.append('router_type_id', router.router_type_id);

            if(router_id && router.router_name && router.router_type_id){
                RouterServce.addRouterName(this.userId, this.networkId, formData).then(response => {
                    if(response.data){
                         Vue.$toast.success('Saved!',{
                            position: 'top-right'
                        });
                    }     
                }).catch(error => {
                    if(error.response.data.error){
                        Vue.$toast.error(error.response.data.error, {
                            position: 'top-right'
                        });
                    }else{
                        Vue.$toast.error('Oooops! Something went wrong!', {
                            position: 'top-right'
                        });
                    }
                });
            }else{
                alert('Fields "Router Name" and "Router Type" not defined!');
            }

        },
        getAllRouters(user_id, net_id){
            RouterServce.getRouters(user_id, net_id).then(response =>{
                this.routers = response.data;
            }).catch(error => {
                if(error.response.status === 401){
                    this.$store.dispatch('logout');
                    this.$router.push('/')
                }
                if( (error.response.status !== 500 && error.response.status !== 400) && error.response.data.error){
                    Vue.$toast.error(error.response.data.error, {
                        position: 'top-right'
                    });
                }else if(error.response.status !== 500 && error.response.status !== 400){
                    Vue.$toast.error(error.response.data, {
                        position: 'top-right'
                    });
                }else if(error.response.status === 500 || error.response.status == 400){
                    Vue.$toast.error('Oooops! Something went wrong!', {
                        position: 'top-right'
                    });
                }
            });
        },
    }
}
</script>