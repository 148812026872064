import Vue from 'vue'
import VueRouter from 'vue-router'
import DashboardComponent from '../views/DashboardComponent/DashboardComponent.vue';
import HomeComponent from '../views/HomeComponent/HomeComponent.vue'
import PeerListComponent from '../views/PeerListComponent/PeerListComponent.vue'
import StatsComponent from '../views/StatsComponent/StatsComponent.vue'
import TemplateComponent from '../views/TemplateComponent/TemplateComponent.vue'
import RequestComponent from '../views/RequestComponent/RequestComponent.vue'
import SessionsComponent from '../views/SessionsComponent/SessionsComponent.vue'
import DeviceSettingsComponent from '../views/DeviceSettingsComponent/DeviceSettingsComponent.vue';
import SettingsComponent from '../views/SettingsComponent/SettingsComponent.vue';
import NetworkProfileComponent from '../views/NetworkProfileComponent/NetworkProfileComponent.vue';
import PrivatePeeringComponent from '../views/PrivatePeeringComponent/PrivatePeeringComponent';
// import BillingComponent from '../views/BillingComponent/BillingComponent';
import PageNotFound from '../views/PageNotFoundComponent/PageNotFoundComponent';
import PrivacyPolicy from '../views/PrivacyPolicyComponent/PrivacyPolicyComponent';
import TermsAndCondition from '../views/TermsAndConditionComponent/TermsAndConditionComponent';
import Eula from '../views/EulaComponent/EulaComponent';

import store from '../store/modules/authStore'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomeComponent
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: DashboardComponent,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/peer-list',
    name: 'PeerList',
    component: PeerListComponent,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/private-peer-list',
    name: 'PrivatePeering',
    component: PrivatePeeringComponent,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/stats',
    name: 'Stats',
    component: StatsComponent,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/policies',
    name: 'Template',
    component: TemplateComponent,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/sessions',
    name: 'Sessions',
    component: SessionsComponent,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/requests',
    name: 'Request',
    component: RequestComponent,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/devices',
    name: 'Devices',
    component: DeviceSettingsComponent,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/settings',
    name: 'Settings',
    component: SettingsComponent,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/profile/:id',
    name: 'NetworkProfile',
    component: NetworkProfileComponent,
    meta: {
      requiresAuth: true
    }
  },
  // {
  //   path: '/billing',
  //   name: 'Billing',
  //   component: BillingComponent,
  //   meta: {
  //     requiresAuth: true
  //   }
  // },
  {
    path: '/legal/privacy',
    name: 'Privacy Policy',
    component: PrivacyPolicy,
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/legal/terms',
    name: 'Terms and Condition',
    component: TermsAndCondition,
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/legal/eula',
    name: 'Eula',
    component: Eula,
    meta: {
      requiresAuth: false
    }
  },
  { path: "*", component: PageNotFound }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})


router.beforeEach((to, from, next) => {
  if(to.matched.some(record => record.meta.requiresAuth)) {
    if (store.state.userProfile.id && store.state.status && store.state.token) {
      next()
      return
    }else{
      next('/')
    }
  } else {
    next()
  }
})

export default router
