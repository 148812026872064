import ApiService from "../../services/ApiService";

export default {

    createPolicy(user_id, data) {
        return ApiService.post(`user/${user_id}/polices`, data);
    },
    createGroupPolicy(user_id, net_id, data) {
        return ApiService.post(`user/${user_id}/net/${net_id}/peerGroup`, data);
    },
    createPrivateGroupPolicy(user_id, net_id, data) {
        return ApiService.post(`user/${user_id}/net/${net_id}/privatePeerGroup`, data);
    },
    getPublicPolices(user_id, net_id, currentPage) {
        return ApiService.get(`user/${user_id}/net/${net_id}/publicPolicies/all?page=${currentPage}`);
    },
    getPrivatePolices(user_id, net_id, currentPage) {
        return ApiService.get(`user/${user_id}/net/${net_id}/privatePolicies/all?page=${currentPage}`);
    },
    getPolices(user_id, net_id) {
        return ApiService.get(`user/${user_id}/net/${net_id}/polices`);
    },
    getSandboxPolices(user_id, net_id) {
        return ApiService.get(`user/${user_id}/net/${net_id}/sandboxPolicies/all`);
    },
    getPolicyById(user_id, net_id, policy_id){
        return ApiService.get(`user/${user_id}/net/${net_id}/polices`, policy_id);
    },
    updatePolicy(user_id, net_id, policy_id, data){
        return ApiService.post(`user/${user_id}/net/${net_id}/polices/${policy_id}`, data);
    },
    delete(user_id, net_id, data){
        return ApiService.post(`user/${user_id}/net/${net_id}/deletePolicy`, data);
    },
    getPeerGroup(user_id, net_id){
        return ApiService.get(`user/${user_id}/net/${net_id}/peerGroup`);
    },
    getPrivatePeerGroup(user_id, net_id){
        return ApiService.get(`user/${user_id}/net/${net_id}/privatePeerGroup`);
    },
    createSandboxPolicy(user_id, net_id, data){
        return ApiService.post(`user/${user_id}/net/${net_id}/polices/sandbox`, data);
    },
    updateSandboxPolicy(user_id, net_id, policy_id, data){
        return ApiService.post(`user/${user_id}/net/${net_id}/polices/sandbox/${policy_id}`, data);
    },
    getPolicesForIx(user_id, net_id, ix_id) {
        return ApiService.get(`user/${user_id}/net/${net_id}/ix/${ix_id}/polices`);
    },
    editPolicesForIx(user_id, net_id, ix_id, data) {
        return ApiService.post(`user/${user_id}/net/${net_id}/ix/${ix_id}/polices/edit`, data);
    },
}