import ApiService from "../../services/ApiService";

export default {

    getConfig(user_id, netix_id, net_id, router_type, network_router) {
        return ApiService.get(`user/${user_id}/netix/${netix_id}/net/${net_id}/routerType/${router_type}/networkRouter/${network_router}/routerConfig`);
    },
    getPeerConfig(user_id, netix_id, net_id, router_type, peering_id){
        return ApiService.get(`user/${user_id}/netix/${netix_id}/net/${net_id}/routerType/${router_type}/peerConfig/${peering_id}`);
    }
}