import ApiService from "../ApiService";

export default {

    getRequest(user_id, net_id, currentPage) {
        return ApiService.get(`user/${user_id}/net/${net_id}/peering_request?page=${currentPage}`);
    },
    getLastPeeringRequest(user_id, net_id, ix_id, to_network){
        return ApiService.get(`user/${user_id}/net/${net_id}/netix/${ix_id}/lastPeeringRequest/${to_network}`);
    },
    approveRequest(user_id, net_id, data) {
        return ApiService.post(`user/${user_id}/net/${net_id}/approve_request`, data);
    },
    bulkApproveRequest(user_id, net_id, data) {
        return ApiService.post(`user/${user_id}/net/${net_id}/approveSelectedRequest`, data);
    },
    updateRequest(user_id, net_id, data){
        return ApiService.post(`user/${user_id}/net/${net_id}/peering_request/update`, data);
    },
    cancelRequest(user_id, net_id, data){
        return ApiService.post(`user/${user_id}/net/${net_id}/cancelRequest`, data);
    },
    getPeeredRequests(user_id, net_id, currentPage) {
        return ApiService.get(`user/${user_id}/net/${net_id}/peered_request?page=${currentPage}`);
    },
    serachPeeredRequests(user_id, net_id, selectedDisplay, query) {
        return ApiService.get(`user/${user_id}/net/${net_id}/peered_request/ix/${selectedDisplay}/search`,query);
    },
    getRouter(user_id, net_id, network_ixlan_id) {
        return ApiService.get(`user/${user_id}/net/${net_id}/getRouterInfo`,network_ixlan_id);
    },
    serachPeeringRequests(user_id, net_id, selectedDisplay, query) {
        return ApiService.get(`user/${user_id}/net/${net_id}/peering_request/ix/${selectedDisplay}/search`,query);
    },
    dePeer(user_id, net_id, data){
        return ApiService.post(`user/${user_id}/net/${net_id}/dePeer`, data);
    },
    dePeerSelected(user_id, net_id, data){
        return ApiService.post(`user/${user_id}/net/${net_id}/dePeerSelected`, data);
    },
    getAllRequests(user_id, net_id){
        return ApiService.get(`user/${user_id}/net/${net_id}/requests`);
    },
    getNewRequestsDashboard(user_id, net_id){
        return ApiService.get(`user/${user_id}/net/${net_id}/newRequests`);
    },
    manuallyAddSession(user_id, net_id, data) {
        return ApiService.post(`user/${user_id}/net/${net_id}/addManuallySession`, data);
    },
    bulkManuallyAddSession(user_id, net_id, data) {
        return ApiService.post(`user/${user_id}/net/${net_id}/bulkAddManuallySession`, data);
    },
    rejectRequest(user_id, net_id, data) {
        return ApiService.post(`user/${user_id}/net/${net_id}/peeringRequest/reject`, data);
    }
}