import ApiService from "../ApiService";

export default {
    getSessionsStats(user_id, net_id){
        return ApiService.get(`user/${user_id}/net/${net_id}/sessions_stats`);
    },
    getSessionsStatsByIpv4AndIpv6(user_id, net_id){
        return ApiService.get(`user/${user_id}/net/${net_id}/sessions_statsByIpv`);
    },
    getSessionsDashStats(user_id, net_id){
        return ApiService.get(`user/${user_id}/net/${net_id}/sessions_stats_dashboard`);
    },
    getPrivateSessionsDashStats(user_id, net_id){
        return ApiService.get(`user/${user_id}/net/${net_id}/private_sessions_stats_dashboard`);
    },
    getSessionsStatsByType(user_id, net_id){
        return ApiService.get(`user/${user_id}/net/${net_id}/sessions_stats_by_type`);
    },
    getSessionsStatsByGeo(user_id, net_id){
        return ApiService.get(`user/${user_id}/net/${net_id}/sessions_stats_by_geo`);
    },
    getSessionsStatsByIx(user_id, net_id,continent){
        return ApiService.get(`user/${user_id}/net/${net_id}/sessions_stats_by_ix/continent/${continent}`);
    },
    getIxesGeoLocations(user_id, net_id){
        return ApiService.get(`user/${user_id}/net/${net_id}/netixes/ixesGeoLocation`);
    },
    getSessionsStatsByNet(user_id, net_id, id){
        return ApiService.get(`user/${user_id}/net/${net_id}/sessionsStatsByNet/${id}`);
    },
    getNetworkSessionsStatsByIx(user_id, net_id,continent, id){
        return ApiService.get(`user/${user_id}/net/${net_id}/networkSessionStatsByIx/${id}/continent/${continent}`);
    },
    getNetworkSessionsStatsByType(user_id, net_id, id){
        return ApiService.get(`user/${user_id}/net/${net_id}/networkSessionsByType/${id}`);
    },
    getNetworkSessionsStatsByGeo(user_id, net_id, id){
        return ApiService.get(`user/${user_id}/net/${net_id}/networkSessionsByGeo/${id}`);
    },
    getTotalNumOfPrivateSessions(user_id, net_id){
        return ApiService.get(`user/${user_id}/net/${net_id}/private_sessions/count`);
    },
    getTotalNumOfPublicIxPorts(user_id, net_id){
        return ApiService.get(`user/${user_id}/net/${net_id}/sessions_ix_ports/total`);
    },
    getTotalNumOfPublicCapacity(user_id, net_id){
        return ApiService.get(`user/${user_id}/net/${net_id}/sessions_capacity/total`);
    },
    getTotalNumOfPrivateIxPorts(user_id, net_id){
        return ApiService.get(`user/${user_id}/net/${net_id}/private_sessions_ix_ports/total`);
    },
    getTotalNumOfPrivateCapacity(user_id, net_id){
        return ApiService.get(`user/${user_id}/net/${net_id}/private_sessions_capacity/total`);
    },
}