<template src="./template.html"></template>

<script>
import Vue from 'vue';

// import component
import DashboardTopNav from "../../components/DashboardTopNavComponent/DashboardTopNavComponent";
import SideBarNavigation from "../../components/SidebarNavigationComponent/SidebarNavigationComponent";
import IternetExchange from "../../components/InternetExchangeComponent/InternetExchangeComponent";
import Footer from '../../components/FooterDashboardComponent/FooterDashboardComponent';
import SessionsTable from '../../components/SessionsTableComponent/SessionsTableComponent';

// import services
import NetService from '../../services/NetService';
import SessionsService from "../../services/user/SessionsService";

export default {
    name: "PeeringRequest",
    components:{
        DashboardTopNav,
        SideBarNavigation,
        IternetExchange,
        SessionsTable,
        Footer
    },
    data(){
        return {
            page: null,
            user: {},
            networkId: null,
            selectedIx: null,
            ixes: [],
            network_id: null,
            total_sessions: 0,
            background_of_modal: false,

        }
    },
    async created(){
        this.page = this.$route.name.toLowerCase();
        // get user first net_id
        if(this.$store.getters.user.id){
            if(localStorage.getItem('activeNet')){
                this.networkId = localStorage.getItem('activeNet');
            }else{
                if(this.$store.getters.user.networks){
                    this.networkId  = this.$store.getters.user.networks[0].peeringdb_network_id;
                }
            }
            this.user  = {
                id: this.$store.getters.user.id,
            };
        }

        // set Internet Exchange
        this.network_id = this.networkId;

        if(this.network_id){
            const ix = this.networkId;
            this.ixes = ix;

            await this.allSessions(this.user.id, this.networkId);
        }
        
    },
    methods:{
        modalChange(modal){
            if(modal){
                this.background_of_modal = true;
            }else{
                this.background_of_modal = false;
            }
        },
        async updateSession(value){
            if(value){
                await this.allSessions(this.user.id, this.networkId);
                await this.allRequests(this.user.id, this.networkId);
            }
            
        },
         // get all session for net
        allSessions(user_id, net){
            SessionsService.getAllSessions(user_id, net).then(response => {
                this.totalSessions(response.data);
            }).catch(error => {
                if(error.response.status === 401){
                    this.$store.dispatch('logout');
                    this.$router.push('/')
                }
                if( (error.response.status !== 500 && error.response.status !== 400) && error.response.data.error){
                    Vue.$toast.error(error.response.data.error, {
                        position: 'top-right'
                    });
                }else if(error.response.status !== 500 && error.response.status !== 400){
                    Vue.$toast.error(error.response.data, {
                        position: 'top-right'
                    });
                }else if(error.response.status === 500 || error.response.status == 400){
                    Vue.$toast.error('Oooops! Something went wrong!', {
                        position: 'top-right'
                    });
                }

                if(error.response.status === 401){
                    this.$store.dispatch('logout');
                    this.$router.push('/')
                }
            });
        },
        //get  number of active sessions
        totalSessions(value){
            if(value > 0){
                for(let i = 0; i < value; i++){
                    if(this.total_sessions < value){
                        this.total_sessions++;
                    }else{
                        this.interval = null;
                    }
                }
                
            }else{
                this.interval = null;
                this.total_sessions = value;
            }
        },
        // onChange user net
        async networkChanged(network_id){
            this.network_id = network_id;
            this.networkId = network_id;
            await this.allSessions(this.user.id, this.networkId);
            await this.allRequests(this.user.id, this.networkId);
        },
        // Get internet Exchanges for given user net_id
        getInternetExchange(user_net_id){
            return new Promise((resolve, reject)=>{
                NetService.getAllIx(user_net_id).then(response => {
                    resolve(response.data);
                }).catch(error => {
                    if(error.response.status === 401){
                        this.$store.dispatch('logout');
                        this.$router.push('/')
                    }
                    if( (error.response.status !== 500 && error.response.status !== 400) && error.response.data.error){
                        Vue.$toast.error(error.response.data.error, {
                            position: 'top-right'
                        });
                    }else if(error.response.status !== 500 && error.response.status !== 400){
                        Vue.$toast.error(error.response.data, {
                            position: 'top-right'
                        });
                    }else if(error.response.status === 500 || error.response.status == 400){
                        Vue.$toast.error('Oooops! Something went wrong!', {
                            position: 'top-right'
                        });
                    }
                    reject(error);
                });     
            });      
        }
    }
}
</script>