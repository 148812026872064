<template src="./template.html"></template>

<script>
import Vue from 'vue';

import DashboardTopNav from "../../components/DashboardTopNavComponent/DashboardTopNavComponent";
import SideBarNavigation from "../../components/SidebarNavigationComponent/SidebarNavigationComponent";
import Footer from '../../components/FooterDashboardComponent/FooterDashboardComponent';
import ManualAddSessionTable from '../../components/ManualAddSessionTableComponent/ManualAddSessionTableComponent';
import PublicSessionTable from '../../components/PublicSessionTableComponent/PublicSessionTableComponent';
import PrivateSessionTable from '../../components/PrivateSessionTableComponent/PrivateSessionTableComponent';

import ProfileChartByIx from '../../components/ProfileChartByIx';
import ProfileSessionChartByIx from '../../components/ProfileSessionChartByIx';

// import services
import RequestService from "../../services/user/RequestService";
import SessionStats from "../../services/user/SessionStatsService";
import NetService from '../../services/NetService';
import SessionService from '../../services/user/SessionsService';
import PolicyService from '../../services/user/PolicyService';
import ActivityLogService from '../../services/user/ActivityLogService';

export default {
    name: 'NetworkProfile',
    components:{
        DashboardTopNav,
        SideBarNavigation,
        Footer,
        ProfileChartByIx,
        ProfileSessionChartByIx,
        ManualAddSessionTable,
        PublicSessionTable,
        PrivateSessionTable
    },
    data(){
        return{
            user: {},
            network_id: null,
            networkId: null,
            networkInformation: {},
            total_sessions: 0,
            totalSessionsByIx: [],
            totalSessionsByGeo: [],
            ixesGeoLoc: [],
            modalDePeerRequest:false,
            selectedCont: "North America",
            colors: ['#2e91b8', '#2c5fc7', '#4e6aa3', '#4fb52d', '#b55924', '#23baa1', '#9e2942'],
            chartOptions:{
                responsive : true,
                maintainAspectRatio: false,
                cutoutPercentage: 60,
                scales: {
                yAxes: [{
                        display: false,
                        ticks: {
                        beginAtZero: true
                        }
                    }]
                },
            },
            chartOptionsIx:{
                responsive : true,
                maintainAspectRatio: false,
                scales: {
                    xAxes: [{
                        barPercentage: 0.7
                    }],
                    yAxes: [{
                        display: true,
                        ticks: {
                        beginAtZero: true
                        }
                    }]
                },
            },
            selectedEvent: null,
            selectedDePeer: null,
            de_peer_note: '',
            network_settings_modal: false,
            policies: [],
            selectedIpv4: null,
            selectedIpv6: null,
            selectedIpv4_out: null,
            selectedIpv6_out: null,
            network_logs: []
        }
    },
    watch:{
        '$route.params.id': {
            handler: async function(id) {
                 // get user first net_id
                if(this.$store.getters.user.id){
                    if(localStorage.getItem('activeNet')){
                        this.networkId = localStorage.getItem('activeNet');
                    }else{
                        this.networkId  = this.$store.getters.user.networks[0].peeringdb_network_id; //Production
                    }
                    this.user  = {
                        id: this.$store.getters.user.id,
                    };
                }
                this.networkInformation = await this.getNetInfo(this.user.id, this.networkId, id);
                await this.getSessions(this.user.id, this.networkId, id);
                await this.getGeoLoc(this.user.id, this.networkId);

                const sessionsByIx = await this.getSessionsByIx(this.user.id, this.networkId, this.selectedCont,  id);
                for(let i = 0; i < sessionsByIx.length; i++){
                this.totalSessionsByIx.push({name: sessionsByIx[i].name, total:sessionsByIx[i].total, color: '#3898e8'});
                }

                const sessionsByGeo = await this.getSessionsByGeo(this.user.id, this.networkId, id);
                for(let i = 0; i < sessionsByGeo.length; i++){
                    var total_geo = 0;
                    if(sessionsByGeo[i].total > 0){
                        total_geo = sessionsByGeo[i].total;
                    }else{
                        total_geo = 0;
                    }
                this.totalSessionsByGeo.push({name: sessionsByGeo[i].region_continent, total: total_geo, color: this.colors[i]});
                }

                this.getAllNetworkLogsActivity(this.user.id, this.networkId, id, 0);
            },
            deep: true,
            immediate: true
        }
    },
    methods:{
        loadMoreLogs(){
            var skip = this.network_logs.length;
            this.getAllNetworkLogsActivity(this.user.id, this.networkId, this.$route.params.id, skip);
        },
        getAllNetworkLogsActivity(user_id, net_id, for_net, skip){
            ActivityLogService.getAllNetworkLogs(user_id, net_id, for_net, skip).then(response => {
                if(response.data){
                    if(response.data.data.length > 0){
                            this.network_logs = response.data.data;
                    }else{
                        document.getElementById('loadMore').classList.add('hidden');
                    }   
                }
            }).catch(error => {
                if(error.response.status === 401){
                    this.$store.dispatch('logout');
                    this.$router.push('/')
                }
                if( (error.response.status !== 500 && error.response.status !== 400) && error.response.data.error){
                    Vue.$toast.error(error.response.data.error, {
                        position: 'top-right'
                    });
                }else if(error.response.status !== 500 && error.response.status !== 400){
                    Vue.$toast.error(error.response.data, {
                        position: 'top-right'
                    });
                }else if(error.response.status === 500 || error.response.status == 400){
                    Vue.$toast.error('Oooops! Something went wrong!', {
                        position: 'top-right'
                    });
                }
            });
        },
        getAllPolices(user_id, net_id){
            PolicyService.getPolices(user_id, net_id).then(response => {
                this.policies = response.data.data;
                this.policies.forEach( policy => {
                    if(policy.global_v6_policy && policy.import_policy){
                        this.selectedIpv6 = policy.id;
                    }else if(policy.global_v6_policy && policy.export_policy){
                        this.selectedIpv6_out = policy.id;
                    }

                    if(policy.global_v4_policy && policy.import_policy){
                        this.selectedIpv4 = policy.id;
                    }else if(policy.global_v4_policy && policy.export_policy){
                        this.selectedIpv4_out = policy.id;
                    }
                });
            }).catch(error => {
                if(error.response.status === 401){
                    this.$store.dispatch('logout');
                    this.$router.push('/')
                }
                if( (error.response.status !== 500 && error.response.status !== 400) && error.response.data.error){
                    Vue.$toast.error(error.response.data.error, {
                        position: 'top-right'
                    });
                }else if(error.response.status !== 500 && error.response.status !== 400){
                    Vue.$toast.error(error.response.data, {
                        position: 'top-right'
                    });
                }else if(error.response.status === 500 || error.response.status == 400){
                    Vue.$toast.error('Oooops! Something went wrong!', {
                        position: 'top-right'
                    });
                }
            });
        },
        goLive(peer_network){

            const formData = new FormData();
            formData.append('peer_network_id', peer_network);
            formData.append('policy_v4', this.selectedIpv4);
            formData.append('export_policy_v4', this.selectedIpv4_out);
            formData.append('policy_v6', this.selectedIpv6);
            formData.append('export_policy_v6', this.selectedIpv6_out);

            SessionService.allPolicyToProduction(this.user.id, this.networkId, formData).then(response => {
                if(response.data){
                    Vue.$toast.success('Saved!',{
                        position: 'top-right'
                    });

                    this.network_settings_modal = false;
                }
            }).catch(error => {
               if(error.response.status === 401){
                    this.$store.dispatch('logout');
                    this.$router.push('/')
                }
                if( (error.response.status !== 500 && error.response.status !== 400) && error.response.data.error){
                    Vue.$toast.error(error.response.data.error, {
                        position: 'top-right'
                    });
                }else if(error.response.status !== 500 && error.response.status !== 400){
                    Vue.$toast.error(error.response.data, {
                        position: 'top-right'
                    });
                }else if(error.response.status === 500 || error.response.status == 400){
                    Vue.$toast.error('Oooops! Something went wrong!', {
                        position: 'top-right'
                    });
                }
            });
        },
        goToSandbox(peer_network){

            const formData = new FormData();
            formData.append('peer_network_id', peer_network);

            SessionService.allPolicyTosandbox(this.user.id, this.networkId, formData).then(response => {
                if(response.data){
                    Vue.$toast.success('All sessions are in sandbox',{
                        position: 'top-right'
                    });

                    this.network_settings_modal = false;
                }
                
            }).catch(error => {
                if(error.response.status === 401){
                    this.$store.dispatch('logout');
                    this.$router.push('/')
                }
                if( (error.response.status !== 500 && error.response.status !== 400) && error.response.data.error){
                    Vue.$toast.error(error.response.data.error, {
                        position: 'top-right'
                    });
                }else if(error.response.status !== 500 && error.response.status !== 400){
                    Vue.$toast.error(error.response.data, {
                        position: 'top-right'
                    });
                }else if(error.response.status === 500 || error.response.status == 400){
                    Vue.$toast.error('Oooops! Something went wrong!', {
                        position: 'top-right'
                    });
                }
            });
        },
        async closeNetworkSettingsModal(){
            this.network_settings_modal = false;
            this.networkInformation = await this.getNetInfo(this.user.id, this.networkId,this.$route.params.id);
            await this.getSessions(this.user.id, this.networkId, this.$route.params.id);
            await this.getGeoLoc(this.user.id, this.networkId);

            const sessionsByIx = await this.getSessionsByIx(this.user.id, this.networkId, this.selectedCont,  this.$route.params.id);
            this.totalSessionsByIx = [];
            for(let i = 0; i < sessionsByIx.length; i++){
            this.totalSessionsByIx.push({name: sessionsByIx[i].name, total:sessionsByIx[i].total, color: '#3898e8'});
            }

            const sessionsByGeo = await this.getSessionsByGeo(this.user.id, this.networkId, this.$route.params.id);
            this.totalSessionsByGeo = [];
            for(let i = 0; i < sessionsByGeo.length; i++){
                var total_geo = 0;
                if(sessionsByGeo[i].total > 0){
                    total_geo = sessionsByGeo[i].total;
                }else{
                    total_geo = 0;
                }
                this.totalSessionsByGeo.push({name: sessionsByGeo[i].region_continent, total: total_geo, color: this.colors[i]});
            }
        },
        openNetworkSettingsModal(){
            this.network_settings_modal = true;
            this.getAllPolices(this.user_id, this.networkId);
        },
        confirmDialog(value){
            if(value == true){

                document.getElementsByClassName('session_loader_n')[0].classList.remove('hidden');
                document.getElementsByClassName('dePeerButton')[0].classList.add('hidden');
                const formData = new FormData;
                formData.append('network_id', this.selectedDePeer);
                formData.append('de_peer_note', this.de_peer_note);
                
                RequestService.dePeer(this.user.id, this.networkId, formData).then(response =>{
                    // show user message
                    Vue.$toast.success('Peer deleted!',{
                        position: 'top-right'
                    });
                    this.$emit('change-peered', response.data.length);

                    document.getElementsByClassName('session_loader_n')[0].classList.add('hidden');
                    document.getElementsByClassName('dePeerButton')[0].classList.remove('hidden');
                    this.$router.push('/requests');

                }).catch(error => {
                    if(error.response.data.error){
                        Vue.$toast.error(error.response.data.error, {
                            position: 'top-right'
                        });
                    }else{
                        Vue.$toast.error('Oooops! Something went wrong!', {
                            position: 'top-right'
                        });
                    }
                    document.getElementsByClassName('session_loader_n')[0].classList.add('hidden');
                    document.getElementsByClassName('dePeerButton')[0].classList.remove('hidden');
                });
            }
            this.modalDePeerRequest = false;
            this.selectedDePeer = null;
        },
         dePeer(peering_id, event){
            this.selectedEvent = event;
            this.selectedDePeer = peering_id;
            this.modalDePeerRequest = true;         
        },
        async changeContinent(){
            this.totalSessionsByIx = [];
            const sessionsByIx = await this.getSessionsByIx(this.user.id, this.networkId, this.selectedCont,  this.$route.params.id);
            for(let i = 0; i < sessionsByIx.length; i++){
            this.totalSessionsByIx.push({name: sessionsByIx[i].name, total:sessionsByIx[i].total, color: '#3898e8'});
            }
        },
        getGeoLoc(user_id, net_id){
            SessionStats.getIxesGeoLocations(user_id, net_id).then(response => {
                this.ixesGeoLoc = response.data;
            }).catch(error => {
                console.log(error);
            });
        },
         async getSessionsByGeo(user_id, net_id, id){
            return new Promise((resolve, reject) => {
                SessionStats.getNetworkSessionsStatsByGeo(user_id, net_id, id).then(response => {
                    resolve(response.data);
                }).catch(error => {
                    reject(error);
                });
            });
        },
        async getNetInfo(user_id, net_id, id){
            return new Promise((resolve, reject) => {
                NetService.getNetworkInfo(user_id, net_id, id).then(response => {
                    resolve(response.data);
                }).catch(error => {
                    if(error.response.data.error){
                        Vue.$toast.error(error.response.data.error, {
                            position: 'top-right'
                        });
                    }else{
                        Vue.$toast.error('Oooops! Something went wrong!', {
                            position: 'top-right'
                        });
                    }
                    reject(error);
                });
            });
        },
        async getSessionsByIx(user_id, net_id, continent, id){
            return new Promise((resolve, reject) => {
                SessionStats.getNetworkSessionsStatsByIx(user_id, net_id, continent, id).then(response => {
                    resolve(response.data);
                }).catch(error => {
                    if(error.response.data.error){
                        Vue.$toast.error(error.response.data.error, {
                            position: 'top-right'
                        });
                    }else{
                        Vue.$toast.error('Oooops! Something went wrong!', {
                            position: 'top-right'
                        });
                    }
                    reject(error);
                });
            });
        },
        getSessions(user_id, net_id, id){
            SessionStats.getSessionsStatsByNet(user_id, net_id, id).then(response => {
                if(response.data.length > 0){
                    this.total_sessions = response.data[0].quantity;
                }
            }).catch(error => {
                if(error.response.status === 401){
                    this.$store.dispatch('logout');
                    this.$router.push('/')
                }
                if( (error.response.status !== 500 && error.response.status !== 400) && error.response.data.error){
                    Vue.$toast.error(error.response.data.error, {
                        position: 'top-right'
                    });
                }else if(error.response.status !== 500 && error.response.status !== 400){
                    Vue.$toast.error(error.response.data, {
                        position: 'top-right'
                    });
                }else if(error.response.status === 500 || error.response.status == 400){
                    Vue.$toast.error('Oooops! Something went wrong!', {
                        position: 'top-right'
                    });
                }
            });
        },
        // onChange user net
        async networkChanged(network_id){
            this.$router.push('/sessions');
            this.network_id = network_id;
            this.networkId = network_id;

            this.networkInformation = await this.getNetInfo(this.user.id, this.networkId,this.$route.params.id);
            await this.getSessions(this.user.id, this.networkId, this.$route.params.id);
            await this.getGeoLoc(this.user.id, this.networkId);

            const sessionsByIx = await this.getSessionsByIx(this.user.id, this.networkId, this.selectedCont,  this.$route.params.id);
            for(let i = 0; i < sessionsByIx.length; i++){
            this.totalSessionsByIx.push({name: sessionsByIx[i].name, total:sessionsByIx[i].total, color: '#3898e8'});
            }

            const sessionsByGeo = await this.getSessionsByGeo(this.user.id, this.networkId, this.$route.params.id);
            for(let i = 0; i < sessionsByGeo.length; i++){
                var total_geo = 0;
                if(sessionsByGeo[i].total > 0){
                    total_geo = sessionsByGeo[i].total;
                }else{
                    total_geo = 0;
                }
            this.totalSessionsByGeo.push({name: sessionsByGeo[i].region_continent, total: total_geo, color: this.colors[i]});
            }

            this.getAllNetworkLogsActivity(this.user.id, this.networkId, this.$route.params.id, 0);
        },
    }
}
</script>