<template src="./template.html"></template>

<script>
import Vue from 'vue';

import SessionChart from '../../components/SessionChart'
import SessionTypeChart from '../../components/SessionTypeChart'
import SessionGeoChart from '../../components/SessionGeoChart'
import SessionIxChart from '../../components/SessionIxChart'

import DashboardTopNav from '../../components/DashboardTopNavComponent/DashboardTopNavComponent';
import SideBarNavigation from '../../components/SidebarNavigationComponent/SidebarNavigationComponent';
import Footer from '../../components/FooterDashboardComponent/FooterDashboardComponent';
// import services
import SessionStats from "../../services/user/SessionStatsService";

export default {
    name: "Stats",
    components:{
        DashboardTopNav,
        SideBarNavigation,
        SessionChart,
        SessionTypeChart,
        SessionGeoChart,
        SessionIxChart,
        Footer
    },
    data(){
        return{
          page: null,
          user: {},
          networkId: null,
          network_id: null,
          chartOptions:{
            responsive : true,
            maintainAspectRatio: false,
            scales: {
              yAxes: [{
                    display: true,
                    ticks: {
                      beginAtZero: true
                    }
                }]
              },
          },
         totalSessions: [],
         totalSessionsByType: [],
         totalSessionsByGeo: [],
         totalSessionsByIx: [],
         ixesGeoLoc: [],
         selectedCont: "North America"
        }
    },
  created(){
    this.page = this.$route.name.toLowerCase();
    // get user first net_id
        if(this.$store.getters.user.id){
            if(localStorage.getItem('activeNet')){
                this.networkId = localStorage.getItem('activeNet');
            }else{
              if(this.$store.getters.user.networks){
                this.networkId  = this.$store.getters.user.networks[0].peeringdb_network_id; //Production
              }
            }
            this.user  = {
                id: this.$store.getters.user.id,
            };
        }
        // set Internet Exchange
        this.network_id = this.networkId;
  },
  async mounted(){

    if(this.networkId){
      const { data } = await this.getSessions(this.user.id, this.networkId);
      for (const [key] of Object.entries(data)) {
        if(this.networkId == data[key].from_network){
          const name = data[key].toNnetwork;
          this.totalSessions.push({name, total:data[key].quantity});
        }else if(this.networkId == data[key].to_network){
          const name = data[key].fromNetwork;
          this.totalSessions.push({name, total:data[key].quantity});
        }

      }
      // session by type
      const dataSession = await this.getSessionsByType(this.user.id, this.networkId);
      for(let i = 0; i < dataSession.data.length; i++){
        const name = dataSession.data[i].info_type;
        this.totalSessionsByType.push({name, total:dataSession.data[i].total});
      }

      const geoSession = await this.getSessionsByGeoLoc(this.user.id, this.networkId);
      for(let i = 0; i < geoSession.data.length; i++){
        const name = geoSession.data[i].region_continent;
        this.totalSessionsByGeo.push({name, total:geoSession.data[i].total});
      }

      const ixesSessions = await this.getSessionsStatsByIxes(this.user.id, this.networkId, this.selectedCont);
      for(let i = 0; i < ixesSessions.data.length; i++){
        const name = ixesSessions.data[i].name;
        this.totalSessionsByIx.push({name, total:ixesSessions.data[i].total});
      }

      await this.getGeoLoc(this.user.id, this.networkId);
    }

  },
  methods:{
    async changeContinent(){
      this.totalSessionsByIx = [];
      const ixesSessions = await this.getSessionsStatsByIxes(this.user.id, this.networkId, this.selectedCont);
        for(let i = 0; i < ixesSessions.data.length; i++){
          const name = ixesSessions.data[i].name;
          this.totalSessionsByIx.push({name, total:ixesSessions.data[i].total});
        }
    },
    getGeoLoc(user_id, net_id){
      SessionStats.getIxesGeoLocations(user_id, net_id).then(response => {
        this.ixesGeoLoc = response.data;
      }).catch(error => {
        if(error.response.data.error){
            Vue.$toast.error(error.response.data.error, {
                position: 'top-right'
            });
        }else{
            Vue.$toast.error('Oooops! Something went wrong!', {
                position: 'top-right'
            });
        }
      });
    },
    // onChange user net
    async networkChanged(network_id){
        this.network_id = network_id;
        this.networkId = network_id;
        this.totalSessions = [];
        this.totalSessionsByType = [];
        this.totalSessionsByGeo = [];
        this.totalSessionsByIx = [];

        const { data } = await this.getSessions(this.user.id, this.networkId);
        for (const [key] of Object.entries(data)) {
          if(this.networkId == data[key].from_network){
            const name = data[key].toNnetwork;
            this.totalSessions.push({name, total:data[key].quantity});
          }else if(this.networkId == data[key].to_network){
            const name = data[key].fromNetwork;
            this.totalSessions.push({name, total:data[key].quantity});
          }

        }

        // session by type
        const dataSession = await this.getSessionsByType(this.user.id, this.networkId);
        for(let i = 0; i < dataSession.data.length; i++){
          const name = dataSession.data[i].info_type;
          this.totalSessionsByType.push({name, total:dataSession.data[i].total});
        }

        const geoSession = await this.getSessionsByGeoLoc(this.user.id, this.networkId);
        for(let i = 0; i < geoSession.data.length; i++){
          const name = geoSession.data[i].region_continent;
          this.totalSessionsByGeo.push({name, total:geoSession.data[i].total});
        }

        const ixesSessions = await this.getSessionsStatsByIxes(this.user.id, this.networkId);
        for(let i = 0; i < ixesSessions.data.length; i++){
          const name = ixesSessions.data[i].name;
          this.totalSessionsByIx.push({name, total:ixesSessions.data[i].total});
        }
    },
    async getSessionsByType(user, net_id){
      return new Promise( (resolve, reject) => {
         SessionStats.getSessionsStatsByType(user, net_id).then(response => {
            resolve(response);
        }).catch(error => {
          if(error.response.data.error){
            Vue.$toast.error(error.response.data.error, {
                position: 'top-right'
            });
          }else{
            Vue.$toast.error('Oooops! Something went wrong!', {
                position: 'top-right'
            });
          }
          reject(error);
        });
      });
    },
    async getSessions(user, net_id){
      return new Promise( (resolve, reject) => {
         SessionStats.getSessionsStats(user, net_id).then(response => {
            resolve(response);
        }).catch(error => {
          if(error.response.data.error){
            Vue.$toast.error(error.response.data.error, {
                position: 'top-right'
            });
          }else{
            Vue.$toast.error('Oooops! Something went wrong!', {
                position: 'top-right'
            });
          }
          if(error.response.status === 401){
              this.$store.dispatch('logout');
              this.$router.push('/')
          }
          reject(error);
        });
      });
    },
    async getSessionsByGeoLoc(user, net_id){
      return new Promise( (resolve, reject) => {
         SessionStats.getSessionsStatsByGeo(user, net_id).then(response => {
            resolve(response);
        }).catch(error => {
          if(error.response.data.error){
            Vue.$toast.error(error.response.data.error, {
                position: 'top-right'
            });
          }else{
            Vue.$toast.error('Oooops! Something went wrong!', {
                position: 'top-right'
            });
          }
          reject(error);
        });
      });
    },
    async getSessionsStatsByIxes(user, net_id, continent){
      return new Promise( (resolve, reject) => {
         SessionStats.getSessionsStatsByIx(user, net_id, continent).then(response => {
            resolve(response);
        }).catch(error => {
          if(error.response.data.error){
            Vue.$toast.error(error.response.data.error, {
                position: 'top-right'
            });
          }else{
            Vue.$toast.error('Oooops! Something went wrong!', {
                position: 'top-right'
            });
          }
          reject(error);
        });
      });
    }
    
  }
}
</script>