import { render, staticRenderFns } from "./template.html?vue&type=template&id=1d722350&scoped=true&"
import script from "./SandboxSettingsComponent.vue?vue&type=script&lang=js&"
export * from "./SandboxSettingsComponent.vue?vue&type=script&lang=js&"
import style0 from "./style.css?vue&type=style&index=0&id=1d722350&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1d722350",
  null
  
)

export default component.exports