<template src="./template.html"></template>
<style src="./style.css"></style>

<script>
// import components
import DashboardTopNav from "../../components/DashboardTopNavComponent/DashboardTopNavComponent";
import SideBarNavigation from "../../components/SidebarNavigationComponent/SidebarNavigationComponent";
import Footer from '../../components/FooterDashboardComponent/FooterDashboardComponent';
import PeerIgnore from '../../components/SettingsComponents/PeerIgnoreComponent/PeerIgnoreComponent';
import PeerHide from '../../components/SettingsComponents/PeerHideComponent/PeerHideComponent';
import PeerBlacklist from '../../components/SettingsComponents/PeerBlacklistComponent/PeerBlacklistComponent';
import ZeroTouchPeering from '../../components/SettingsComponents/ZeroTouchPeeringComponent/ZeroTouchPeeringComponent';
import SandboxWorkflow from '../../components/SettingsComponents/SandboxSettingsComponent/SandboxSettingsComponent';
import NetworkLogs from '../../components/SettingsComponents/NetworkLogsComponent/NetworkLogsComponent';

// import PeerSettingsService from '../../services/user/PeerSettingsService';

export default {
    name: 'Settings',
    components:{
        DashboardTopNav,
        SideBarNavigation,
        Footer,
        PeerIgnore,
        PeerHide,
        PeerBlacklist,
        ZeroTouchPeering,
        SandboxWorkflow,
        NetworkLogs
    },
    directives: {
        "click-outside": {
            bind: function(el, binding) {
                // Define ourClickEventHandler
                const ourClickEventHandler = event => {
                    if (!el.contains(event.target) && el !== event.target && event.target.type != 'text') {
                        // as we are attaching an click event listern to the document (below)
                        // ensure the events target is outside the element or a child of it
                        binding.value(event); // before binding it
                    }
                };
                // attached the handler to the element so we can remove it later easily
                el.__vueClickEventHandler__ = ourClickEventHandler;

                // attaching ourClickEventHandler to a listener on the document here
                document.addEventListener("click", ourClickEventHandler);
            },
            unbind: function(el) {
                // Remove Event Listener
                document.removeEventListener("click", el.__vueClickEventHandler__);
            }
        }
    },
    data(){
        return{
            page: null,
            user: {},
            network_id: null,
            networkId: null,
            ignore_content: false,
            blacklist_content: false,
            hide_peer_content: false,
            networks: [],
            zero_touch_peering_content: false,
            sandbox_content:false,
            network_logs_content: false
        }
    },
    created(){
        this.page = this.$route.name.toLowerCase();
        // get user first net_id
        if(this.$store.getters.user.id){
            if(localStorage.getItem('activeNet')){
                this.networkId = localStorage.getItem('activeNet');
            }else{
                if(this.$store.getters.user.networks){
                    this.networkId  = this.$store.getters.user.networks[0].peeringdb_network_id;
                }
            }
            this.user  = {
                id: this.$store.getters.user.id,
                name: this.$store.getters.user.name,
            };
        }

        this.network_id = this.networkId;
        
    },
    methods:{
        openIgnoreList(){
            this.blacklist_content = false;
            this.hide_peer_content = false;
            this.zero_touch_peering_content = false;
            this.sandbox_content = false;
            this.network_logs_content = false;
            if(this.ignore_content){
                this.ignore_content = false;
            }else{
                this.ignore_content = true;
            }
        },
        openHideList(){
            this.blacklist_content = false;
            this.ignore_content = false;
            this.zero_touch_peering_content = false;
            this.sandbox_content = false;
            this.network_logs_content = false;
            if(this.hide_peer_content){
                this.hide_peer_content = false;
            }else{
                this.hide_peer_content = true;
            }
        },
        openBlacklist(){
            this.hide_peer_content = false;
            this.ignore_content = false;
            this.zero_touch_peering_content = false;
            this.sandbox_content = false;
            this.network_logs_content = false;
            if(this.blacklist_content){
                this.blacklist_content = false;
            }else{
                this.blacklist_content = true;
            }
        },
         openZeroTouchPeering(){
            this.hide_peer_content = false;
            this.ignore_content = false;
            this.blacklist_content = false;
            this.sandbox_content = false;
            this.network_logs_content = false;
            if(this.zero_touch_peering_content){
                this.zero_touch_peering_content = false;
            }else{
                this.zero_touch_peering_content = true;
            }
        },
        openSandbox(){
            this.hide_peer_content = false;
            this.ignore_content = false;
            this.blacklist_content = false;
            this.zero_touch_peering_content = false;
            this.network_logs_content = false;
            if(this.sandbox_content){
                this.sandbox_content = false;
            }else{
                this.sandbox_content = true;
            }
        },
        openNetLogs(){
            this.hide_peer_content = false;
            this.ignore_content = false;
            this.blacklist_content = false;
            this.zero_touch_peering_content = false;
             this.sandbox_content = false;
            if(this.network_logs_content){
                this.network_logs_content = false;
            }else{
                this.network_logs_content = true;
            }
        },
        // onChange user net
        async networkChanged(network_id){
            this.network_id = network_id;
            this.networkId = network_id;

            this.zero_touch_peering_content = false;
            this.hide_peer_content = false;
            this.ignore_content = false;
            this.blacklist_content = false;
        }
    }
}
</script>