import Vue from 'vue'
import Vuex from 'vuex'
Vue.config.devtools = true //Remove it before deploying to production


Vue.use(Vuex);

import authStore from './modules/authStore'

export default new Vuex.Store({
   modules: {
      authStore
   }
});

