import {apiClient} from '../utils/ApiClient';
import {isObjectEmpty} from '../utils/helpers/Helpers';

export default {
    get(resource, id = null, query_params = null) {
        if (id) {
            resource += '/' + id;
        }
        if (query_params) {
            resource += '?';
            for (let key in query_params) {
                resource += key + '=' + query_params[key];
                delete query_params[key];
                if (!isObjectEmpty(query_params)) {
                    resource += '&';
                }
            }

        }
        return apiClient.get(resource);
    },
    post(resource, data) {
        return apiClient.post(resource, data);
    },
    delete(resoruce, id) {
        var url = resoruce + '/' + id;
        return apiClient.delete(url);
    }
}
