<template src="./template.html"></template>

<script>
import Vue from 'vue';

// import services
import NetService from '../../services/NetService';

export default {
    name: "InternetExchange",
    props: ['network_id', 'user'],
    directives: {
        "click-outside": {
            bind: function(el, binding) {
                // Define ourClickEventHandler
                const ourClickEventHandler = event => {
                    if (!el.contains(event.target) && el !== event.target && event.target.type != 'text') {
                        // as we are attaching an click event listern to the document (below)
                        // ensure the events target is outside the element or a child of it
                        binding.value(event); // before binding it
                    }
                };
                // attached the handler to the element so we can remove it later easily
                el.__vueClickEventHandler__ = ourClickEventHandler;

                // attaching ourClickEventHandler to a listener on the document here
                document.addEventListener("click", ourClickEventHandler);
            },
            unbind: function(el) {
                // Remove Event Listener
                document.removeEventListener("click", el.__vueClickEventHandler__);
            }
        }
    },
    data(){
        return{
            ixes: [],
            networkId: null,
            queryIx: '',
            showDropdown: false,
        }
    },
    watch: {
        'network_id': async function (value)  {
            this.ixes = [];

            if(value){
                this.networkId = value;
                const ix = await this.getInternetExchange(this.networkId);
                this.ixes = ix;

                this.ixes.sort(function(a, b) {
                    return a.name.localeCompare(b.name);
                });
            }
            
            this.queryIx = '';

        }
    },
    async created(){
  
        this.networkId  = this.$props.network_id; 
        
        // set Internet Exchange
        if(this.networkId){
            const ix = await this.getInternetExchange(this.networkId);
            this.ixes = ix;
            this.ixes.sort(function(a, b) {
                return a.name.localeCompare(b.name);
            });
        }
        
    },
    methods:{
        openDropdown(){
            this.showDropdown = true;
        },
        selectIx(ix){
            this.ix = ix;
            this.queryIx = ix.name;
            this.changeIx(ix);
        },
        hideDropdown(){
            this.showDropdown = false;
        },
         // onChange Internet Exchange
        changeIx(){
            this.$emit('internet-exchange', this.queryIx.id);
            this.$emit('internet-exchange-ixes', this.queryIx);
        },
        // Get internet Exchanges for given user net_id
        getInternetExchange(user_net_id){
            return new Promise((resolve, reject)=>{
                NetService.getAllIx(this.user.id, user_net_id).then(response => {
                    resolve(response.data);
                }).catch(error => {
                    if(error.response.status === 401){
                        this.$store.dispatch('logout');
                        this.$router.push('/')
                    }

                    if(error.response.data.error){
                        Vue.$toast.error(error.response.data.error, {
                            position: 'top-right'
                        });
                    }else{
                        Vue.$toast.error('Oooops! Something went wrong!', {
                            position: 'top-right'
                        });
                    }
                    reject(error);
                });     
            });      
        },
    }
}
</script>

<style>
input:focus{
    outline: none;
}
</style>