import ApiService from "../ApiService";

export default {

    getPrivatePeerings(user_id, net_id) {
        return ApiService.get(`user/${user_id}/net/${net_id}/privatePeering/list`);
    },
    createPrivatePeerings(user_id, net_id, formData) {
        return ApiService.post(`user/${user_id}/net/${net_id}/privatePeering/add`, formData);
    },
    editPrivatePeerings(user_id, net_id, formData) {
        return ApiService.post(`user/${user_id}/net/${net_id}/privatePeering/edit`, formData);
    },
    getPrivatePolicies(user_id, net_id, device_id){
        return ApiService.get(`user/${user_id}/net/${net_id}/privatePeering/getPrivateDevicePolicies`, device_id);
    },
    getPrivatePeeringConfig(user_id, net_id, device_id){
        return ApiService.get(`user/${user_id}/net/${net_id}/privatePeering/config`, device_id);
    },
    deletePrivatePeering(user_id, net_id, data){
        return ApiService.post(`user/${user_id}/net/${net_id}/privatePeering/delete`, data);
    }
}   