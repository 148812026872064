import ApiService from "../ApiService";

export default {

    getSessions(user_id, net_id, currentPage) {
        return ApiService.get(`user/${user_id}/net/${net_id}/sessions?page=${currentPage}`);
    },
    getPublicSessions(user_id, net_id, peer, currentPage) {
        return ApiService.get(`user/${user_id}/net/${net_id}/publicSessions/${peer}?page=${currentPage}`);
    },
    getPrivateSessions(user_id, net_id, peer, currentPage) {
        return ApiService.get(`user/${user_id}/net/${net_id}/privateSessions/${peer}?page=${currentPage}`);
    },
    serachSessions(user_id, net_id, selectedDisplay, query) {
        return ApiService.get(`user/${user_id}/net/${net_id}/sessions/ix/${selectedDisplay}/search`,query);
    },
    getSessionsIxes(user_id, net_id) {
        return ApiService.get(`user/${user_id}/net/${net_id}/sessions/getSessionsIxes`);
    },
    getPublicSessionsIxes(user_id, net_id, peer) {
        return ApiService.get(`user/${user_id}/net/${net_id}/sessions/getPublicSessionsIxes/${peer}`);
    },
    dePeerSession(user_id, net_id, data){
        return ApiService.post(`user/${user_id}/net/${net_id}/dePeerSession`, data);
    },
    dePeerSelectedSessions(user_id, net_id, data){
        return ApiService.post(`user/${user_id}/net/${net_id}/dePeerSelectedSessions`, data);
    },
    getAllSessions(user_id, net_id){
        return ApiService.get(`user/${user_id}/net/${net_id}/sessionsAll`);
    },
    approveSessionRequest(user_id, net_id, data) {
        return ApiService.post(`user/${user_id}/net/${net_id}/approveSessionRequest`, data);
    },
    getRouterRequestSessions(user_id, net_id, router_id){
        return ApiService.get(`user/${user_id}/net/${net_id}/requests/${router_id}`);
    },
    allPolicyToProduction(user_id, net_id, data){
        return ApiService.post(`user/${user_id}/net/${net_id}/sessions/live/all`, data);
    },
    allPolicyTosandbox(user_id, net_id, data){
        return ApiService.post(`user/${user_id}/net/${net_id}/sessions/sandbox/all`, data);
    },
    getAllPeerSessions(user_id, net_id, peer){
        return ApiService.get(`user/${user_id}/net/${net_id}/getPeerSessions/${peer}`);
    },
    getAllSessionsForIx(user_id, net_id, ix){
        return ApiService.get(`user/${user_id}/net/${net_id}/getAllSessionsForIx/${ix}`);
    },
    updateNote(user_id, net_id, data){
        return ApiService.post(`user/${user_id}/net/${net_id}/sessionNote/update`, data);
    }
}