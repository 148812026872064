<template src="./template.html"></template>
<style scoped src="./style.css"></style>

<script>
import Vue from 'vue';
// import components
import DashboardTopNav from "../../components/DashboardTopNavComponent/DashboardTopNavComponent";
import SideBarNavigation from "../../components/SidebarNavigationComponent/SidebarNavigationComponent";
import Footer from '../../components/FooterDashboardComponent/FooterDashboardComponent';

// import services
import RouterServce from '../../services/user/RouterService';
import NetService from '../../services/NetService';
import PrivatePeeringService from '../../services/user/PrivatePeering';
import PolicyService from '../../services/user/PolicyService';
import PrivateDeviceService from '../../services/user/PrivateDeviceService';

export default {
    name: "PrivatePeering",
    components:{
        DashboardTopNav,
        SideBarNavigation,
        Footer
    },
    directives: {
        "click-outside": {
            bind: function(el, binding) {
                // Define ourClickEventHandler
                const ourClickEventHandler = event => {
                    if (!el.contains(event.target) && el !== event.target && event.target.type != 'text' && event.target.classList.contains('search') != true) {
                        // as we are attaching an click event listern to the document (below)
                        // ensure the events target is outside the element or a child of it
                        binding.value(event); // before binding it
                    }
                };
                // attached the handler to the element so we can remove it later easily
                el.__vueClickEventHandler__ = ourClickEventHandler;

                // attaching ourClickEventHandler to a listener on the document here
                document.addEventListener("click", ourClickEventHandler);
            },
            unbind: function(el) {
                // Remove Event Listener
                document.removeEventListener("click", el.__vueClickEventHandler__);
            }
        }
    },
    watch: {
        'queryNet': function() {
            this.showDropdown = true;
            return this.filteredIxes = this.networks.filter(item => {
                return item.name.toLowerCase().indexOf(this.queryNet.toLowerCase()) > -1
            });
        },
        ipv4: function(newVal) { // watch it
            if (this.timer) {
                clearTimeout(this.timer);
                this.timer = null;
            }
            this.timer = setTimeout(() => {
                this.validateIpv4(newVal, 'ipv4')
            }, 1000);
        },
        peer_ipv4: function(newVal) { // watch it
            if (this.timer) {
                clearTimeout(this.timer);
                this.timer = null;
            }
            this.timer = setTimeout(() => {
                this.validateIpv4(newVal, 'peer_ipv4')
            }, 1000);
        },

        ipv6: function(newVal) { // watch it
            if (this.timer) {
                clearTimeout(this.timer);
                this.timer = null;
            }
            this.timer = setTimeout(() => {
                this.validateIpv6(newVal, 'ipv6')
            }, 1000);
        },
        peer_ipv6: function(newVal) { // watch it
            if (this.timer) {
                clearTimeout(this.timer);
                this.timer = null;
            }
            this.timer = setTimeout(() => {
                this.validateIpv6(newVal, 'peer_ipv6')
            }, 1000);
        },
        // Edit
        edit_ipv4: function(newVal) { // watch it
            if (this.timer) {
                clearTimeout(this.timer);
                this.timer = null;
            }
            this.timer = setTimeout(() => {
                this.validateIpv4(newVal, 'edit_ipv4')
            }, 1000);
        },
        edit_peer_ipv4: function(newVal) { // watch it
            if (this.timer) {
                clearTimeout(this.timer);
                this.timer = null;
            }
            this.timer = setTimeout(() => {
                this.validateIpv4(newVal, 'edit_peer_ipv4')
            }, 1000);
        },

        edit_ipv6: function(newVal) { // watch it
            if (this.timer) {
                clearTimeout(this.timer);
                this.timer = null;
            }
            this.timer = setTimeout(() => {
                this.validateIpv6(newVal, 'edit_ipv6')
            }, 1000);
        },
        edit_peer_ipv6: function(newVal) { // watch it
            if (this.timer) {
                clearTimeout(this.timer);
                this.timer = null;
            }
            this.timer = setTimeout(() => {
                this.validateIpv6(newVal, 'edit_peer_ipv6')
            }, 1000);
        },
    },
    data(){
        return{
            page: null,
            user:{},
            networkId: null,
            network_id:null,
            ixes: [],
            searchQuery: null,
            deviceModal: false,
            ipv4: null,
            ipv6: null,
            device_name: null,
            router_types:[],
            showDropdown: false,
            queryNet: '',
            showDropdownNet: false, 
            filteredNet: [],
            networks: [],
            error_search_net: null,
            no_net_res: false,
            to_network: null,
            devices: [],
            policies_v4_import: [],
            policies_v4_export: [],
            policies_v6_import: [],
            policies_v6_export: [],
            selected_policy_v4_import: null,
            selected_policy_v4_export: null,
            selected_policy_v6_import: null,
            selected_policy_v6_export: null,
            selected_router: null,
            selected_peer_router: null,
            device_type: null,
            peer_ipv4: null,
            peer_ipv6: null,
            peer_asn: null,
            speed: null,
            routers: [],
            peer_routers: [],
            private_peering_policies: [],
            policies_modal:false,
            editDeviceModal: false,
            edit_policy_v4_import: null,
            edit_policy_v4_export: null,
            edit_policy_v6_import: null,
            edit_policy_v6_export: null,
            edit_ipv4: null,
            edit_ipv6: null,
            edit_device_name: null,
            edit_selected_policy_v4_import: null,
            edit_selected_policy_v4_export: null,
            edit_selected_policy_v6_import: null,
            edit_selected_policy_v6_export: null,
            edit_selected_router: '',
            edit_device_type: null,
            edit_peer_ipv4: null,
            edit_peer_ipv6: null,
            edit_peer_asn: null,
            edit_speed: null,
            edit_queryNet: null,
            edit_to_network: null,
            config_modal: false,
            configIxes: null,
            configs: [],
            background_of_modal: false,
            not_allowed_modal:false,
            showDropdownSpeed: false,
            local_asn: null,
            edit_local_asn: null,
            prefix4: null,
            prefix6: null,
            edit_prefix4: null,
            edit_prefix6: null,
            opened: [],
            delete_pp_modal: false,
            selectedPPDelete: null,
        }
    },
    async created(){

        this.page = this.$route.name.toLowerCase();
        // get user first net_id
        if(this.$store.getters.user.id){
            if(localStorage.getItem('activeNet')){
                this.networkId = localStorage.getItem('activeNet');
            }else{
                if(this.$store.getters.user.networks){
                    this.networkId  = this.$store.getters.user.networks[0].peeringdb_network_id;
                }
            }
            this.user  = {
                id: this.$store.getters.user.id,
            };
        }
        // set Internet Exchange
        this.network_id = this.networkId; 
        if(this.network_id){
            this.local_asn = this.getNetworkAsn(this.networkId);
            await this.getAllPrivateRouters(this.user.id, this.networkId);
            this.getPDevices();
            this.getNetworkPrivatePolicies();
            this.routerTypes();
        }
    },
    methods:{
        openDeletePPModal(private_peering_id){
            this.delete_pp_modal = true;
            this.selectedPPDelete = private_peering_id;
        },
        hideDeletePPModal(){
            this.delete_pp_modal = false;
            this.selectedPPDelete = null;
        },
        deletePrivatePeer(){

            if(this.selectedPPDelete){
                const formData = new FormData();
                formData.append('private_peering_id', this.selectedPPDelete);
                PrivatePeeringService.deletePrivatePeering(this.user.id, this.networkId, formData).then(response =>{
                    if(response.data){
                        this.devices = this.devices.filter(device => device.id != this.selectedPPDelete);
                        this.selectedPPDelete = null;
                        this.delete_pp_modal = false;
                    }
                }).catch(error => {
                    if(error.response.data.error){
                         Vue.$toast.error(error.response.data.error, {
                            position: 'top-right'
                        });
                    }else{
                         Vue.$toast.error('Oooops! Something went wrong!', {
                            position: 'top-right'
                        });
                    }
                });
            }
        }, 
        async getNetworkPrivatePolicies(){
            var policies_data = await this.getAllPolicies(this.networkId);
            policies_data.data.forEach(policy => {
                if(policy.private == 1){
                    if(policy.import_policy == 1){
                        if(policy.global_v4_policy == 1){
                            this.policies_v4_import.push(policy);
                            this.selected_policy_v4_import = policy.id;
                            if(policy.global_v6_policy == 1){
                                this.policies_v6_import.push(policy);
                                this.selected_policy_v6_import = policy.id;
                            }
                        }else if(policy.global_v6_policy == 1){
                            this.policies_v6_import.push(policy);
                            this.selected_policy_v6_import = policy.id;
                        }else{
                            this.policies_v4_import.push(policy);
                            this.policies_v6_import.push(policy);
                        }
                    }else if(policy.export_policy == 1){
                        if(policy.global_v4_policy == 1){
                            this.policies_v4_export.push(policy);
                            this.selected_policy_v4_export = policy.id;
                            if(policy.global_v6_policy == 1){
                                this.policies_v6_export.push(policy);
                                this.selected_policy_v6_export = policy.id;
                            }
                        }else if(policy.global_v6_policy == 1){
                            this.policies_v6_export.push(policy);
                            this.selected_policy_v6_export = policy.id;
                        }else{
                            this.policies_v4_export.push(policy);
                            this.policies_v6_export.push(policy);
                        }
                    }
                }
            }); 
        },
        getNetworkAsn(net_id){
            const current_network = this.$store.getters.user.networks.filter(network => {
                if(network.peeringdb_network_id == net_id){
                    return network;
                }
            });

            return current_network[0].asn;
        },
        validateIP6address(ipaddress) 
        {
            if (/((^\s*((([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5]))\s*$)|(^\s*((([0-9A-Fa-f]{1,4}:){7}([0-9A-Fa-f]{1,4}|:))|(([0-9A-Fa-f]{1,4}:){6}(:[0-9A-Fa-f]{1,4}|((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){5}(((:[0-9A-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){4}(((:[0-9A-Fa-f]{1,4}){1,3})|((:[0-9A-Fa-f]{1,4})?:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){3}(((:[0-9A-Fa-f]{1,4}){1,4})|((:[0-9A-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){2}(((:[0-9A-Fa-f]{1,4}){1,5})|((:[0-9A-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){1}(((:[0-9A-Fa-f]{1,4}){1,6})|((:[0-9A-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(:(((:[0-9A-Fa-f]{1,4}){1,7})|((:[0-9A-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:)))(%.+)?\s*$))/g.test(ipaddress))
            {
                return (true)
            }
            return (false)
        },
        validateIP4address(ipaddress) 
        {
            if (/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(ipaddress))
            {
                return (true)
            }
            return (false)
        },
        validateIpv4(ipv4, item){
            if(item == 'ipv4'){
                var el = document.getElementById('ipv4_error').parentNode;
                var parent = document.getElementById('ipv4_error').parentNode.parentNode;
                var input = parent.getElementsByTagName('input')[0];
                if(this.validateIP4address(ipv4)){
                    document.getElementById('addDevice').disabled = false;
                    document.getElementById('addDevice').classList.remove('bg-gray-700');
                    document.getElementById('addDevice').classList.add('bg-green-500');
                    document.getElementById('addDevice').classList.add('hover:bg-green-400');
                    input.classList.add('border-gray-200');
                    input.classList.remove('border-red-600');
                    el.classList.add('hidden');
                }else{
                    document.getElementById('addDevice').disabled = true;
                    document.getElementById('addDevice').classList.remove('bg-green-500');
                    document.getElementById('addDevice').classList.remove('hover:bg-green-400');
                    document.getElementById('addDevice').classList.add('bg-gray-700');
                    input.classList.remove('border-gray-200');
                    input.classList.add('border-red-600');
                    el.classList.remove('hidden');
                }
            }else if(item == 'peer_ipv4'){
                var peer_el = document.getElementById('peer_ipv4_error').parentNode;
                var peer_parent = document.getElementById('peer_ipv4_error').parentNode.parentNode;
                var peer_input = peer_parent.getElementsByTagName('input')[0];
                if(this.validateIP4address(ipv4)){
                        peer_input.classList.add('border-gray-200');
                        peer_input.classList.remove('border-red-600');
                        peer_el.classList.add('hidden');
                        document.getElementById('addDevice').disabled = false;
                        document.getElementById('addDevice').classList.remove('bg-gray-700');
                        document.getElementById('addDevice').classList.add('bg-green-500');
                        document.getElementById('addDevice').classList.add('hover:bg-green-400');
                }else{
                    document.getElementById('addDevice').disabled = true;
                    document.getElementById('addDevice').classList.remove('bg-green-500');
                    document.getElementById('addDevice').classList.remove('hover:bg-green-400');
                    document.getElementById('addDevice').classList.add('bg-gray-700');
                    peer_input.classList.remove('border-gray-200');
                    peer_input.classList.add('border-red-600');
                    peer_el.classList.remove('hidden');
                }
            }
            
            if(item == 'edit_ipv4'){
                var edit_el = document.getElementById('edit_ipv4_error').parentNode;
                var edit_parent = document.getElementById('edit_ipv4_error').parentNode.parentNode;
                var edit_input = edit_parent.getElementsByTagName('input')[0];
                if(this.validateIP4address(ipv4)){
                    document.getElementById('editDevice').disabled = false;
                    document.getElementById('editDevice').classList.remove('bg-gray-700');
                    document.getElementById('editDevice').classList.add('bg-green-500');
                    document.getElementById('editDevice').classList.add('hover:bg-green-400');
                    edit_input.classList.add('border-gray-200');
                    edit_input.classList.remove('border-red-600');
                    edit_el.classList.add('hidden');
                }else{
                    document.getElementById('editDevice').disabled = true;
                    document.getElementById('editDevice').classList.remove('bg-green-500');
                    document.getElementById('editDevice').classList.remove('hover:bg-green-400');
                    document.getElementById('editDevice').classList.add('bg-gray-700');
                    edit_input.classList.remove('border-gray-200');
                    edit_input.classList.add('border-red-600');
                    edit_el.classList.remove('hidden');
                }
            }else if(item == 'edit_peer_ipv4'){
                var edit_peer_el = document.getElementById('edit_peer_ipv4_error').parentNode;
                var edit_peer_parent = document.getElementById('edit_peer_ipv4_error').parentNode.parentNode;
                var edit_peer_input = edit_peer_parent.getElementsByTagName('input')[0];
                if(this.validateIP4address(ipv4)){
                        edit_peer_input.classList.add('border-gray-200');
                        edit_peer_input.classList.remove('border-red-600');
                        edit_peer_el.classList.add('hidden');
                        document.getElementById('editDevice').disabled = false;
                        document.getElementById('editDevice').classList.remove('bg-gray-700');
                        document.getElementById('editDevice').classList.add('bg-green-500');
                        document.getElementById('editDevice').classList.add('hover:bg-green-400');
                }else{
                    document.getElementById('editDevice').disabled = true;
                    document.getElementById('editDevice').classList.remove('bg-green-500');
                    document.getElementById('editDevice').classList.remove('hover:bg-green-400');
                    document.getElementById('editDevice').classList.add('bg-gray-700');
                    edit_peer_input.classList.remove('border-gray-200');
                    edit_peer_input.classList.add('border-red-600');
                    edit_peer_el.classList.remove('hidden');
                }
            }
            

        },
        validateIpv6(ipv6, item){
            if(item == 'ipv6'){
                var el = document.getElementById('ipv6_error').parentNode;
                var parent = document.getElementById('ipv6_error').parentNode.parentNode;
                var input = parent.getElementsByTagName('input')[0];
                if(this.validateIP6address(ipv6)){
                        input.classList.add('border-gray-200');
                        input.classList.remove('border-red-600');
                        el.classList.add('hidden');
                        document.getElementById('addDevice').disabled = false;
                        document.getElementById('addDevice').classList.remove('bg-gray-700');
                        document.getElementById('addDevice').classList.add('bg-green-500');
                        document.getElementById('addDevice').classList.add('hover:bg-green-400');
                }else{
                    document.getElementById('addDevice').disabled = true;
                    document.getElementById('addDevice').classList.remove('bg-green-500');
                    document.getElementById('addDevice').classList.remove('hover:bg-green-400');
                    document.getElementById('addDevice').classList.add('bg-gray-700');
                    input.classList.remove('border-gray-200');
                    input.classList.add('border-red-600');
                    el.classList.remove('hidden');
                }
            }else if(item == 'peer_ipv6'){
                var peer_el = document.getElementById('peer_ipv6_error').parentNode;
                var peer_parent = document.getElementById('peer_ipv6_error').parentNode.parentNode;
                var peer_input = peer_parent.getElementsByTagName('input')[0];
                if(this.validateIP6address(ipv6)){
                    peer_input.classList.add('border-gray-200');
                    peer_input.classList.remove('border-red-600');
                    peer_el.classList.add('hidden');
                    document.getElementById('addDevice').disabled = false;
                    document.getElementById('addDevice').classList.remove('bg-gray-700');
                    document.getElementById('addDevice').classList.add('bg-green-500');
                    document.getElementById('addDevice').classList.add('hover:bg-green-400');
                }else{
                    document.getElementById('addDevice').disabled = true;
                    document.getElementById('addDevice').classList.remove('bg-green-500');
                    document.getElementById('addDevice').classList.remove('hover:bg-green-400');
                    document.getElementById('addDevice').classList.add('bg-gray-700');
                    peer_input.classList.remove('border-gray-200');
                    peer_input.classList.add('border-red-600');
                    peer_el.classList.remove('hidden');
                }
            }

            if(item == 'edit_ipv6'){
                var edit_el = document.getElementById('edit_ipv6_error').parentNode;
                var edit_parent = document.getElementById('edit_ipv6_error').parentNode.parentNode;
                var edit_input = edit_parent.getElementsByTagName('input')[0];
                if(this.validateIP6address(ipv6)){
                        edit_input.classList.add('border-gray-200');
                        edit_input.classList.remove('border-red-600');
                        edit_el.classList.add('hidden');
                        document.getElementById('editDevice').disabled = false;
                        document.getElementById('editDevice').classList.remove('bg-gray-700');
                        document.getElementById('editDevice').classList.add('bg-green-500');
                        document.getElementById('editDevice').classList.add('hover:bg-green-400');
                }else{
                    document.getElementById('editDevice').disabled = true;
                    document.getElementById('editDevice').classList.remove('bg-green-500');
                    document.getElementById('editDevice').classList.remove('hover:bg-green-400');
                    document.getElementById('editDevice').classList.add('bg-gray-700');
                    edit_input.classList.remove('border-gray-200');
                    edit_input.classList.add('border-red-600');
                    edit_el.classList.remove('hidden');
                }
            }else if(item == 'edit_peer_ipv6'){
                var edit_peer_el = document.getElementById('edit_peer_ipv6_error').parentNode;
                var edit_peer_parent = document.getElementById('edit_peer_ipv6_error').parentNode.parentNode;
                var edit_peer_input = edit_peer_parent.getElementsByTagName('input')[0];
                if(this.validateIP6address(ipv6)){
                    document.getElementById('editDevice').disabled = false;
                    document.getElementById('editDevice').classList.remove('bg-gray-700');
                    document.getElementById('editDevice').classList.add('bg-green-500');
                    document.getElementById('editDevice').classList.add('hover:bg-green-400');
                    edit_peer_input.classList.add('border-gray-200');
                    edit_peer_input.classList.remove('border-red-600');
                    edit_peer_el.classList.add('hidden');
                }else{
                    document.getElementById('editDevice').disabled = true;
                    document.getElementById('editDevice').classList.remove('bg-green-500');
                    document.getElementById('editDevice').classList.remove('hover:bg-green-400');
                    document.getElementById('editDevice').classList.add('bg-gray-700');
                    edit_peer_input.classList.remove('border-gray-200');
                    edit_peer_input.classList.add('border-red-600');
                    edit_peer_el.classList.remove('hidden');
                }
            }

        },
        toggle(id, event) {
            const index = this.opened.indexOf(id);
            var el = event.target.parentNode;
            var open = el.getElementsByClassName('open')
            var closed = el.getElementsByClassName('closed');
            if(closed.length == 0 && open.length == 0){
                var new_el = event.target.parentNode.parentNode;
                var new_close = new_el.getElementsByClassName('closed');
                var new_open = new_el.getElementsByClassName('open');
                if(new_open.length > 0 && new_close.length > 0){
                    if(new_open[0].classList.contains('hidden')){
                        new_close[0].classList.add('hidden');
                        new_open[0].classList.remove('hidden');
                    }else{
                        new_close[0].classList.remove('hidden');
                        new_open[0].classList.add('hidden');
                    }
                }
            }else{
               if(open[0].classList.contains('hidden') == true){
                   closed[0].classList.add('hidden');
                   open[0].classList.remove('hidden');
               }else{
                   closed[0].classList.remove('hidden');
                   open[0].classList.add('hidden');
               }
            }

            if (index > -1) {
                this.opened.splice(index, 1)
            } else {
                this.opened.push(id)
            }
        },
        openDropdownSpeed(){
            this.showDropdownSpeed = true;
        },
        hideDropdownSpeed(){
            this.showDropdownSpeed = false;
        },
        selectSpeed(value){
            this.speed = value;
            this.hideDropdown();
        },
        selectEditSpeed(value){
            this.edit_speed = value;
            this.hideDropdown();
        },
        hideNotAllowedModal(){
            this.not_allowed_modal = false;
        },
        async openRouterConfigModal(device_id){
            this.config_modal = true;
            this.background_of_modal = true;

            PrivatePeeringService.getPrivatePeeringConfig(this.user.id, this.networkId, device_id).then(response => {
                this.configs = response.data;
                
            }).catch(error => {
                if(error.response.status === 401){
                    this.$store.dispatch('logout');
                    this.$router.push('/')
                }
                if( (error.response.status !== 500 && error.response.status !== 400) && error.response.data.error){
                    Vue.$toast.error(error.response.data.error, {
                        position: 'top-right'
                    });
                }else if(error.response.status !== 500 && error.response.status !== 400){
                    Vue.$toast.error(error.response.data, {
                        position: 'top-right'
                    });
                }else if(error.response.status === 500 || error.response.status == 400){
                    Vue.$toast.error('Oooops! Something went wrong!', {
                        position: 'top-right'
                    });
                }
            });
        },
        closeRouterConfigModal(){
            this.config_modal = false;
            this.background_of_modal = false;
        },
        editDevice(){
            var formData = new FormData;
            formData.append('ipv4', this.edit_ipv4);
            formData.append('ipv6', this.edit_ipv6);
            formData.append('speed', this.edit_speed);
            formData.append('private_device_id', this.edit_selected_router);
            formData.append('peer_network_id', this.edit_to_network);
            formData.append('peer_name', this.edit_queryNet);
            formData.append('peer_asn', this.edit_peer_asn);
            formData.append('peer_ipv4', this.edit_peer_ipv4);
            formData.append('peer_ipv6', this.edit_peer_ipv6);
            formData.append('prefix4', this.edit_prefix4);
            formData.append('prefix6', this.edit_prefix6);
            formData.append('policy_v4_import', this.edit_selected_policy_v4_import);
            formData.append('policy_v4_export', this.edit_selected_policy_v4_export);
            formData.append('policy_v6_import', this.edit_selected_policy_v6_import);
            formData.append('policy_v6_export', this.edit_selected_policy_v6_export);
            
            if(this.edit_ipv4 && this.edit_speed && this.edit_selected_router && this.edit_to_network && this.edit_queryNet 
                && this.edit_peer_asn && this.edit_peer_ipv4 && this.edit_selected_policy_v4_import 
                && this.edit_selected_policy_v4_export){

                PrivatePeeringService.editPrivatePeerings(this.user.id, this.networkId, formData).then(response => {
                    if(response.data){
                        Vue.$toast.success('Private Peering Updated!',{
                            position: 'top-right'
                        });
                    }
                    this.getPDevices();
                }).catch(error => {
                    if(error.response.status === 401){
                        this.$store.dispatch('logout');
                        this.$router.push('/')
                    }
                    if( (error.response.status !== 500 && error.response.status !== 400) && error.response.data.error){
                        Vue.$toast.error(error.response.data.error, {
                            position: 'top-right'
                        });
                    }else if(error.response.status !== 500 && error.response.status !== 400){
                        Vue.$toast.error(error.response.data, {
                            position: 'top-right'
                        });
                    }else if(error.response.status === 500 || error.response.status == 400){
                        Vue.$toast.error('Oooops! Something went wrong!', {
                            position: 'top-right'
                        });
                    }
                });
            }else{
                alert('All fields are required!');
            }
        },
        async editPrivatePeering(device_id){
            this.editDeviceModal = true;
            this.background_of_modal = true;
            var selectedDevice = this.devices.filter(device => device.id === device_id);
            this.edit_selected_router = selectedDevice[0].private_device_id;
            this.edit_device_name = selectedDevice[0].router_name;
            this.edit_queryNet = selectedDevice[0].peer_name;
            this.edit_to_network = selectedDevice[0].peer_network_id;
            var device_policies = await this.privatePolcies(device_id);
            device_policies.forEach(policy => {
                if(policy.import_policy == 1 && policy.pivot.v4){
                    this.edit_selected_policy_v4_import = policy.id;
                }else if(policy.export_policy == 1 && policy.pivot.v4){
                    this.edit_selected_policy_v4_export = policy.id;
                }else if(policy.import_policy == 1 && policy.pivot.v6){
                    this.edit_selected_policy_v6_import = policy.id;
                }else if(policy.export_policy == 1 && policy.pivot.v6){
                    this.edit_selected_policy_v6_export = policy.id;
                }
            });
            this.edit_ipv4 = selectedDevice[0].ipv4;
            this.edit_ipv6 = selectedDevice[0].ipv6;
            this.edit_device_type = selectedDevice[0].router_type_id;
            this.edit_peer_ipv4 = selectedDevice[0].peer_ipv4;
            this.edit_peer_ipv6 = selectedDevice[0].peer_ipv6;
            this.edit_peer_asn = selectedDevice[0].peer_asn;
            this.edit_speed = selectedDevice[0].speed;
            this.edit_prefix4 = selectedDevice[0].prefix4;
            this.edit_prefix6 = selectedDevice[0].prefix6;
            this.edit_local_asn = selectedDevice[0].asn;
        },
        closeEditDeviceModal(){
            this.editDeviceModal = false;
            this.background_of_modal = false;
        },
        closePoliciesModal(){
           this.policies_modal = false; 
           this.background_of_modal = false;
        },
        privatePolcies(device_id){
            return new Promise((resolve, reject) => {
                PrivatePeeringService.getPrivatePolicies(this.user.id, this.networkId, device_id).then(response => {
                    resolve(response.data);
                }).catch(error => {
                    if(error.response.status === 401){
                        this.$store.dispatch('logout');
                        this.$router.push('/')
                    }
                    if( (error.response.status !== 500 && error.response.status !== 400) && error.response.data.error){
                        Vue.$toast.error(error.response.data.error, {
                            position: 'top-right'
                        });
                    }else if(error.response.status !== 500 && error.response.status !== 400){
                        Vue.$toast.error(error.response.data, {
                            position: 'top-right'
                        });
                    }else if(error.response.status === 500 || error.response.status == 400){
                        Vue.$toast.error('Oooops! Something went wrong!', {
                            position: 'top-right'
                        });
                    }
                    reject(error);
                });
            });
            
        },
        async getPolicies(device_id){
            this.policies_modal = true;
            this.background_of_modal = true;
            this.private_peering_policies = await this.privatePolcies(device_id);
            this.private_peering_policies.forEach(policy => {
                if(policy.import_policy == 1 && policy.pivot.v4){
                    this.edit_policy_v4_import = policy.id;
                }else if(policy.export_policy == 1 && policy.pivot.v4){
                    this.edit_policy_v4_export = policy.id;
                }else if(policy.import_policy == 1 && policy.pivot.v6){
                    this.edit_policy_v6_import = policy.id;
                }else if(policy.export_policy == 1 && policy.pivot.v6){
                    this.edit_policy_v6_export = policy.id;
                }
            });
        },
        routerPeerChange(){
            this.peer_ipv4 = this.selected_peer_router.ipaddr4;
            this.peer_ipv6 = this.selected_peer_router.ipaddr6;
            this.peer_ipv4 = this.selected_peer_router.ipaddr4;
        },
        getAllPrivateRouters(user_id, net_id){
            PrivateDeviceService.getPrivateDevices(user_id, net_id).then(response =>{
                this.routers = response.data;
            }).catch(error => {
                Vue.$toast.error(error.response.data.error,{
                    position: 'top-right'
                });
            });
        },
        getAllPeerRouters(user_id, net_id){
            RouterServce.getRouters(user_id, net_id).then(response =>{
                this.peer_routers = response.data;
            }).catch(error => {
                if(error.response.status === 401){
                    this.$store.dispatch('logout');
                    this.$router.push('/')
                }
                if( (error.response.status !== 500 && error.response.status !== 400) && error.response.data.error){
                    Vue.$toast.error(error.response.data.error, {
                        position: 'top-right'
                    });
                }else if(error.response.status !== 500 && error.response.status !== 400){
                    Vue.$toast.error(error.response.data, {
                        position: 'top-right'
                    });
                }else if(error.response.status === 500 || error.response.status == 400){
                    Vue.$toast.error('Oooops! Something went wrong!', {
                        position: 'top-right'
                    });
                }
            });
        },
        async selectNet(network){
            this.to_network = network.id;
            this.peer_routers = await this.getAllPeerRouters(this.user.id, this.to_network);
            this.queryNet = network.name;
            this.peer_asn = network.asn;
        },
        getPDevices(){
            PrivatePeeringService.getPrivatePeerings(this.user.id, this.networkId).then(response => {
                this.devices = response.data;
            }).catch(error => {
                if(error.response.status === 401){
                    this.$store.dispatch('logout');
                    this.$router.push('/')
                }
                if( (error.response.status !== 500 && error.response.status !== 400) && error.response.data.error){
                    Vue.$toast.error(error.response.data.error, {
                        position: 'top-right'
                    });
                }else if(error.response.status !== 500 && error.response.status !== 400){
                    Vue.$toast.error(error.response.data, {
                        position: 'top-right'
                    });
                }else if(error.response.status === 500 || error.response.status == 400){
                    Vue.$toast.error('Oooops! Something went wrong!', {
                        position: 'top-right'
                    });
                }
            });
        },
        addDevice(){
            
            var formData = new FormData;
            formData.append('ipv4', this.ipv4);
            formData.append('ipv6', this.ipv6);
            formData.append('speed', this.speed);
            formData.append('private_device_id', this.selected_router);
            formData.append('peer_network_id', this.to_network);
            formData.append('peer_name', this.queryNet);
            formData.append('peer_asn', this.peer_asn);
            formData.append('peer_ipv4', this.peer_ipv4);
            formData.append('peer_ipv6', this.peer_ipv6);
            formData.append('prefix4', this.prefix4);
            formData.append('prefix6', this.prefix6);
            formData.append('policy_v4_import', this.selected_policy_v4_import);
            formData.append('policy_v4_export', this.selected_policy_v4_export);
            formData.append('policy_v6_import', this.selected_policy_v6_import);
            formData.append('policy_v6_export', this.selected_policy_v6_export);

            if(this.ipv4 && this.speed && this.selected_router && this.to_network && this.queryNet 
                && this.peer_asn && this.peer_ipv4 && this.selected_policy_v4_import 
                && this.selected_policy_v4_export){

                PrivatePeeringService.createPrivatePeerings(this.user.id, this.networkId, formData).then(response => {
                    if(response.data.status == 'error'){
                        this.not_allowed_modal = true;
                        this.closeDeviceModal();
                    }else{
                        this.ipv4 = null;
                        this.ipv6 = null;
                        this.speed = null;
                        this.peer_network_id = null;
                        this.peer_name = null;
                        this.peer_asn = null;
                        this.peer_ipv4 = null;
                        this.peer_ipv6 = null;
                        this.policy_v4_import = "";
                        this.policy_v4_export = "";
                        this.policy_v6_import = "";
                        this.policy_v6_export = "";
                        this.queryNet = '';
                        this.selected_policy_v4_import = "";
                        this.selected_policy_v4_export = "";
                        this.selected_policy_v6_import = "";
                        this.selected_policy_v6_export = "";
                        this.selected_router = '';
                        this.prefix4 = null;
                        this.prefix6 = null;

                        Vue.$toast.success('Private Peering Added!',{
                            position: 'top-right'
                        });
                        this.getPDevices();
                        this.closeDeviceModal();
                    }
                }).catch(error => {
                    if(error.response.data.error){
                        Vue.$toast.error(error.response.data.error, {
                            position: 'top-right'
                        });
                    }else{
                        Vue.$toast.error('Oooops! Something went wrong!', {
                            position: 'top-right'
                        });
                    }
                });
            }else{
                alert('All fields are required!');
            }
        },
        async searchNet(){

            this.showDropdownNet = true;
            if(this.queryNet != '' && this.queryNet.length > 2){
                this.error_search_net = null;
                await this.getListOfNetworks(this.user.id, this.queryNet);
            }else{
                this.showDropdownNet = false;
                this.filteredNet = [];
                this.error_search_net = 'Min. 3 characters required!';
            }
        },
        async getListOfNetworks(user_id, query){
            await NetService.getListOfNets(user_id, this.networkId, query).then(response => {
                this.networks = response.data;
                this.filteredNet = this.networks;
                if(this.networks.length == 0){
                    this.no_net_res = true;
                }else{
                    this.no_net_res = false;
                }
                
            }).catch(error => {
                if(error.response.status === 401){
                    this.$store.dispatch('logout');
                    this.$router.push('/')
                }
                if( (error.response.status !== 500 && error.response.status !== 400) && error.response.data.error){
                    Vue.$toast.error(error.response.data.error, {
                        position: 'top-right'
                    });
                }else if(error.response.status !== 500 && error.response.status !== 400){
                    Vue.$toast.error(error.response.data, {
                        position: 'top-right'
                    });
                }else if(error.response.status === 500 || error.response.status == 400){
                    Vue.$toast.error('Oooops! Something went wrong!', {
                        position: 'top-right'
                    });
                }
            });
        },
        routerTypes(){
            RouterServce.getRouterTypes().then(response => {
                this.router_types = response.data;
                this.router_types.sort(function(a, b) {
                    return a.name.localeCompare(b.name);
                });
            }).catch(error =>{
                if(error.response.status === 401){
                    this.$store.dispatch('logout');
                    this.$router.push('/')
                }
                if( (error.response.status !== 500 && error.response.status !== 400) && error.response.data.error){
                    Vue.$toast.error(error.response.data.error, {
                        position: 'top-right'
                    });
                }else if(error.response.status !== 500 && error.response.status !== 400){
                    Vue.$toast.error(error.response.data, {
                        position: 'top-right'
                    });
                }else if(error.response.status === 500 || error.response.status == 400){
                    Vue.$toast.error('Oooops! Something went wrong!', {
                        position: 'top-right'
                    });
                }
            });
        },
        openDeviceModal(){
            this.deviceModal = true;
            this.background_of_modal = true;
        },
        closeDeviceModal(){
            this.deviceModal = false;
            this.background_of_modal = false;
        },
        openDropdown(){
            this.showDropdown = true;
        },
        openDropdownNet(){
            this.showDropdownNet = true;
        },
        hideDropdownNet(){
            this.showDropdownNet = false;
        },
        hideDropdown(){
            this.showDropdown = false;
        },
        getAllPolicies(user_net_id){
            return new Promise((resolve, reject)=>{
                PolicyService.getPolices(this.user.id, user_net_id).then(response => {
                    resolve(response.data);
                }).catch(error => {
                    if(error.response.data.error){
                        Vue.$toast.error(error.response.data.error, {
                            position: 'top-right'
                        });
                    }else{
                        Vue.$toast.error('Oooops! Something went wrong!', {
                            position: 'top-right'
                        });
                    }
                    reject(error);
                });     
            });      
        },
        // onChange user net
        async networkChanged(network_id){
            this.network_id = network_id;
            this.networkId = network_id;
            this.selectedIx = null;
            this.peered = [];
            this.not_peered = [];
            this.approvel = [];
            this.pending = [];
            this.local_asn = this.getNetworkAsn(this.networkId);
            this.routerTypes();
            this.getPDevices();
            this.getNetworkPrivatePolicies();
            await this.getAllPrivateRouters(this.user.id, this.networkId);
        },
    }
}
</script>