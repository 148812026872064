import ApiService from "../../services/ApiService";

export default {

    getEmailTemplates(user_id, net_id){
        return ApiService.get(`user/${user_id}/net/${net_id}/emailTemplate`);
    },
    createEmailTemplate(user_id, net_id, data){
        return ApiService.post(`user/${user_id}/net/${net_id}/emailTemplate/create`, data);
    },
    editEmailTemplate(user_id, net_id, data){
        return ApiService.post(`user/${user_id}/net/${net_id}/emailTemplate/edit`, data);
    },
    deleteEmailTemplate(user_id, net_id, data){
        return ApiService.post(`user/${user_id}/net/${net_id}/emailTemplate/delete`, data);
    },
}