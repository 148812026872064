<template src="./template.html"></template>
<style src="./style.css" scoped></style>

<script>
import Vue from 'vue';

// import components
import DashboardTopNav from "../../components/DashboardTopNavComponent/DashboardTopNavComponent";
import SideBarNavigation from "../../components/SidebarNavigationComponent/SidebarNavigationComponent";
import Footer from '../../components/FooterDashboardComponent/FooterDashboardComponent';
import IternetExchange from "../../components/InternetExchangeComponent/InternetExchangeComponent";
import ApproveCard from "../../components/ApproveCardComponent/ApproveCardComponent";
import PeeredCard from "../../components/PeeredCardComponent/PeeredCardComponent";
import PendingCard from "../../components/PendingCardComponent/PendingCardComponent";
import NotPeeredCard from "../../components/NotPeeredCardComponent/NotPeeredCardComponent";
import DashboardLoader from '../../components/DashboardLoaderComponent/DashboardLoaderComponent';
import ManuallyAddSessionPerIxTable from '../../components/ManuallyAddSessionPerIxTableComponent/ManuallyAddSessionPerIxTableComponent';

// import services
import NetService from '../../services/NetService';
import ConfigService from '../../services/user/ConfigService';
import RouterService from '../../services/user/RouterService';
import PolicyService from '../../services/user/PolicyService';

export default {
    name: "PeerList",
    components:{
        DashboardTopNav,
        SideBarNavigation,
        IternetExchange,
        ApproveCard,
        PeeredCard,
        PendingCard,
        NotPeeredCard,
        DashboardLoader,
        Footer,
        ManuallyAddSessionPerIxTable
    },
    directives: {
        "click-outside": {
            bind: function(el, binding) {
                // Define ourClickEventHandler
                const ourClickEventHandler = event => {
                    if (!el.contains(event.target) && el !== event.target && event.target.classList.contains('click_filter') == false && event.target.tagName != 'path') {
                        // as we are attaching an click event listern to the document (below)
                        // ensure the events target is outside the element or a child of it
                        binding.value(event); // before binding it
                    }
                };
                // attached the handler to the element so we can remove it later easily
                el.__vueClickEventHandler__ = ourClickEventHandler;

                // attaching ourClickEventHandler to a listener on the document here
                document.addEventListener("click", ourClickEventHandler);
            },
            unbind: function(el) {
                // Remove Event Listener
                document.removeEventListener("click", el.__vueClickEventHandler__);
            }
        }
    },
    data(){
        return {
            page: null,
            user:{},
            modalConfig: false,
            filterModel: false,
            ixes: [],
            selectedIx: null,
            networkId: null,
            network_id:null,
            netSpeed: null,
            totalPeered: 0,
            totalRsPeer: 0,
            totalNotPeered: 0,
            totalPending: 0,
            selectedPolicyV4: [],
            selectedPolicyV6: [],
            selectedPolicyV4_out: [],
            selectedPolicyV6_out: [],
            configs: null,
            peered: [],
            pending: [],
            not_peered: [],
            approvel: [],
            configIx: null,
            configIxes: [],
            checkbox_peered: 0,
            checkbox_not_peered: 0,
            checkbox_pending: 0,
            checkbox_rs: false,
            hidePending: false,
            hidePeered: false,
            hideApprovel: false,
            hideNotPeered: false,
            scopes: [],
            types: [],
            policies: [],
            selectedScope: 'all',
            selectedType: 'all',
            selectedPolicy: 'all',
            loader: false,
            router_types: [],
            selected_router_type: 1,
            selected_ix_config: null,
            background_of_modal: false,
            network_routers: [],
            selected_network_router: null,
            show_ix_session_modal: false,
            ix_polcies: [],
            selectedIxPolicyV4: [],
            selectedIxPolicyV6: [],
            selectedIxPolicyV4_out: [],
            selectedIxPolicyV6_out: [],
            selected_ix_settings_id: null,
            queryListedPeer: null,
            searchPeerresuts: [],
        }
    },
    async created(){
        this.page = this.$route.name.toLowerCase();
        // get user first net_id
        if(this.$store.getters.user.id){
            if(localStorage.getItem('activeNet')){
                this.networkId = localStorage.getItem('activeNet');
            }else{
                if(this.$store.getters.user.networks){
                    this.networkId  = this.$store.getters.user.networks[0].peeringdb_network_id;
                }
            }
            this.user  = {
                id: this.$store.getters.user.id,
            };
        }
        // set Internet Exchange
        this.network_id = this.networkId; 
        this.routerTypes();
    },
    methods:{
        searchForPeer(){
            var local_peered = JSON.parse(localStorage.getItem('peered'));
            var local_pending = JSON.parse(localStorage.getItem('pending'));
            var local_not_peered = JSON.parse(localStorage.getItem('not_peered'));

            local_not_peered = local_not_peered.filter(item => {
               if(item.name.toLowerCase().indexOf(this.queryListedPeer.toLowerCase()) > -1){
                   return item;
               }
            });


            local_peered = local_peered.filter(item => {
               if(item.name.toLowerCase().indexOf(this.queryListedPeer.toLowerCase()) > -1){
                   return item;
               }
            });

            local_pending = local_pending.filter(item => {
               if(item.name.toLowerCase().indexOf(this.queryListedPeer.toLowerCase()) > -1){
                   return item;
               }
            });

            this.not_peered = local_not_peered;
            this.not_peered.sort(function(a, b) {
                return a.name.localeCompare(b.name);
            });

            this.peered = local_peered;
            this.peered.sort(function(a, b) {
                return a.name.localeCompare(b.name);
            });

            this.pending = local_pending;
            this.pending.sort(function(a, b) {
                return a.name.localeCompare(b.name);
            });

            this.filterPolicyTypeScope2( this.peered, this.pending, this.not_peered);


        },
        doCopy: function () {
            var conf_text = document.getElementById('router_config').innerText;
            this.$copyText(conf_text).then(function () {
                alert('Copied')
            }, function () {
                alert('Can not copy')
            })
        },
        saveNewSessionsPolicies(){
            if(this.selectedIxPolicyV4 && this.selectedIxPolicyV4_out && this.selectedIxPolicyV6 && this.selectedIxPolicyV6_out){
                const formData = new FormData;
                formData.append('policy_v4', this.selectedIxPolicyV4);
                formData.append('policy_v4_out', this.selectedIxPolicyV4_out);
                formData.append('policy_v6', this.selectedIxPolicyV6);
                formData.append('policy_v6_out', this.selectedIxPolicyV6_out);
                formData.append('ix_id', this.selected_ix_settings_id);
                PolicyService.editPolicesForIx(this.user.id, this.networkId, this.selected_ix_settings_id, formData).then(response => {
                    if(response.data){
                        Vue.$toast.success('Policies saved!', {
                            position: 'top-right'
                        });
                    }
                }).catch(error => {
                    if(error.response.status === 401){
                        this.$store.dispatch('logout');
                        this.$router.push('/')
                    }
                    if( (error.response.status !== 500 && error.response.status !== 400) && error.response.data.error){
                        Vue.$toast.error(error.response.data.error, {
                            position: 'top-right'
                        });
                    }else if(error.response.status !== 500 && error.response.status !== 400){
                        Vue.$toast.error(error.response.data, {
                            position: 'top-right'
                        });
                    }else if(error.response.status === 500 || error.response.status == 400){
                        Vue.$toast.error('Oooops! Something went wrong!', {
                            position: 'top-right'
                        });
                    }
                });
            }else{
                alert('Please select all policies');
            }
        },
        getIxPolicies(user_id, network_id, ix_id){
            this.ix_polcies = [];
            PolicyService.getPolicesForIx(user_id, network_id, ix_id).then(response => {
                this.ix_polcies = response.data;
                this.ix_polcies = this.getUniqueListBy(this.ix_polcies, 'id');
            }).catch(error => {
                if(error.response.status === 401){
                    this.$store.dispatch('logout');
                    this.$router.push('/')
                }
                if( (error.response.status !== 500 && error.response.status !== 400) && error.response.data.error){
                    Vue.$toast.error(error.response.data.error, {
                        position: 'top-right'
                    });
                }else if(error.response.status !== 500 && error.response.status !== 400){
                    Vue.$toast.error(error.response.data, {
                        position: 'top-right'
                    });
                }else if(error.response.status === 500 || error.response.status == 400){
                    Vue.$toast.error('Oooops! Something went wrong!', {
                        position: 'top-right'
                    });
                }
            });
        },
        openIxSessionModal(){
            this.show_ix_session_modal = true;
        },
        hideIxSessionModal(){
            this.show_ix_session_modal = false;
        },
        getCurrentNetRouter(user_id, net_id, ix_id){
            RouterService.getNetworkRouterList(user_id, net_id, ix_id).then(response => {
                this.network_routers = response.data;
                this.selected_network_router = this.network_routers[0].id;
            }).catch(error => {
                if(error.response.status === 401){
                    this.$store.dispatch('logout');
                    this.$router.push('/')
                }
                if( (error.response.status !== 500 && error.response.status !== 400) && error.response.data.error){
                    Vue.$toast.error(error.response.data.error, {
                        position: 'top-right'
                    });
                }else if(error.response.status !== 500 && error.response.status !== 400){
                    Vue.$toast.error(error.response.data, {
                        position: 'top-right'
                    });
                }else if(error.response.status === 500 || error.response.status == 400){
                    Vue.$toast.error('Oooops! Something went wrong!', {
                        position: 'top-right'
                    });
                }
            });
        },
        modalChange(modal){
            if(modal){
                this.background_of_modal = true;
            }else{
                this.background_of_modal = false;
            }
        },
        changeCheckboxRouter(){
            if(this.checkbox_rs){
                this.checkbox_rs = false;
            }else{
                this.checkbox_rs = true;
            }

           this.filterPolicyTypeScope();

        },
        async routerTypes(){
           await RouterService.getRouterTypes().then(response => {
                this.router_types = response.data;

                this.router_types.sort(function(a, b) {
                    return a.name.localeCompare(b.name);
                });
            }).catch(error =>{
                console.log(error);
            });
        },
        filterPolicyTypeScope2(local_peered, local_pending, local_not_peered){
            var hellper_not_peered = [];
            var hellper_peered = [];
            var hellper_pending = [];

            // var local_peered = JSON.parse(localStorage.getItem('peered'));
            // var local_pending = JSON.parse(localStorage.getItem('pending'));
            // var local_not_peered = JSON.parse(localStorage.getItem('not_peered'));

            if(this.selectedScope != 'all' && this.selectedType == 'all' && this.selectedPolicy == 'all'){
                
                local_not_peered.map(item => {
                    if(item.info_scope == this.selectedScope){
                        if(this.checkbox_rs){
                            if(item.is_rs_peer == 1){
                                hellper_not_peered.push(item);
                            }
                        }else{
                            hellper_not_peered.push(item);
                        }
                        
                    }
                });

                if(local_peered){
                    local_peered.map(item => {
                        if(item.info_scope == this.selectedScope){
                            if(this.checkbox_rs){
                                if(item.is_rs_peer == 1){
                                    hellper_peered.push(item);
                                }
                            }else{
                                hellper_peered.push(item);
                            }
                        }
                    });
                }

                if(local_pending){
                    local_pending.map(item => {
                        if(item.info_scope == this.selectedScope){
                            if(this.checkbox_rs){
                                if(item.is_rs_peer == 1){
                                    hellper_pending.push(item);
                                }
                            }else{
                                hellper_pending.push(item);
                            }
                            
                        }
                    });
                }

                this.not_peered = hellper_not_peered;
                this.not_peered.sort(function(a, b) {
                    return a.name.localeCompare(b.name);
                });

                this.peered = hellper_peered;
                this.peered.sort(function(a, b) {
                    return a.name.localeCompare(b.name);
                });

                this.pending = hellper_pending;
                this.pending.sort(function(a, b) {
                    return a.name.localeCompare(b.name);
                });

            }else if(this.selectedScope != 'all' && this.selectedType != 'all' && this.selectedPolicy == 'all'){
                local_not_peered.map(item => {
                    if(item.info_scope == this.selectedScope && item.info_type == this.selectedType){

                        if(this.checkbox_rs){
                            if(item.is_rs_peer == 1){
                                hellper_not_peered.push(item);
                            }
                        }else{
                            hellper_not_peered.push(item);
                        }
                        
                        
                    }
                });

                if(local_peered){
                    local_peered.map(item => {
                        if(item.info_scope == this.selectedScope && item.info_type == this.selectedType){

                            if(this.checkbox_rs){
                                if(item.is_rs_peer == 1){
                                    hellper_peered.push(item);
                                }
                            }else{
                                hellper_peered.push(item);
                            }
                            
                        }
                    });
                }

                if(local_pending){
                    local_pending.map(item => {
                        if(item.info_scope == this.selectedScope && item.info_type == this.selectedType){
                            if(this.checkbox_rs){
                                if(item.is_rs_peer == 1){
                                    hellper_pending.push(item);
                                }
                            }else{
                                hellper_pending.push(item);
                            }
                            
                        }
                    });
                }

                this.not_peered = hellper_not_peered;
                this.not_peered.sort(function(a, b) {
                    return a.name.localeCompare(b.name);
                });
                this.peered = hellper_peered;
                this.peered.sort(function(a, b) {
                    return a.name.localeCompare(b.name);
                });
                this.pending = hellper_pending;
                this.pending.sort(function(a, b) {
                    return a.name.localeCompare(b.name);
                });

            }else if(this.selectedScope != 'all' && this.selectedType == 'all' && this.selectedPolicy != 'all'){
                local_not_peered.map(item => {
                    if(item.info_scope == this.selectedScope && item.policy_general == this.selectedPolicy){
                        if(this.checkbox_rs){
                            if(item.is_rs_peer == 1){
                                hellper_not_peered.push(item);
                            }
                        }else{
                            hellper_not_peered.push(item);
                        }
                        
                    }
                });

                if(local_peered){
                    local_peered.map(item => {
                        if(item.info_scope == this.selectedScope && item.policy_general == this.selectedPolicy){

                            if(this.checkbox_rs){
                                if(item.is_rs_peer == 1){
                                    hellper_peered.push(item);
                                }
                            }else{
                                hellper_peered.push(item);
                            }
                            
                        }
                    });
                }

                if(local_pending){
                    local_pending.map(item => {
                        if(item.info_scope == this.selectedScope && item.policy_general == this.selectedPolicy){
                            if(this.checkbox_rs){
                                if(item.is_rs_peer == 1){
                                    hellper_pending.push(item);
                                }
                            }else{
                                hellper_pending.push(item);
                            }
                           
                        }
                    });
                }

                this.not_peered = hellper_not_peered;
                this.not_peered.sort(function(a, b) {
                    return a.name.localeCompare(b.name);
                });
                this.peered = hellper_peered;
                this.peered.sort(function(a, b) {
                    return a.name.localeCompare(b.name);
                });
                this.pending = hellper_pending;
                this.pending.sort(function(a, b) {
                    return a.name.localeCompare(b.name);
                });

            }else if(this.selectedScope != 'all' && this.selectedType != 'all' && this.selectedPolicy != 'all'){
                local_not_peered.map(item => {
                    if(item.info_scope == this.selectedScope && item.policy_general == this.selectedPolicy && item.info_type == this.selectedType){
                        if(this.checkbox_rs){
                            if(item.is_rs_peer == 1){
                                hellper_not_peered.push(item);
                            }
                        }else{
                            hellper_not_peered.push(item);
                        }
                        
                    }
                });

                if(local_peered){
                    local_peered.map(item => {
                        if(item.info_scope == this.selectedScope && item.policy_general == this.selectedPolicy && item.info_type == this.selectedType){

                            if(this.checkbox_rs){
                                if(item.is_rs_peer == 1){
                                    hellper_peered.push(item);
                                }
                            }else{
                                hellper_peered.push(item);
                            }
                            
                        }
                    });
                }

                if(local_pending){
                    local_pending.map(item => {
                        if(item.info_scope == this.selectedScope && item.policy_general == this.selectedPolicy && item.info_type == this.selectedType){
                            if(this.checkbox_rs){
                                if(item.is_rs_peer == 1){
                                   hellper_pending.push(item);
                                }
                            }else{
                                hellper_pending.push(item);
                            }
                            
                        }
                    });
                }

                this.not_peered = hellper_not_peered;
                this.not_peered.sort(function(a, b) {
                    return a.name.localeCompare(b.name);
                });
                this.peered = hellper_peered;
                this.peered.sort(function(a, b) {
                    return a.name.localeCompare(b.name);
                });
                this.pending = hellper_pending;
                this.pending.sort(function(a, b) {
                    return a.name.localeCompare(b.name);
                });

            }else if(this.selectedScope == 'all' && this.selectedType != 'all' && this.selectedPolicy == 'all'){
                
                local_not_peered.map(item => {
                    if(item.info_type == this.selectedType){
                        if(this.checkbox_rs){
                            if(item.is_rs_peer == 1){
                                hellper_not_peered.push(item);
                            }
                        }else{
                            hellper_not_peered.push(item);
                        }
                        
                    }
                });

                if(local_peered){
                    local_peered.map(item => {
                        if(item.info_type == this.selectedType){
                            if(this.checkbox_rs){
                                if(item.is_rs_peer == 1){
                                    hellper_peered.push(item);
                                }
                            }else{
                                hellper_peered.push(item);
                            }
                        }
                    });
                }

                if(local_pending){
                    local_pending.map(item => {
                        if(item.info_type == this.selectedType){
                            if(this.checkbox_rs){
                                if(item.is_rs_peer == 1){
                                    hellper_pending.push(item);
                                }
                            }else{
                                hellper_pending.push(item);
                            }
                            
                        }
                    });
                }

                this.not_peered = hellper_not_peered;
                this.not_peered.sort(function(a, b) {
                    return a.name.localeCompare(b.name);
                });
                this.peered = hellper_peered;
                this.peered.sort(function(a, b) {
                    return a.name.localeCompare(b.name);
                });
                this.pending = hellper_pending;
                this.pending.sort(function(a, b) {
                    return a.name.localeCompare(b.name);
                });

            }else if(this.selectedScope == 'all' && this.selectedType == 'all' && this.selectedPolicy != 'all'){
                
                local_not_peered.map(item => {
                    if(item.policy_general == this.selectedPolicy){
                        if(this.checkbox_rs){
                            if(item.is_rs_peer == 1){
                                 hellper_not_peered.push(item);
                            }
                        }else{
                            hellper_not_peered.push(item);
                        }
                       
                    }
                });

                if(local_peered){
                    local_peered.map(item => {
                        if(item.policy_general == this.selectedPolicy){
                            if(this.checkbox_rs){
                                if(item.is_rs_peer == 1){
                                    hellper_peered.push(item);
                                }
                            }else{
                                hellper_peered.push(item);
                            }
                            
                        }
                    });
                }

                if(local_pending){
                    local_pending.map(item => {
                        if(item.policy_general == this.selectedPolicy){
                            if(this.checkbox_rs){
                                if(item.is_rs_peer == 1){
                                    hellper_pending.push(item);
                                }
                            }else{
                               hellper_pending.push(item);
                            }
                            
                        }
                    });
                }

                this.not_peered = hellper_not_peered;
                this.not_peered.sort(function(a, b) {
                    return a.name.localeCompare(b.name);
                });
                this.peered = hellper_peered;
                this.peered.sort(function(a, b) {
                    return a.name.localeCompare(b.name);
                });
                this.pending = hellper_pending;
                this.pending.sort(function(a, b) {
                    return a.name.localeCompare(b.name);
                });
            }else if(this.selectedScope == 'all' && this.selectedType != 'all' && this.selectedPolicy != 'all'){
                
                local_not_peered.map(item => {
                    if(item.info_type == this.selectedType && item.policy_general == this.selectedPolicy){
                        if(this.checkbox_rs){
                            if(item.is_rs_peer == 1){
                               hellper_not_peered.push(item);
                            }
                        }else{
                            hellper_not_peered.push(item);
                        }
                        
                    }
                });

                if(local_peered){
                    local_peered.map(item => {
                        if(item.info_type == this.selectedType && item.policy_general == this.selectedPolicy){
                            if(this.checkbox_rs){
                                if(item.is_rs_peer == 1){
                                    hellper_peered.push(item);
                                }
                            }else{
                                hellper_peered.push(item);
                            }
                            
                        }
                    });
                }

                if(local_pending){
                    local_pending.map(item => {
                        if(item.info_type == this.selectedType && item.policy_general == this.selectedPolicy){
                            if(this.checkbox_rs){
                                if(item.is_rs_peer == 1){
                                   hellper_pending.push(item);
                                }
                            }else{
                               hellper_pending.push(item);
                            }
                            
                        }
                    });
                }

                this.not_peered = hellper_not_peered;
                this.not_peered.sort(function(a, b) {
                    return a.name.localeCompare(b.name);
                });
                this.peered = hellper_peered;
                this.peered.sort(function(a, b) {
                    return a.name.localeCompare(b.name);
                });
                this.pending = hellper_pending;
                this.pending.sort(function(a, b) {
                    return a.name.localeCompare(b.name);
                });
            }else{
                if(this.checkbox_rs){
                    local_not_peered.map(item => {
                        if(item.is_rs_peer == 1){
                            hellper_not_peered.push(item);
                        }
                    });

                    if(local_peered){
                        local_peered.map(item => {
                            if(item.is_rs_peer == 1){
                                hellper_peered.push(item);
                            }
                        });
                    }

                    if(local_pending){
                        local_pending.map(item => {
                            if(item.is_rs_peer == 1){
                                hellper_pending.push(item);
                            }
                        });
                    }
                    
                    this.not_peered = hellper_not_peered;
                    this.not_peered.sort(function(a, b) {
                        return a.name.localeCompare(b.name);
                    });
                    this.peered = hellper_peered;
                    this.peered.sort(function(a, b) {
                        return a.name.localeCompare(b.name);
                    });
                    this.pending = hellper_pending;
                    this.pending.sort(function(a, b) {
                        return a.name.localeCompare(b.name);
                    });
                }else{
                    this.not_peered = local_not_peered;
                    this.not_peered.sort(function(a, b) {
                        return a.name.localeCompare(b.name);
                    });
                    if(local_peered != null){
                        this.peered = local_peered;
                        this.peered.sort(function(a, b) {
                            return a.name.localeCompare(b.name);
                        });
                    }
                   
                    if(local_pending != null){
                        this.pending = local_pending;
                        this.pending.sort(function(a, b) {
                            return a.name.localeCompare(b.name);
                        });
                    }
                    
                }
                
            }
        },
        filterPolicyTypeScope(){
            var hellper_not_peered = [];
            var hellper_peered = [];
            var hellper_pending = [];

            var local_peered = JSON.parse(localStorage.getItem('peered'));
            var local_pending = JSON.parse(localStorage.getItem('pending'));
            var local_not_peered = JSON.parse(localStorage.getItem('not_peered'));

            if(this.selectedScope != 'all' && this.selectedType == 'all' && this.selectedPolicy == 'all'){
                
                local_not_peered.map(item => {
                    if(item.info_scope == this.selectedScope){
                        if(this.checkbox_rs){
                            if(item.is_rs_peer == 1){
                                hellper_not_peered.push(item);
                            }
                        }else{
                            hellper_not_peered.push(item);
                        }
                        
                    }
                });

                if(local_peered){
                    local_peered.map(item => {
                        if(item.info_scope == this.selectedScope){
                            if(this.checkbox_rs){
                                if(item.is_rs_peer == 1){
                                    hellper_peered.push(item);
                                }
                            }else{
                                hellper_peered.push(item);
                            }
                        }
                    });
                }

                if(local_pending){
                    local_pending.map(item => {
                        if(item.info_scope == this.selectedScope){
                            if(this.checkbox_rs){
                                if(item.is_rs_peer == 1){
                                    hellper_pending.push(item);
                                }
                            }else{
                                hellper_pending.push(item);
                            }
                            
                        }
                    });
                }

                this.not_peered = hellper_not_peered;
                this.not_peered.sort(function(a, b) {
                    return a.name.localeCompare(b.name);
                });

                this.peered = hellper_peered;
                this.peered.sort(function(a, b) {
                    return a.name.localeCompare(b.name);
                });

                this.pending = hellper_pending;
                this.pending.sort(function(a, b) {
                    return a.name.localeCompare(b.name);
                });

            }else if(this.selectedScope != 'all' && this.selectedType != 'all' && this.selectedPolicy == 'all'){
                local_not_peered.map(item => {
                    if(item.info_scope == this.selectedScope && item.info_type == this.selectedType){

                        if(this.checkbox_rs){
                            if(item.is_rs_peer == 1){
                                hellper_not_peered.push(item);
                            }
                        }else{
                            hellper_not_peered.push(item);
                        }
                        
                        
                    }
                });

                if(local_peered){
                    local_peered.map(item => {
                        if(item.info_scope == this.selectedScope && item.info_type == this.selectedType){

                            if(this.checkbox_rs){
                                if(item.is_rs_peer == 1){
                                    hellper_peered.push(item);
                                }
                            }else{
                                hellper_peered.push(item);
                            }
                            
                        }
                    });
                }

                if(local_pending){
                    local_pending.map(item => {
                        if(item.info_scope == this.selectedScope && item.info_type == this.selectedType){
                            if(this.checkbox_rs){
                                if(item.is_rs_peer == 1){
                                    hellper_pending.push(item);
                                }
                            }else{
                                hellper_pending.push(item);
                            }
                            
                        }
                    });
                }

                this.not_peered = hellper_not_peered;
                this.not_peered.sort(function(a, b) {
                    return a.name.localeCompare(b.name);
                });
                this.peered = hellper_peered;
                this.peered.sort(function(a, b) {
                    return a.name.localeCompare(b.name);
                });
                this.pending = hellper_pending;
                this.pending.sort(function(a, b) {
                    return a.name.localeCompare(b.name);
                });

            }else if(this.selectedScope != 'all' && this.selectedType == 'all' && this.selectedPolicy != 'all'){
                local_not_peered.map(item => {
                    if(item.info_scope == this.selectedScope && item.policy_general == this.selectedPolicy){
                        if(this.checkbox_rs){
                            if(item.is_rs_peer == 1){
                                hellper_not_peered.push(item);
                            }
                        }else{
                            hellper_not_peered.push(item);
                        }
                        
                    }
                });

                if(local_peered){
                    local_peered.map(item => {
                        if(item.info_scope == this.selectedScope && item.policy_general == this.selectedPolicy){

                            if(this.checkbox_rs){
                                if(item.is_rs_peer == 1){
                                    hellper_peered.push(item);
                                }
                            }else{
                                hellper_peered.push(item);
                            }
                            
                        }
                    });
                }

                if(local_pending){
                    local_pending.map(item => {
                        if(item.info_scope == this.selectedScope && item.policy_general == this.selectedPolicy){
                            if(this.checkbox_rs){
                                if(item.is_rs_peer == 1){
                                    hellper_pending.push(item);
                                }
                            }else{
                                hellper_pending.push(item);
                            }
                           
                        }
                    });
                }

                this.not_peered = hellper_not_peered;
                this.not_peered.sort(function(a, b) {
                    return a.name.localeCompare(b.name);
                });
                this.peered = hellper_peered;
                this.peered.sort(function(a, b) {
                    return a.name.localeCompare(b.name);
                });
                this.pending = hellper_pending;
                this.pending.sort(function(a, b) {
                    return a.name.localeCompare(b.name);
                });

            }else if(this.selectedScope != 'all' && this.selectedType != 'all' && this.selectedPolicy != 'all'){
                local_not_peered.map(item => {
                    if(item.info_scope == this.selectedScope && item.policy_general == this.selectedPolicy && item.info_type == this.selectedType){
                        if(this.checkbox_rs){
                            if(item.is_rs_peer == 1){
                                hellper_not_peered.push(item);
                            }
                        }else{
                            hellper_not_peered.push(item);
                        }
                        
                    }
                });

                if(local_peered){
                    local_peered.map(item => {
                        if(item.info_scope == this.selectedScope && item.policy_general == this.selectedPolicy && item.info_type == this.selectedType){

                            if(this.checkbox_rs){
                                if(item.is_rs_peer == 1){
                                    hellper_peered.push(item);
                                }
                            }else{
                                hellper_peered.push(item);
                            }
                            
                        }
                    });
                }

                if(local_pending){
                    local_pending.map(item => {
                        if(item.info_scope == this.selectedScope && item.policy_general == this.selectedPolicy && item.info_type == this.selectedType){
                            if(this.checkbox_rs){
                                if(item.is_rs_peer == 1){
                                   hellper_pending.push(item);
                                }
                            }else{
                                hellper_pending.push(item);
                            }
                            
                        }
                    });
                }

                this.not_peered = hellper_not_peered;
                this.not_peered.sort(function(a, b) {
                    return a.name.localeCompare(b.name);
                });
                this.peered = hellper_peered;
                this.peered.sort(function(a, b) {
                    return a.name.localeCompare(b.name);
                });
                this.pending = hellper_pending;
                this.pending.sort(function(a, b) {
                    return a.name.localeCompare(b.name);
                });

            }else if(this.selectedScope == 'all' && this.selectedType != 'all' && this.selectedPolicy == 'all'){
                
                local_not_peered.map(item => {
                    if(item.info_type == this.selectedType){
                        if(this.checkbox_rs){
                            if(item.is_rs_peer == 1){
                                hellper_not_peered.push(item);
                            }
                        }else{
                            hellper_not_peered.push(item);
                        }
                        
                    }
                });

                if(local_peered){
                    local_peered.map(item => {
                        if(item.info_type == this.selectedType){
                            if(this.checkbox_rs){
                                if(item.is_rs_peer == 1){
                                    hellper_peered.push(item);
                                }
                            }else{
                                hellper_peered.push(item);
                            }
                        }
                    });
                }

                if(local_pending){
                    local_pending.map(item => {
                        if(item.info_type == this.selectedType){
                            if(this.checkbox_rs){
                                if(item.is_rs_peer == 1){
                                    hellper_pending.push(item);
                                }
                            }else{
                                hellper_pending.push(item);
                            }
                            
                        }
                    });
                }

                this.not_peered = hellper_not_peered;
                this.not_peered.sort(function(a, b) {
                    return a.name.localeCompare(b.name);
                });
                this.peered = hellper_peered;
                this.peered.sort(function(a, b) {
                    return a.name.localeCompare(b.name);
                });
                this.pending = hellper_pending;
                this.pending.sort(function(a, b) {
                    return a.name.localeCompare(b.name);
                });

            }else if(this.selectedScope == 'all' && this.selectedType == 'all' && this.selectedPolicy != 'all'){
                
                local_not_peered.map(item => {
                    if(item.policy_general == this.selectedPolicy){
                        if(this.checkbox_rs){
                            if(item.is_rs_peer == 1){
                                 hellper_not_peered.push(item);
                            }
                        }else{
                            hellper_not_peered.push(item);
                        }
                       
                    }
                });

                if(local_peered){
                    local_peered.map(item => {
                        if(item.policy_general == this.selectedPolicy){
                            if(this.checkbox_rs){
                                if(item.is_rs_peer == 1){
                                    hellper_peered.push(item);
                                }
                            }else{
                                hellper_peered.push(item);
                            }
                            
                        }
                    });
                }

                if(local_pending){
                    local_pending.map(item => {
                        if(item.policy_general == this.selectedPolicy){
                            if(this.checkbox_rs){
                                if(item.is_rs_peer == 1){
                                    hellper_pending.push(item);
                                }
                            }else{
                               hellper_pending.push(item);
                            }
                            
                        }
                    });
                }

                this.not_peered = hellper_not_peered;
                this.not_peered.sort(function(a, b) {
                    return a.name.localeCompare(b.name);
                });
                this.peered = hellper_peered;
                this.peered.sort(function(a, b) {
                    return a.name.localeCompare(b.name);
                });
                this.pending = hellper_pending;
                this.pending.sort(function(a, b) {
                    return a.name.localeCompare(b.name);
                });
            }else if(this.selectedScope == 'all' && this.selectedType != 'all' && this.selectedPolicy != 'all'){
                
                local_not_peered.map(item => {
                    if(item.info_type == this.selectedType && item.policy_general == this.selectedPolicy){
                        if(this.checkbox_rs){
                            if(item.is_rs_peer == 1){
                               hellper_not_peered.push(item);
                            }
                        }else{
                            hellper_not_peered.push(item);
                        }
                        
                    }
                });

                if(local_peered){
                    local_peered.map(item => {
                        if(item.info_type == this.selectedType && item.policy_general == this.selectedPolicy){
                            if(this.checkbox_rs){
                                if(item.is_rs_peer == 1){
                                    hellper_peered.push(item);
                                }
                            }else{
                                hellper_peered.push(item);
                            }
                            
                        }
                    });
                }

                if(local_pending){
                    local_pending.map(item => {
                        if(item.info_type == this.selectedType && item.policy_general == this.selectedPolicy){
                            if(this.checkbox_rs){
                                if(item.is_rs_peer == 1){
                                   hellper_pending.push(item);
                                }
                            }else{
                               hellper_pending.push(item);
                            }
                            
                        }
                    });
                }

                this.not_peered = hellper_not_peered;
                this.not_peered.sort(function(a, b) {
                    return a.name.localeCompare(b.name);
                });
                this.peered = hellper_peered;
                this.peered.sort(function(a, b) {
                    return a.name.localeCompare(b.name);
                });
                this.pending = hellper_pending;
                this.pending.sort(function(a, b) {
                    return a.name.localeCompare(b.name);
                });
            }else{
                if(this.checkbox_rs){
                    local_not_peered.map(item => {
                        if(item.is_rs_peer == 1){
                            hellper_not_peered.push(item);
                        }
                    });

                    if(local_peered){
                        local_peered.map(item => {
                            if(item.is_rs_peer == 1){
                                hellper_peered.push(item);
                            }
                        });
                    }

                    if(local_pending){
                        local_pending.map(item => {
                            if(item.is_rs_peer == 1){
                                hellper_pending.push(item);
                            }
                        });
                    }
                    
                    this.not_peered = hellper_not_peered;
                    this.not_peered.sort(function(a, b) {
                        return a.name.localeCompare(b.name);
                    });
                    this.peered = hellper_peered;
                    this.peered.sort(function(a, b) {
                        return a.name.localeCompare(b.name);
                    });
                    this.pending = hellper_pending;
                    this.pending.sort(function(a, b) {
                        return a.name.localeCompare(b.name);
                    });
                }else{
                    this.not_peered = local_not_peered;
                    this.not_peered.sort(function(a, b) {
                        return a.name.localeCompare(b.name);
                    });
                    if(local_peered != null){
                        this.peered = local_peered;
                        this.peered.sort(function(a, b) {
                            return a.name.localeCompare(b.name);
                        });
                    }
                   
                    if(local_pending != null){
                        this.pending = local_pending;
                        this.pending.sort(function(a, b) {
                            return a.name.localeCompare(b.name);
                        });
                    }
                    
                }
                
            }
        },
        filterPeered(){
            this.checkbox_not_peered = 0;
            this.checkbox_pending = 0;
            if(!this.checkbox_peered){
                this.hidePending = true;
                this.hideNotPeered = true;
                this.hideApprovel = true;
                this.hidePeered = false;
            }else{
                this.hidePending = false;
                this.hideNotPeered = false;
                this.hideApprovel = false;
                this.hidePeered = false;
            }
        },
        filterNotPeered(){
            this.checkbox_pending = 0;
            this.checkbox_peered = 0;
            if(!this.checkbox_not_peered){
                this.hidePending = true;
                this.hideNotPeered = false;
                this.hideApprovel = true;
                this.hidePeered = true;
            }else{
                this.hidePending = false;
                this.hideNotPeered = false;
                this.hideApprovel = false;
                this.hidePeered = false;
            }
        },

        filterPending(){
            this.checkbox_not_peered = 0;
            this.checkbox_peered = 0;
            if(!this.checkbox_pending){
                this.hidePending = false;
                this.hideNotPeered = true;
                this.hideApprovel = true;
                this.hidePeered = true;
            }else{
                this.hidePending = false;
                this.hideNotPeered = false;
                this.hideApprovel = false;
                this.hidePeered = false;
            }
        },
        updateNetsFromNotPeered(value){
            if(value){
              var hellper = [];
              this.not_peered.map(item => {

                if(item.net_id != value.to_network){
                        hellper.push(item);
                    }else if(item.net_id == value.to_network && value.status == 2){
                        this.pending.push(item);
                    }else if(item.net_id == value.to_network && (value.status == 1 || value.status == 4) ){
                        this.peered.push(item);

                        var other_policies = value.other_policy;
                        var global_policies = value.policies;

                        for(let k = 0; k < global_policies.length; k++){

                            if(global_policies[k].global_v4_policy == 1 && global_policies[k].import_policy == 1){
                                item.policy4_id = global_policies[k].id;
                                item.policy4_name = 'Inherit Policy';
                                this.selectedPolicyV4[value.id] = global_policies[k].id;
                            }


                            if(global_policies[k].global_v6_policy == 1 && global_policies[k].import_policy == 1){
                                item.policy6_id = global_policies[k].id;
                                item.policy6_name = 'Inherit Policy';
                                this.selectedPolicyV6[value.id] = global_policies[k].id;
                            }

                            if(global_policies[k].global_v4_policy == 1 && global_policies[k].export_policy == 1){
                                item.policy4_out_id = global_policies[k].id;
                                item.policy4_out_name = 'Inherit Policy';
                                this.selectedPolicyV4_out[value.id] = global_policies[k].id;
                            }                           

                            if(global_policies[k].global_v6_policy == 1 && global_policies[k].export_policy == 1){
                                item.policy6_out_id = global_policies[k].id;
                                item.policy6_out_name = 'Inherit Policy';
                                this.selectedPolicyV6_out[value.id] = global_policies[k].id;
                            }

                        }

                        item.peering_id = value.id;
                        
                         // other policies
                        item.import_policies_v4 = [];
                        item.import_policies_v6 = [];
                        item.export_policies_v4 = [];
                        item.export_policies_v6 = [];

                        for(let k = 0; k < other_policies.length; k++){

                            // Policy In
                            if(item.import_policies_v4 && other_policies[k].import_policy === 1){

                                item.import_policies_v4.push({policy_name: other_policies[k].policy_name, policy_id: other_policies[k].id});

                            }else if(other_policies[k].import_policy === 1){

                                item.import_policies_v4.push({policy_name: other_policies[k].policy_name, policy_id: other_policies[k].id});
                            }

                            if(item.import_policies_v6 && other_policies[k].import_policy === 1){

                                item.import_policies_v6.push({policy_name: other_policies[k].policy_name, policy_id: other_policies[k].id});

                            }else if(other_policies[k].import_policy === 1){

                                item.import_policies_v6.push({policy_name: other_policies[k].policy_name, policy_id: other_policies[k].id});
                            }

                                // Policy out
                            if(item.export_policies_v4 && other_policies[k].export_policy === 1){

                                item.export_policies_v4.push({policy_name: other_policies[k].policy_name, policy_id: other_policies[k].id});

                            }else if(other_policies[k].export_policy === 1){

                                item.export_policies_v4.push({policy_name: other_policies[k].policy_name, policy_id: other_policies[k].id});
                            }

                            if(item.export_policies_v6 && other_policies[k].export_policy === 1){

                                item.export_policies_v6.push({policy_name: other_policies[k].policy_name, policy_id: other_policies[k].id});

                            }else if(other_policies[k].export_policy === 1){

                                item.export_policies_v6.push({policy_name: other_policies[k].policy_name, policy_id: other_policies[k].id});
                            }
                        }                                                         
                    }
                });

                this.not_peered = hellper;

                 this.peered.sort(function(a, b) {
                    return a.name.localeCompare(b.name);
                });

                 this.pending.sort(function(a, b) {
                    return a.name.localeCompare(b.name);
                });

                this.not_peered.sort(function(a, b) {
                    return a.name.localeCompare(b.name);
                });
            }
        },
        updateNetsFromPending(value){
            if(value){
                this.pending.map((item, index) => { 
                    if(item.net_id == value.to_network){
                        this.pending.splice(index,1);
                        this.not_peered.push(item);
                    }
                });

                this.not_peered.sort(function(a, b) {
                    return a.name.localeCompare(b.name);
                });
            }
        },
        dePeerNetsFromPeered(value){
            if(value){
                var hellper_peered = [];
                this.peered.map(item => { 
                    if(item.router_id != value.to_network_ixlan_id){
                        hellper_peered.push(item);   
                    }else{
                        this.not_peered.push(item);
                    }
                    
                });
                
                this.peered = hellper_peered;
                this.not_peered.sort(function(a, b) {
                    return a.name.localeCompare(b.name);
                });
            }
        },
        dePeerNetsFromSelectedPeered(value){
            if(value){
                var hellper_peered = [];
                this.peered.map(item => { 
                    if(item.router_id != value.to_network_ixlan_id){
                        hellper_peered.push(item);   
                    }else{
                        this.not_peered.push(item);
                    }
                    
                });
                
                this.peered = hellper_peered;
                this.not_peered.sort(function(a, b) {
                    return a.name.localeCompare(b.name);
                });
            }
        },
        updateNetsFromPeered(value){
            if(value){
                var hellper_peered = [];
                this.peered.map(item => { 
                    if(item.net_id == value.from_network || item.net_id == value.to_network){
                        if(value.new_prefix4){
                            item.new_prefix_4 = value.new_prefix4;
                        }

                        if(value.new_prefix6){
                            item.new_prefix_6 = value.new_prefix6;
                        }
                    }

                    hellper_peered.push(item);
                });
                
                this.peered = hellper_peered;
            }
        },
        hideFilterModel(){
            this.filterModel = false;
        },
        openFilterModel(){
            if(this.filterModel === false){
                this.filterModel = true;
            }else{
                this.filterModel = false;
            }
        },
        //Get user net information for given ix
        netInformation(user_net_id, ix){
            NetService.getNetInfo(this.user.id, user_net_id, ix).then(response => {
                this.netSpeed = response.data.speed / 1000 ;
            }).catch(error => {
                if(error.response.status === 401){
                    this.$store.dispatch('logout');
                    this.$router.push('/')
                }
                if( (error.response.status !== 500 && error.response.status !== 400) && error.response.data.error){
                    Vue.$toast.error(error.response.data.error, {
                        position: 'top-right'
                    });
                }else if(error.response.status !== 500 && error.response.status !== 400){
                    Vue.$toast.error(error.response.data, {
                        position: 'top-right'
                    });
                }else if(error.response.status === 500 || error.response.status == 400){
                    Vue.$toast.error('Oooops! Something went wrong!', {
                        position: 'top-right'
                    });
                }
            });
        },
        // onChange Internet Exchange
        onChangeIx(ix){
            if(ix){
                this.selectedIx = ix;
                this.getAllNets(this.networkId, this.selectedIx);
                this.netInformation(this.networkId,this.selectedIx);
                this.getCurrentNetRouter(this.user.id, this.networkId, this.selectedIx);
                this.getIxPolicies(this.user.id, this.networkId, ix)
            }
        },
        changeIxSettings(ix){
            this.selected_ix_settings_id = ix;
            this.getIxPolicies(this.user.id, this.networkId, ix);
        },
        // onChange user net
        networkChanged(network_id){
            this.network_id = network_id;
            this.networkId = network_id;
            this.selectedIx = null;
            this.peered = [];
            this.not_peered = [];
            this.approvel = [];
            this.pending = [];
            this.ix_polcies = [];
        },
        getIxes(ixes){
           this.ixes = ixes;
        },
        configIxChange(){
            this.getCurrentNetRouter(this.user.id, this.networkId, this.selected_ix_config);
        },
        getConfigRouter(){
            if(this.selected_ix_config && this.selected_router_type && this.selected_network_router){
                ConfigService.getConfig(this.user.id, this.selected_ix_config ,this.networkId, this.selected_router_type, this.selected_network_router).then(response => {
                    this.configs = response.data;
                }).catch(error => {
                    if(error.response.data.error){
                        Vue.$toast.error(error.response.data.error, {
                            position: 'top-right'
                        });
                    }else{
                        Vue.$toast.error(error.response.data, {
                            position: 'top-right'
                        });
                    }
                    
                    this.configs = null;

                });
            }
        },
        async openConfig(){
            if(this.networkId){
                this.modalConfig = true;
                this.background_of_modal = true;
                const ix = await this.getInternetExchange(this.networkId);
                this.configIxes = ix;
                // sort alphabeticly
                this.configIxes.sort(function(a, b) {
                    return a.name.localeCompare(b.name);
                });
                this.selected_ix_config = this.selectedIx;
                this.configs = null;
                this.configIxChange();
            }
        },
        closeConfig(){
            this.modalConfig = false;
            this.background_of_modal = false;
            this.configIxes = [];
        },
        // Get internet Exchanges for given user net_id
        getInternetExchange(user_net_id){
            return new Promise((resolve, reject)=>{
                NetService.getAllIx(this.user.id, user_net_id).then(response => {
                    resolve(response.data);
                }).catch(error => {
                   if(error.response.status === 401){
                        this.$store.dispatch('logout');
                        this.$router.push('/')
                    }
                    if( (error.response.status !== 500 && error.response.status !== 400) && error.response.data.error){
                        Vue.$toast.error(error.response.data.error, {
                            position: 'top-right'
                        });
                    }else if(error.response.status !== 500 && error.response.status !== 400){
                        Vue.$toast.error(error.response.data, {
                            position: 'top-right'
                        });
                    }else if(error.response.status === 500 || error.response.status == 400){
                        Vue.$toast.error('Oooops! Something went wrong!', {
                            position: 'top-right'
                        });
                    }
                   
                    reject(error);
                });     
            });      
        },
        getUniqueListBy(arr, key) {
            return [...new Map(arr.map(item => [item[key], item])).values()]
        },
        async getAllNets(user_net_id, ix){
            this.totalPeered = 0;
            this.totalNotPeered = 0;
            this.totalRsPeer = 0;
            this.totalPending = 0;
            this.selectedPolicyV6_out = [];
            this.selectedPolicyV4_out = [];
            this.selectedPolicyV4 = [];
            this.selectedPolicyV6 = [];
            this.peered = [];
            this.not_peered = [];
            this.approvel = [];
            this.pending = [];
            this.loader = true;
            
            NetService.getAllNetsForUser(this.user.id, user_net_id, ix).then(response => {
                
                var networks = response.data.data;
                var hellper_peerings = [];
                var peerings = response.data.peerings;

                // Eliminate duplicates peerings
                for(let p = 0; p < peerings.length; p++){
                  if(hellper_peerings.length > 0){
                        if(hellper_peerings.map(e => (e.from_network_ixlan_id != peerings[p].from_network_ixlan_id) && (e.to_network_ixlan_id != peerings[p].to_network_ixlan_id))){
                            hellper_peerings.push(peerings[p]);
                        }
                    }else{
                        hellper_peerings.push(peerings[p]);
                    }  
                }
                    
                peerings = hellper_peerings;

                networks = networks.filter(item =>{
                        if(item.hide != 1){
                            return item;
                        }
                    });

                for(let i = 0; i < networks.length; i++){

                    if(networks[i].is_rs_peer == 1){
                        this.totalRsPeer += 1;
                    }
                    // scopes
                    if(this.scopes.includes(networks[i].info_scope) != true && networks[i].info_scope != ''){
                        this.scopes.push(networks[i].info_scope);
                    }

                    // types
                    if(this.types.includes(networks[i].info_type) != true && networks[i].info_type != ''){
                        this.types.push(networks[i].info_type);
                    }
                    // policies
                    if(this.policies.includes(networks[i].policy_general) != true && networks[i].policy_general != ''){
                        this.policies.push(networks[i].policy_general);
                    }

                    for(let z = 0; z < peerings.length; z++){


                        if( ( ((networks[i].id === peerings[z].to_network && peerings[z].status == '1' && peerings[z].to_network_ixlan_id == networks[i].router_id) || (networks[i].id === peerings[z].from_network && peerings[z].status == '1' && peerings[z].from_network_ixlan_id == networks[i].router_id) ) || ((networks[i].id === peerings[z].to_network && peerings[z].status == '4' && peerings[z].to_network_ixlan_id == networks[i].router_id && peerings[z].original_from == this.networkId) || (networks[i].id === peerings[z].from_network && peerings[z].status == '4' && peerings[z].from_network_ixlan_id == networks[i].router_id && peerings[z].original_from == this.networkId) )) && (!networks[i].pending || !networks[i].peered || !networks[i].approve) ){

                            networks[i].peered = true;
                            networks[i].peering_id = peerings[z].id;

                            if(peerings[z].router_password){
                                networks[i].md5_password = peerings[z].router_password;

                            }

                            // set net prefix
                            //  for(let k = 0; k < (peerings[z].network_prefixes).length; k++){
                            if(peerings[z].network_prefixes){
                                if(peerings[z].network_prefixes.new_prefix_4){
                                    networks[i].new_prefix_4 = peerings[z].network_prefixes.new_prefix_4;
                                }

                                if(peerings[z].network_prefixes.new_prefix_6){
                                    networks[i].new_prefix_6 = peerings[z].network_prefixes.new_prefix_6;
                                }
                            }
 
                            //  }

                            // other policies
                            networks[i].import_policies_v4 = [];
                            networks[i].import_policies_v6 = [];
                            networks[i].export_policies_v4 = [];
                            networks[i].export_policies_v6 = [];

                            // global/active ipv4 and ipv6 polices
                            for(let k = 0; k < (peerings[z].global_peering_policies).length; k++){
                                
                                if( peerings[z].peering_policies.filter(function(e) { return (e.pivot.v4_import == 1 || e.pivot.v6_import == 1) }).length > 0 ){

                                    var policy_v4 = peerings[z].peering_policies.filter(function(e) { return e.pivot.v4_import == 1; });

                                    if(policy_v4.length > 0){
                                        // if  peering policy exists in peering_policies and is import v4
                                        if(policy_v4[0].pivot.v4_import == 1){
                                            networks[i].import_policies_v4.push({policy_name: policy_v4[0].policy_name, policy_id: policy_v4[0].id});
                                            this.selectedPolicyV4[networks[i].peering_id] = policy_v4[0].id;
                                        }
                                    }

                                    var policy_v6 = peerings[z].peering_policies.filter(function(e) { return e.pivot.v6_import == 1; });
                                    if(policy_v6.length > 0){
                                    // if  peering policy exists in peering_policies and is import v6
                                        if(policy_v6[0].pivot.v6_import == 1){
                                            networks[i].import_policies_v6.push({policy_name: policy_v6[0].policy_name, policy_id: policy_v6[0].id});
                                            this.selectedPolicyV6[networks[i].peering_id] = policy_v6[0].id;

                                        }
                                    }

                                }


                                if( peerings[z].peering_policies.filter(function(e) { return (e.pivot.v4_export == 1 || e.pivot.v6_export == 1) }).length > 0 ){

                                    var policy_v4_export = peerings[z].peering_policies.filter(function(e) { return e.pivot.v4_export == 1; });

                                    if(policy_v4_export.length > 0){
                                            // if  peering policy exists in peering_policies and is export v4
                                        if(policy_v4_export[0].pivot.v4_export == 1){
                                            networks[i].export_policies_v4.push({policy_name: policy_v4_export[0].policy_name, policy_id: policy_v4_export[0].id});
                                            this.selectedPolicyV4_out[networks[i].peering_id] = policy_v4_export[0].id;

                                        }
                                    }

                                    var policy_v6_export = peerings[z].peering_policies.filter(function(e) { return e.pivot.v6_export == 1; });

                                    if(policy_v6_export.length > 0){
                                        // if  peering policy exists in peering_policies and is import v4
                                        if(policy_v6_export[0].pivot.v6_export == 1){
                                            networks[i].export_policies_v6.push({policy_name: policy_v6_export[0].policy_name, policy_id: policy_v6_export[0].id});
                                            this.selectedPolicyV6_out[networks[i].peering_id] = policy_v6_export[0].id;
                                        }
                                    }

                                }
                                
                                // If not selected any other than global use global policy
                                 //  Import v4 global policy
                                if(peerings[z].global_peering_policies[k].global_v4_policy && peerings[z].global_peering_policies[k].import_policy){

                                    if(!this.selectedPolicyV4[networks[i].peering_id]){
                                        networks[i].policy4_id = peerings[z].global_peering_policies[k].id;
                                        networks[i].policy4_name = 'Inherit Policy';
                                        this.selectedPolicyV4[networks[i].peering_id] = peerings[z].global_peering_policies[k].id;
                                    }else{
                                        networks[i].policy4_id = peerings[z].global_peering_policies[k].id;
                                        networks[i].policy4_name = 'Inherit Policy';
                                    }                                  

                                }

                                //  Import v6 global policy
                                if(peerings[z].global_peering_policies[k].global_v6_policy && peerings[z].global_peering_policies[k].import_policy){

                                    if(!this.selectedPolicyV6[networks[i].peering_id]){
                                        networks[i].policy6_id = peerings[z].global_peering_policies[k].id;
                                        networks[i].policy6_name = 'Inherit Policy';
                                        this.selectedPolicyV6[networks[i].peering_id] = peerings[z].global_peering_policies[k].id;
                                    }else{
                                        networks[i].policy6_id = peerings[z].global_peering_policies[k].id;
                                        networks[i].policy6_name = 'Inherit Policy';
                                    }                                  

                                }

                                //  Export v4 global policy
                                if(peerings[z].global_peering_policies[k].global_v4_policy && peerings[z].global_peering_policies[k].export_policy){

                                    if(!this.selectedPolicyV4_out[networks[i].peering_id]){
                                        networks[i].policy4_out_id = peerings[z].global_peering_policies[k].id;
                                        networks[i].policy4_out_name = 'Inherit Policy';
                                        this.selectedPolicyV4_out[networks[i].peering_id] = peerings[z].global_peering_policies[k].id;
                                    }else{
                                        networks[i].policy4_out_id = peerings[z].global_peering_policies[k].id;
                                        networks[i].policy4_out_name = 'Inherit Policy';
                                    }
                                }

                                
                                //  Export v6 global policy
                                if(peerings[z].global_peering_policies[k].global_v6_policy && peerings[z].global_peering_policies[k].export_policy){

                                    if(!this.selectedPolicyV6_out[networks[i].peering_id]){
                                        networks[i].policy6_out_id = peerings[z].global_peering_policies[k].id;
                                        networks[i].policy6_out_name = 'Inherit Policy';
                                        this.selectedPolicyV6_out[networks[i].peering_id] = peerings[z].global_peering_policies[k].id;
                                    }else{
                                        networks[i].policy6_out_id = peerings[z].global_peering_policies[k].id;
                                        networks[i].policy6_out_name = 'Inherit Policy';
                                    }                                  
                                }
                            }

                            if((peerings[z].other_policy).length > 0){
                                for(let k = 0; k < (peerings[z].other_policy).length; k++){
                                      // Policy In v4
                                    if(networks[i].import_policies_v4 && peerings[z].other_policy[k].import_policy === 1 && peerings[z].other_policy[k].id != this.selectedPolicyV4[networks[i].peering_id]){

                                        networks[i].import_policies_v4.push({policy_name: peerings[z].other_policy[k].policy_name, policy_id: peerings[z].other_policy[k].id});

                                    }else if(peerings[z].other_policy[k].import_policy === 1 && peerings[z].other_policy[k].id != this.selectedPolicyV4[networks[i].peering_id]){

                                        networks[i].import_policies_v4.push({policy_name: peerings[z].other_policy[k].policy_name, policy_id: peerings[z].other_policy[k].id});
                                    }

                                    // Policy In v6
                                    if(networks[i].import_policies_v6 && peerings[z].other_policy[k].import_policy === 1 && this.selectedPolicyV6[networks[i].peering_id] != peerings[z].other_policy[k].id){

                                        networks[i].import_policies_v6.push({policy_name: peerings[z].other_policy[k].policy_name, policy_id: peerings[z].other_policy[k].id});

                                    }else if(peerings[z].other_policy[k].import_policy === 1 && this.selectedPolicyV6[networks[i].peering_id] != peerings[z].other_policy[k].id){

                                        networks[i].import_policies_v6.push({policy_name: peerings[z].other_policy[k].policy_name, policy_id: peerings[z].other_policy[k].id});
                                    }

                                    // Policy out v4
                                    if(networks[i].export_policies_v4 && peerings[z].other_policy[k].export_policy === 1 && this.selectedPolicyV4_out[networks[i].peering_id] != peerings[z].other_policy[k].id){

                                        networks[i].export_policies_v4.push({policy_name: peerings[z].other_policy[k].policy_name, policy_id: peerings[z].other_policy[k].id});

                                    }else if(peerings[z].other_policy[k].export_policy === 1 && this.selectedPolicyV4_out[networks[i].peering_id] != peerings[z].other_policy[k].id){

                                        networks[i].export_policies_v4.push({policy_name: peerings[z].other_policy[k].policy_name, policy_id: peerings[z].other_policy[k].id});
                                    }

                                    // Policy out v6
                                    if(networks[i].export_policies_v6 && peerings[z].other_policy[k].export_policy === 1 && this.selectedPolicyV6_out[networks[i].peering_id] != peerings[z].other_policy[k].id){

                                        networks[i].export_policies_v6.push({policy_name: peerings[z].other_policy[k].policy_name, policy_id: peerings[z].other_policy[k].id});

                                    }else if(peerings[z].other_policy[k].export_policy === 1 && this.selectedPolicyV6_out[networks[i].peering_id] != peerings[z].other_policy[k].id){

                                        networks[i].export_policies_v6.push({policy_name: peerings[z].other_policy[k].policy_name, policy_id: peerings[z].other_policy[k].id});
                                    }
                                    
                                }
                            }

                            // Remove dulpicates from arrays
                            networks[i].import_policies_v4 = this.getUniqueListBy(networks[i].import_policies_v4, 'policy_id');
                            networks[i].import_policies_v6 = this.getUniqueListBy(networks[i].import_policies_v6, 'policy_id');

                            networks[i].export_policies_v4 = this.getUniqueListBy(networks[i].export_policies_v4, 'policy_id');
                            networks[i].export_policies_v6 = this.getUniqueListBy(networks[i].export_policies_v6, 'policy_id');


                        }else if(networks[i].id == peerings[z].to_network && peerings[z].status == '2' && peerings[z].to_network_ixlan_id === networks[i].router_id && (!networks[i].pending || !networks[i].peered || !networks[i].approve)){

                            networks[i].pending = true;

                             // set net prefix

                            if(peerings[z].network_prefixes){
                                if(peerings[z].network_prefixes.new_prefix_4){
                                    networks[i].new_prefix_4 = peerings[z].network_prefixes.new_prefix_4;
                                }

                                if(peerings[z].network_prefixes.new_prefix_6){
                                    networks[i].new_prefix_6 = peerings[z].network_prefixes.new_prefix_6;
                                }
                            }
                            

                        }else if(networks[i].id === peerings[z].from_network && peerings[z].status == '2'
                            && (!networks[i].pending || !networks[i].peered || !networks[i].approve)){
                                  // set net prefix
                            if(peerings[z].network_prefixes){
                                for(let k = 0; k < (peerings[z].network_prefixes).length; k++){
                                    if(peerings[z].network_prefixes[k].new_prefix_4){
                                        networks[i].new_prefix_4 = peerings[z].network_prefixes[k].new_prefix_4;
                                    }

                                    if(peerings[z].network_prefixes[k].new_prefix_6){
                                        networks[i].new_prefix_6 = peerings[z].network_prefixes[k].new_prefix_6;
                                    }
    
                                }
                            }

                            networks[i].approve = true;
                        }

                    }

                    if(networks[i].peered === true){
                        this.totalPeered += 1;
                        this.peered.push(networks[i]);
                    }else if(networks[i].pending === true){
                        this.totalPending += 1;
                        this.pending.push(networks[i]);
                    }else if(networks[i].approve === true){
                        this.approvel.push(networks[i]);
                        
                    }else{
                        this.totalNotPeered += 1;
                        this.not_peered.push(networks[i]);
                    }
                }

                // Sort alphabetically
                this.peered.sort(function(a, b) {
                    return a.name.localeCompare(b.name);
                });
                localStorage.setItem('peered', JSON.stringify(this.peered));

                this.approvel.sort(function(a, b) {
                    return a.name.localeCompare(b.name);
                });
                localStorage.setItem('approvel', JSON.stringify(this.approvel));

                this.pending.sort(function(a, b) {
                    return a.name.localeCompare(b.name);
                });
                localStorage.setItem('pending', JSON.stringify(this.pending));

                this.not_peered.sort(function(a, b) {
                    return a.name.localeCompare(b.name);
                });
                localStorage.setItem('not_peered', JSON.stringify(this.not_peered));

                this.loader = false;

                
            }).catch(error => {
                if(error.response.status === 401){
                    this.$store.dispatch('logout');
                    this.$router.push('/')
                }
                if( (error.response.status !== 500 && error.response.status !== 400) && error.response.data.error){
                    Vue.$toast.error(error.response.data.error, {
                        position: 'top-right'
                    });
                }else if(error.response.status !== 500 && error.response.status !== 400){
                    Vue.$toast.error(error.response.data, {
                        position: 'top-right'
                    });
                }else if(error.response.status === 500 || error.response.status == 400){
                    Vue.$toast.error('Oooops! Something went wrong!', {
                        position: 'top-right'
                    });
                }
                this.loader = false;
            });
        },
    
    }
}
</script>