import ApiService from "../services/ApiService";

export default {

    sendMessage(data){
        return ApiService.post(`/email`, data);
    },
    wishMessage(user_id, net_id, data){
        return ApiService.post(`/user/${user_id}/net/${net_id}/wish-message`, data);
    }
}
