<template src="./template.html"></template>

<script>
import Vue from 'vue';
// import services
import ActivityLogService from '../../../services/user/ActivityLogService';

export default {
    name: 'NetworkLogs',
    props: [
        'user',
        'networkId'
    ],
    data(){
        return {
            network_logs: [],
            error_search_net: null,
            no_net_res: false,
            query_log: '',
        }
    },
    watch:{
        'networkId': function() {
            if(this.networkId){
                this.getAllNetworkLogsActivity(this.user.id, this.networkId, 0);
            }
        }
    },
    async created(){
        if(this.networkId){
            this.getAllNetworkLogsActivity(this.user.id, this.networkId, 0);
        }
    },
    methods:{
        findLog(skip){
            if(this.networkId){
                ActivityLogService.serchAllNetworkLogs(this.user_id, this.networkId, this.query_log, skip).then(response => {

                    if(this.network_logs.length == (response.data.data).length){
                        document.getElementById('loadMore').classList.add('hidden');
                    }else{
                        document.getElementById('loadMore').classList.remove('hidden');
                    }
                    this.network_logs = response.data.data;
                    
                }).catch(error => {
                    if(error.response.status === 401){
                        this.$store.dispatch('logout');
                        this.$router.push('/')
                    }
                    if( (error.response.status !== 500 && error.response.status !== 400) && error.response.data.error){
                        Vue.$toast.error(error.response.data.error, {
                            position: 'top-right'
                        });
                    }else if(error.response.status !== 500 && error.response.status !== 400){
                        Vue.$toast.error(error.response.data, {
                            position: 'top-right'
                        });
                    }else if(error.response.status === 500 || error.response.status == 400){
                        Vue.$toast.error('Oooops! Something went wrong!', {
                            position: 'top-right'
                        });
                    }
                });
            }
        },
        loadMoreLogs(){
            if(this.networkId){
                var skip = this.network_logs.length;
                if(this.query_log != '' && this.query_log){
                    this.findLog(skip);
                }else{
                    this.getAllNetworkLogsActivity(this.user.id, this.networkId, skip);
                }
            }
            
        },
        getAllNetworkLogsActivity(user_id, net_id, skip){
            if(this.networkId){
                ActivityLogService.getAllLogsForNet(user_id, net_id, skip).then(response => {
                    if(response.data){
                        if(response.data.data){
                            console.log(response.data.data);
                            if(this.network_logs.length == (response.data.data).length){
                                document.getElementById('loadMore').classList.add('hidden');
                            }else{
                                // document.getElementById('loadMore').classList.remove('hidden');
                            }
                        }
                        
                        this.network_logs = response.data.data;  
                    }
                }).catch(error => {
                    if(error.response.status === 401){
                        this.$store.dispatch('logout');
                        this.$router.push('/')
                    }
                    if( (error.response.status !== 500 && error.response.status !== 400) && error.response.data.error){
                        Vue.$toast.error(error.response.data.error, {
                            position: 'top-right'
                        });
                    }else if(error.response.status !== 500 && error.response.status !== 400){
                        Vue.$toast.error(error.response.data, {
                            position: 'top-right'
                        });
                    }else if(error.response.status === 500 || error.response.status == 400){
                        Vue.$toast.error('Oooops! Something went wrong!', {
                            position: 'top-right'
                        });
                    }
                });
            }
        },
    }
}
</script>