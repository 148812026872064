<template src="./template.html"></template>

<script>
import Vue from 'vue';

import SessionService from '../../services/user/SessionsService';
import RequestService from "@/services/user/RequestService";
import NetService from '../../services/NetService';

export default {
    name: 'ManualAddSessionPerIxTable',
    props: ['user', 'networkId', 'selectedIx'],
    data(){
        return{
            ix_sessions: [],
            network_routers: [],
            numberOfSelected: 0,
            selectedRows: [],
            sessionIxes: [],
            selectedSessionIx: '',
            searchQuery: '',
            filteredIxSessions: [],
        }
    },
    async created(){
        
        if(this.selectedIx){
            this.sessionIxes = await this.getInternetExchange(this.networkId);
            this.sessionIxes.sort(function(a, b) {
                return a.name.localeCompare(b.name);
            });
            this.getIxSesions(this.user.id, this.networkId, this.selectedIx);
            this.selectedSessionIx = this.selectedIx;
        }
    },
    watch:{
         'searchQuery': async function() {
            // setTimeout -> when user stop typing trigger serach method after 0.5 seconds
            if(this.timer) {
                clearTimeout(this.timer);
                this.timer = null;
            }
            this.timer = setTimeout(() => {
                if(this.searchQuery.length > 2){
                    this.totalRows = 0;
                    this.filteredIxSessions = this.ix_sessions.filter(item => {
                        return item.name.toLowerCase().indexOf(this.searchQuery.toLowerCase()) > -1
                    });
                    if(this.filteredIxSessions.length == 0){
                        this.filteredIxSessions = this.ix_sessions.filter(item => {
                            return String(item.asn).includes(this.searchQuery)
                        }); 
                    }
                }else{
                    this.filteredIxSessions = this.ix_sessions;
                }
            }, 500);
        }
    },
    methods:{
        onSessionIxChange(){
            this.$emit('change-ix-settings', this.selectedSessionIx);
            this.getIxSesions(this.user.id, this.networkId, this.selectedSessionIx);
        },
        // Get internet Exchanges for given user net_id
        getInternetExchange(user_net_id){
            if(user_net_id){
                return new Promise((resolve, reject)=>{
                    NetService.getAllIx(this.user.id, user_net_id).then(response => {
                        resolve(response.data);
                    }).catch(error => {
                        if(error.response.data.error){
                            Vue.$toast.error(error.response.data.error, {
                                position: 'top-right'
                            });
                        }else{
                            Vue.$toast.error('Oooops! Something went wrong!', {
                                position: 'top-right'
                            });
                        }
                    
                        reject(error);
                    });     
                });    
            }
              
        },
        bulkAddSession(){
            const formData = new FormData;
            formData.append('peers', JSON.stringify(this.selectedRows));

            RequestService.bulkManuallyAddSession(this.user.id, this.networkId, formData).then(response => {
                if(response.data.status == 'error'){
                    Vue.$toast.warning(response.data.message,{
                    position: 'top-right',
                    duration: 3000
                    });
                    
                }else {
                    Vue.$toast.success('Manually session added!', {
                    position: 'top-right'
                    });
                }

                this.getIxSesions(this.user.id,this.networkId, this.selectedSessionIx);
                this.selectedRows = [];
                this.deSelectAllCheckbox();
            }).catch(error => {
                if(error.response.status === 401){
                    this.$store.dispatch('logout');
                    this.$router.push('/')
                }
                if( (error.response.status !== 500 && error.response.status !== 400) && error.response.data.error){
                    Vue.$toast.error(error.response.data.error, {
                        position: 'top-right'
                    });
                }else if(error.response.status !== 500 && error.response.status !== 400){
                    Vue.$toast.error(error.response.data, {
                        position: 'top-right'
                    });
                }else if(error.response.status === 500 || error.response.status == 400){
                    Vue.$toast.error('Oooops! Something went wrong!', {
                        position: 'top-right'
                    });
                }
            });
        },
         getIxSesions(user_id, net_id, peer){
            this.ix_sessions = [];
            this.filteredIxSessions = this.ix_sessions;
            var loader = document.getElementById('ix_session_loader');
            loader.classList.remove('hidden');
            SessionService.getAllSessionsForIx(user_id, net_id, peer).then(response => {
                this.ix_sessions = response.data;
                this.filteredIxSessions = this.ix_sessions;
                this.filteredIxSessions.sort(function(a, b) {
                    return a.name.localeCompare(b.name);
                });
                loader.classList.add('hidden');
            }).catch(error => {
                loader.classList.add('hidden');
                if(error.response.status === 401){
                    this.$store.dispatch('logout');
                    this.$router.push('/')
                }
                if( (error.response.status !== 500 && error.response.status !== 400) && error.response.data.error){
                    Vue.$toast.error(error.response.data.error, {
                        position: 'top-right'
                    });
                }else if(error.response.status !== 500 && error.response.status !== 400){
                    Vue.$toast.error(error.response.data, {
                        position: 'top-right'
                    });
                }else if(error.response.status === 500 || error.response.status == 400){
                    Vue.$toast.error('Oooops! Something went wrong!', {
                        position: 'top-right'
                    });
                }
            });
        },
        deSelectAllCheckbox() {
            let columns = document.querySelectorAll('.rowCheckbox');
            this.numberOfSelected = 0;
            columns.forEach(column => {
                column.checked = false
            });
        },
        selectAllCheckbox($event) {
            let columns = document.querySelectorAll('.rowCheckbox');
            this.selectedRows = [];

            if ($event.target.checked == true) {
                columns.forEach(column => {
                    column.checked = true
                    var session = JSON.parse(column.name);
                    this.selectedRows.push(
                        {
                            router_id: parseInt(session.router_id),
                            local_router_id: parseInt(session.local_router_id),
                            ix_id: parseInt(session.ix_id), 
                            network_id: parseInt(session.network_id)
                        }
                    );
                });
            }else{
                columns.forEach(column => {
                    column.checked = false
                });
                this.selectedRows = [];
            }
            this.numberOfSelected = this.selectedRows.length;
        },
        getRowDetail($event, value){
            let rows = this.selectedRows;
            var session = JSON.parse(value);

            if (rows.filter(e => (e.router_id === session.router_id && e.local_router_id === session.local_router_id )).length > 0) {
                rows = rows.filter(e => (e.router_id != session.router_id && e.local_router_id != session.local_router_id));
            }else{
                rows.push(
                        {
                            router_id: parseInt(session.router_id),
                            local_router_id: parseInt(session.local_router_id),
                            ix_id: parseInt(session.ix_id), 
                            network_id: parseInt(session.network_id)
                        }
                    );
            }
            this.selectedRows = rows;
            this.numberOfSelected = this.selectedRows.length;
        },
    }
}
</script>