<template src="./template.html"></template>

<script>
import Vue from 'vue';

import PeerSettingsService from '../../../services/user/PeerSettingsService';
import NetService from '../../../services/NetService';

export default {
    name: 'PeerBlacklist',
    props: [
        'user',
        'networkId',
        'networks'
    ],
    directives: {
        "click-outside": {
            bind: function(el, binding) {
                // Define ourClickEventHandler
                const ourClickEventHandler = event => {
                    if (!el.contains(event.target) && el !== event.target && event.target.type != 'text' && event.target.tagName != 'svg') {
                        // as we are attaching an click event listern to the document (below)
                        // ensure the events target is outside the element or a child of it
                        binding.value(event); // before binding it
                    }
                };
                // attached the handler to the element so we can remove it later easily
                el.__vueClickEventHandler__ = ourClickEventHandler;

                // attaching ourClickEventHandler to a listener on the document here
                document.addEventListener("click", ourClickEventHandler);
            },
            unbind: function(el) {
                // Remove Event Listener
                document.removeEventListener("click", el.__vueClickEventHandler__);
            }
        }
    },
    data(){
        return{
            filteredNetworks: [],
            blockList: [],
            selectedNet: {},
            showDropdown: false,
            queryNet:null,
            modalBlacklist: false,
            block_note: null,
            networksList: [],

        }
    },
    watch:{
        'queryNet': function() {
            this.showDropdown = true;
            var results = [];
            this.networks.filter(item => {
                if((item.name.toLowerCase().indexOf(this.queryNet.toLowerCase()) > -1) || (item.asn.toString().indexOf(this.queryNet.toLowerCase()) > -1)){
                    results.push(item);
                }
            });

            return this.filteredNetworks = results;
        },

        'networkId': function() {
            if(this.networkId){
                this.filteredNetworks = this.networks
                this.getBlackListItems(this.user.id, this.networkId);
            }
            
        }
    },
    created(){
        this.filteredNetworks = this.networks
        if(this.networkId){
            this.getBlackListItems(this.user.id, this.networkId);
        }
    },
    methods:{
        async searchNet(){
            if(this.networkId){
                this.showDropdown = true;
                if(this.queryNet != '' && this.queryNet.length > 2){
                    // this.error_search_net = null;
                    await this.getListOfNetworks(this.user.id, this.queryNet);
                }else{
                    this.showDropdown = false;
                    this.filteredNetworks = [];
                    // this.error_search_net = 'Min. 3 characters required!';
                }
            }
        },
        async getListOfNetworks(user_id, query){
            await NetService.getListOfNets(user_id, this.networkId, query).then(response => {
                this.networksList = response.data;
                this.filteredNetworks = this.networksList;
                
            }).catch(error => {
                if(error.response.status === 401){
                    this.$store.dispatch('logout');
                    this.$router.push('/')
                }
                if( (error.response.status !== 500 && error.response.status !== 400) && error.response.data.error){
                    Vue.$toast.error(error.response.data.error, {
                        position: 'top-right'
                    });
                }else if(error.response.status !== 500 && error.response.status !== 400){
                    Vue.$toast.error(error.response.data, {
                        position: 'top-right'
                    });
                }else if(error.response.status === 500 || error.response.status == 400){
                    Vue.$toast.error('Oooops! Something went wrong!', {
                        position: 'top-right'
                    });
                }
            });
        },
        confirmBlock(value){
            if(value && this.networkId){
                this.queryNet = '';
                this.filteredNetworks = this.networks;

                var formData = new FormData;
                formData.append('to_network', this.selectedNet.id);
                formData.append('note', this.block_note);

                PeerSettingsService.addToBlacklist(this.user.id, this.networkId, formData).then(response => {
                    if(response.data){
                        this.blockList.push(this.selectedNet);
                        this.selectedNet = {};
                    }
                
                }).catch(error => {
                   if(error.response.data.error){
                        Vue.$toast.error(error.response.data.error, {
                            position: 'top-right'
                        });
                    }else{
                        Vue.$toast.error('Oooops! Something went wrong!', {
                            position: 'top-right'
                        });
                    }
                });
            }

            this.modalBlacklist = false;
            
        },
        getBlackListItems(user_id, net_id){
            if(net_id){
                
                PeerSettingsService.getBlacklist(user_id, net_id).then(response => {
                    this.blockList = response.data;
                }).catch(error => {
                    if(error.response.status === 401){
                        this.$store.dispatch('logout');
                        this.$router.push('/')
                    }
                    if( (error.response.status !== 500 && error.response.status !== 400) && error.response.data.error){
                        Vue.$toast.error(error.response.data.error, {
                            position: 'top-right'
                        });
                    }else if(error.response.status !== 500 && error.response.status !== 400){
                        Vue.$toast.error(error.response.data, {
                            position: 'top-right'
                        });
                    }else if(error.response.status === 500 || error.response.status == 400){
                        Vue.$toast.error('Oooops! Something went wrong!', {
                            position: 'top-right'
                        });
                    }
                });
            }
        },
        addItemToBlacklist(){
            this.modalBlacklist = true;
        },
        removeItemFromBlackList(id){

            if(id && this.networkId){

                this.queryNet = '';
                this.filteredNetworks = this.networks;

                var formData = new FormData;
                formData.append('id', id);

                PeerSettingsService.removeFromBlacklist(this.user.id, this.networkId, formData).then(response => {
                    if(response.data){
                        var data = response.data;
                        this.blockList = this.blockList.filter(item =>{
                            if(item.id != data.id){
                                return item;
                            }
                        });
                    }

                }).catch(error => {
                    if(error.response.status === 401){
                        this.$store.dispatch('logout');
                        this.$router.push('/')
                    }
                    if( (error.response.status !== 500 && error.response.status !== 400) && error.response.data.error){
                        Vue.$toast.error(error.response.data.error, {
                            position: 'top-right'
                        });
                    }else if(error.response.status !== 500 && error.response.status !== 400){
                        Vue.$toast.error(error.response.data, {
                            position: 'top-right'
                        });
                    }else if(error.response.status === 500 || error.response.status == 400){
                        Vue.$toast.error('Oooops! Something went wrong!', {
                            position: 'top-right'
                        });
                    }
                });
            }

        },
        chooseNet(net){
            this.queryNet = net.name;
            this.selectedNet = net;
        },
        openDropdown(){
            this.showDropdown = true;
        },
        hideDropdown(){
            this.showDropdown = false;
        },
    }
}
</script>