<template src="./template.html"></template>

<style src="./style.css" scoped></style>

<script>
import Vue from 'vue';
import moment from 'moment';
import 'moment-timezone';

// import service
import NotificationSerivce from '../../services/NotificationService';
import NetService from '../../services/NetService';

export default {
    name: "DashboardTopNav",
    directives: {
        "click-outside": {
            bind: function(el, binding) {
                // Define ourClickEventHandler
                const ourClickEventHandler = event => {
                    if ( (!el.contains(event.target) && el !== event.target && event.target.classList.contains('click_set') == false) || (!el.contains(event.target) && el !== event.target && event.target.classList.contains('click_set') == false) ){
                        // as we are attaching an click event listern to the document (below)
                        // ensure the events target is outside the element or a child of it
                        binding.value(event); // before binding it
                    }
                };
                // attached the handler to the element so we can remove it later easily
                el.__vueClickEventHandler__ = ourClickEventHandler;

                // attaching ourClickEventHandler to a listener on the document here
                document.addEventListener("click", ourClickEventHandler);
            },
            unbind: function(el) {
                // Remove Event Listener
                document.removeEventListener("click", el.__vueClickEventHandler__);
            }
        },
        "click-outside-notification": {
            bind: function(el, binding) {
                // Define ourClickEventHandler
                const ourClickEventHandler = event => {
                    if ( !el.contains(event.target) && el !== event.target && event.target.classList.contains('click_not') == false && event.target.tagName != 'path'){
                        // as we are attaching an click event listern to the document (below)
                        // ensure the events target is outside the element or a child of it
                        binding.value(event); // before binding it
                    }
                };
                // attached the handler to the element so we can remove it later easily
                el.__vueClickEventHandler__ = ourClickEventHandler;

                // attaching ourClickEventHandler to a listener on the document here
                document.addEventListener("click", ourClickEventHandler);
            },
            unbind: function(el) {
                // Remove Event Listener
                document.removeEventListener("click", el.__vueClickEventHandler__);
            }
        },
         "click-outside-search": {
            bind: function(el, binding) {
                // Define ourClickEventHandler
                const ourClickEventHandler = event => {
                    if (!el.contains(event.target) && el !== event.target && event.target.type != 'text' && event.target.classList.contains('search') != true) {
                        // as we are attaching an click event listern to the document (below)
                        // ensure the events target is outside the element or a child of it
                        binding.value(event); // before binding it
                    }
                };
                // attached the handler to the element so we can remove it later easily
                el.__vueClickEventHandler__ = ourClickEventHandler;

                // attaching ourClickEventHandler to a listener on the document here
                document.addEventListener("click", ourClickEventHandler);
            },
            unbind: function(el) {
                // Remove Event Listener
                document.removeEventListener("click", el.__vueClickEventHandler__);
            }
        }
    },
    data(){
        return{
            user: {},
            networks: [],
            all_networks:[],
            selectedNet: (this.$store.getters.user.networks) ? this.$store.getters.user.networks[0].peeringdb_network_id : null,
            userOptions: false,
            new_notifictions: [],
            total_new_notifications: 0,
            notification_div: false,
            notifications_timeout: null,
            search_network: '',
            error_search_net: null,
            no_net_res: false,
            showDropdownNet: false,
            filteredNet: null
        }
    },
    created(){
        // get user first net_id
        if(this.$store.getters.user.id){
            if(localStorage.getItem('activeNet')){
                this.selectedNet = localStorage.getItem('activeNet');
            }
            this.user  = {
                id: this.$store.getters.user.id,
                name: this.$store.getters.user.name,
            };
        }
        this.networks = this.$store.getters.user.networks;

        if(this.networks){
            this.networks.sort(function(a, b) {
                return a.name.localeCompare(b.name);
            }); 
        }
        if(this.selectedNet){
            this.notifications(this.user.id, this.selectedNet);
            this.notifications_timeout = setInterval( () => {
                this.notifications(this.user.id, this.selectedNet);
            }, 30000);
        }
       
    },
    beforeDestroy(){
        clearInterval(this.notifications_timeout);
    },
    methods:{
        openSideBar(){
            var sidebar = document.getElementById('siderBarMain');
            if(sidebar.classList.contains('hidden') ) {
                sidebar.classList.remove('hidden');
            }else{
                sidebar.classList.add('hidden');
            }
        },
        selectNet(net){
            if(this.$router.name == 'NetworkProfile'){
                this.$router.push({
                    params:  { id: net.id },
                });
            }else{
                this.$router.push({
                    name: 'NetworkProfile',
                    params:  { id: net.id },
                });
            }
        },
         hideDropdownNet(){
            this.showDropdownNet = false;
        },
        async findNetwork(){
            this.showDropdownNet = true;
            if(this.search_network != '' && this.search_network.length > 2){
                this.error_search_net = null;
                await this.getListOfNetworks(this.user.id, this.search_network);
            }else{
                this.showDropdownNet = false;
                this.filteredNet = [];
                this.error_search_net = 'Min. 3 characters required!';
            }
        },
        async getListOfNetworks(user_id, query){
            if(this.selectedNet){
                await NetService.getListOfNets(user_id, this.selectedNet, query).then(response => {
                    this.all_networks = response.data;
                    this.filteredNet = this.all_networks;
                    if(this.all_networks.length == 0){
                        this.no_net_res = true;
                    }else{
                        this.no_net_res = false;
                    }
                    
                }).catch(error => {
                    if(error.response.status === 401){
                        this.$store.dispatch('logout');
                        this.$router.push('/')
                    }
                    if( (error.response.status !== 500 && error.response.status !== 400) && error.response.data.error){
                        Vue.$toast.error(error.response.data.error, {
                            position: 'top-right'
                        });
                    }else if(error.response.status !== 500 && error.response.status !== 400){
                        Vue.$toast.error(error.response.data, {
                            position: 'top-right'
                        });
                    }else if(error.response.status === 500 || error.response.status == 400){
                        Vue.$toast.error('Oooops! Something went wrong!', {
                            position: 'top-right'
                        });
                    }
                });
            }
        },
        formatCreatedAt(date){
            return moment(date).tz("Europe/Berlin").fromNow();
        },
        hideNotifications(){
            this.notification_div = false;
        },
        showNotification(){
            if(this.notification_div){
                this.notification_div = false;
                if(this.new_notifictions.length > 0){
                    this.new_notifictions.forEach(notification => {
                        if(notification.is_read == 0){
                            notification.is_read = 1;
                        }
                    });
                }
               
            }else{
                this.notification_div = true;

                if(this.total_new_notifications > 0){
                    NotificationSerivce.seen(this.user.id, this.selectedNet).then(response => {
                        if(response.status === 201){
                            this.total_new_notifications = 0;
                        }
                    }).catch(error => {
                        if(error.response.data.error){
                            Vue.$toast.error(error.response.data.error, {
                                position: 'top-right'
                            });
                        }else{
                            Vue.$toast.error('Oooops! Something went wrong!', {
                                position: 'top-right'
                            });
                        }
                    });
                }
            }
        },
        notifications(user_id, net_id){
            NotificationSerivce.getNotifications(user_id, net_id).then(response => {
                if(response.data){
                    this.new_notifictions = response.data;
                    this.total_new_notifications = response.data.filter(notification => notification.is_read === 0).length;
                    this.$emit('changed-notification', this.new_notifictions); 
                }
            }).catch(error => {
                if(error.response.status === 401){
                    this.$store.dispatch('logout');
                    this.$router.push('/')
                }else{
                    if(error.response.data.error){
                        Vue.$toast.error(error.response.data.error, {
                            position: 'top-right'
                        });
                    }else{
                        Vue.$toast.error('Oooops! Something went wrong!', {
                            position: 'top-right'
                        });
                    }
                }
            });
        },
        hideUserOptions(){
            this.userOptions = false;
        },
        showUserOptions(){
            if(this.userOptions == true){
                this.userOptions = false;
            }else{
                this.userOptions = true;
            }
            
        },
        changeNetwork(event){
            
            this.notifications(this.user.id, this.selectedNet);
            this.notifications_timeout = setInterval( () => {
                this.notifications(this.user.id, this.selectedNet);
            }, 30000);

            this.selectedNet = event.target.value;
            localStorage.setItem('activeNet', event.target.value);
            this.$emit('changed-network', event.target.value); 
            this.$emit('changed-notification', this.new_notifictions); 
        },
        async logout(){
           //localStorage.removeItem('user');
           //localStorage.removeItem('token');
           await this.$store.dispatch('logout');
           this.$router.push('/');
        }
    }
}
</script>