<template src="./template.html"></template>
<style src="./style.css" scoped></style>

<script>
import Vue from 'vue';
import TopNav from '../../components/TopNavbarComponent/TopNavbarComponent';
import HomeFooter from '../../components/HomeFooterComponent/HomeFooterComponent';

import userService from '../../services/user/UserService';
import emailService from '../../services/EmailService';

import config from '../../config.json';

export default {
    name: "Home",
    components:{
        TopNav,
        HomeFooter
    },
    data(){
        return {
            oauth_code: null,
            loading: false,
            name: '',
            email: '',
            message: '',
            email_error: null,
            name_error: null,
            message_error: null,
        }
    },
    methods:{
        validateEmail(email){
            var re = /\S+@\S+\.\S+/;
            return re.test(email);
        },
        sendMessage(){

            var button_send = document.getElementById('send');
            button_send.classList.remove('bg-pmgrorange');
            button_send.classList.add('bg-gray-600');
            button_send.disabled = true;

            var spin_loader = document.getElementsByClassName('animate-spin')[0];
            spin_loader.classList.remove('hidden');

            if(this.name != '' && this.email != '' && this.message != ''){

                const checkEmail = this.validateEmail(this.email);
                if(!checkEmail){
                    this.email_error = 'Email is not valid!';
                    button_send.classList.add('bg-pmgrorange');
                    button_send.classList.remove('bg-gray-600');
                    button_send.disabled = false;
                    spin_loader.classList.add('hidden');
                    return false;
                }
            }else{
                if(this.name == ''){
                    this.name_error = 'This field is required!';
                }
                if(this.email == ''){
                    this.email_error = 'This field is required!';
                }
                if(this.message == ''){
                    this.message_error = 'This field is required!';
                }
                button_send.classList.add('bg-pmgrorange');
                button_send.classList.remove('bg-gray-600');
                button_send.disabled = false;
                spin_loader.classList.add('hidden');
                return false;
            }

            const formData = new FormData;
            formData.append('name', this.name);
            formData.append('email', this.email);
            formData.append('message', this.message);

            emailService.sendMessage(formData).then(response => {
                if(response.data){
                    Vue.$toast.success('Message sent!', {
                        position: 'top'
                    });

                    this.message_error = null;
                    this.name_error = null;
                    this.email_error = null;

                    this.email = '';
                    this.name = '';
                    this.message = '';
                     button_send.classList.add('bg-pmgrorange');
                    button_send.classList.remove('bg-gray-600');
                    button_send.disabled = false;
                    spin_loader.classList.add('hidden');
                }
            }).catch(error => {
                button_send.classList.add('bg-pmgrorange');
                button_send.classList.remove('bg-gray-600');
                button_send.disabled = false;
                spin_loader.classList.add('hidden');
                if(error.response.data.error){
                    Vue.$toast.error(error.response.data.error, {
                        position: 'top-right'
                    });
                }else{
                    if(error.response.status == 500){
                        Vue.$toast.error('Oooops! Something went wrong!', {
                            position: 'top-right'
                        });
                    }else{
                        if(error.response.data.errors){
                             Vue.$toast.error(error.response.data.errors, {
                                position: 'top-right'
                            });
                        }
                    }
                }
            })

        },
        startOAuth(){
            window.location.href = config.peeringdb_authorization_url;
        },
        getUser(){
            return new Promise( (resolve) => {
                return userService.getMe().then(response => {
                    resolve(response);
                }).catch(error => {
                    resolve(error);
                });
            });
        },
        async oauthCode(code){
            this.oauth_code = code;
            localStorage.setItem('auth_code', code);
            const formData = {
                code: this.oauth_code,
            };
            this.loading = true;
            const checkUser = await this.getUser();
            if(checkUser.status != 200){
                this.$store.dispatch('loginAction', formData)
                .then(response => { 
                    if(response.data.status  == '401'){
                        Vue.$toast.error('Not authorized!', {
                            position: 'top-right'
                        });
                        this.loading = false;
                    }else{
                        this.loading = false;
                        this.$router.push('/dashboard').catch(error => console.error(error));
                    }
                
                })
                .catch(error =>{ 
                    if(error.response.data.error){
                        Vue.$toast.error(error.response.data.error, {
                            position: 'top-right'
                        });
                    }else{
                        if(error.response.status == 500){
                            this.startOAuth();
                        }else{
                             Vue.$toast.error('Oooops! Something went wrong!', {
                                position: 'top-right'
                            });
                        }
                       
                    }
                })
            }else{
                this.loading = false;
                this.$router.push('/dashboard')
            }
        },
    }
}
</script>