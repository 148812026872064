<template src="./template.html"></template>

<style src="./style.css" scoped></style>

<script>
import Vue from 'vue';

import NetworkSettingsService from '../../../services/user/NetworkSettings';

export default {
    name: 'SandboxSettings',
     props: [
        'user',
        'networkId',
        'networks'
    ],
    data(){
        return{
            update_modal: false,
            sandbox_workflow: false,
            current_settings_sandbox: false,
        }
    },
    watch:{
        'networkId': function() {
            if(this.networkId){
                this.getNetworkSettings(this.user.id, this.networkId);
            }
        }
    },
    created(){
        if(this.networkId){
            this.getNetworkSettings(this.user.id, this.networkId);
        }
    },
    methods:{
        getNetworkSettings(user_id, net_id){
            if(net_id){
                NetworkSettingsService.getNetworkSettings(user_id, net_id).then(response => {
                    if(response.data){
                        this.current_settings_sandbox = (response.data.new_sessions_to_sandbox == 1) ? true : false;
                        this.sandbox_workflow = (response.data.new_sessions_to_sandbox == 1) ? true : false;
                    }else{
                        this.current_settings_sandbox = false;
                    }
                }).catch(error => {
                    if(error.response.status === 401){
                        this.$store.dispatch('logout');
                        this.$router.push('/')
                    }
                    if( (error.response.status !== 500 && error.response.status !== 400) && error.response.data.error){
                        Vue.$toast.error(error.response.data.error, {
                            position: 'top-right'
                        });
                    }else if(error.response.status !== 500 && error.response.status !== 400){
                        Vue.$toast.error(error.response.data, {
                            position: 'top-right'
                        });
                    }else if(error.response.status === 500 || error.response.status == 400){
                        Vue.$toast.error('Oooops! Something went wrong!', {
                            position: 'top-right'
                        });
                    }
                });
            }
            
        },
        enableSandboxWorkflow(){

            if(this.networkId){
                const formData = new FormData();
                formData.append('new_sessions_to_sandbox', this.sandbox_workflow);

                NetworkSettingsService.saveSandboxWorkflow(this.user.id, this.networkId, formData).then(response => {
                    if(response.data){
                        this.sandbox_workflow = response.data.new_sessions_to_sandbox;
                    }
                    this.update_modal = false;
                
                }).catch(error => {
                    if(error.response.status === 401){
                        this.$store.dispatch('logout');
                        this.$router.push('/')
                    }
                    if( (error.response.status !== 500 && error.response.status !== 400) && error.response.data.error){
                        Vue.$toast.error(error.response.data.error, {
                            position: 'top-right'
                        });
                    }else if(error.response.status !== 500 && error.response.status !== 400){
                        Vue.$toast.error(error.response.data, {
                            position: 'top-right'
                        });
                    }else if(error.response.status === 500 || error.response.status == 400){
                        Vue.$toast.error('Oooops! Something went wrong!', {
                            position: 'top-right'
                        });
                    }
                });
            }
        },
        openUpdateModal(){
            this.update_modal = true;
        },
        hideUpdateModal(){
            this.update_modal = false;
            this.sandbox_workflow = this.current_settings_sandbox;
        }
    }
}
</script>