import axios from 'axios'
import config from '../config.json'
import {cookieMixin} from '../mixins/cookieMixin';

export const apiClient = axios.create({
    baseURL: config.backendApiUrl,
    withCredentials: false,
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        // TODO uncomment this line if website use some JWT authentication
         Authorization: {
             toString() {
                //  if(localStorage.getItem('token')){
                //     return `${localStorage.getItem('token')}`
                //  }
                return cookieMixin.getCookie('token');
            }
         },
         Code: {
            toString() {
                if(localStorage.getItem('auth_code')){
                    return `${localStorage.getItem('auth_code')}`
                }
            }
        }
    },
    timeout: 100000
});
