<template src="./template.html"></template>

<script>
import Vue from 'vue';

// import services
import NetService from '../../services/NetService';
import RequestService from '../../services/user/RequestService';
import ConfigService from '../../services/user/ConfigService';
import RouterService from '../../services/user/RouterService';

export default {
    name: 'PeeredCard',
    props:[
        'peered', 
        'selectedIx', 
        'networkId',
        'selectedPolicyV4', 
        'selectedPolicyV6', 
        'selectedPolicyV4_out', 
        'selectedPolicyV6_out',
        'user'
    ],
    directives: {
        "click-outside": {
            // bind: function(el, binding) {
            //     // Define ourClickEventHandler
            //     const ourClickEventHandler = event => {
            //         if (!el.contains(event.target) && el !== event.target && event.target.classList.contains('click_peer') == false && event.target.tagName != 'path') {
            //             // as we are attaching an click event listern to the document (below)
            //             // ensure the events target is outside the element or a child of it
            //             binding.value(event); // before binding it
            //         }
            //     };
            //     // attached the handler to the element so we can remove it later easily
            //     el.__vueClickEventHandler__ = ourClickEventHandler;

            //     // attaching ourClickEventHandler to a listener on the document here
            //     document.addEventListener("click", ourClickEventHandler);
            // },
            // unbind: function(el) {
            //     // Remove Event Listener
            //     document.removeEventListener("click", el.__vueClickEventHandler__);
            // }
        }
    },
    data(){
        return{
            md5Password: null,
            md5PasswordNetId: null,
            modalMd5: false,
            new_prefix_4: null,
            new_prefix_6: null,
            infoPrefix6: false,
            infoPrefix4: false,
            selectedDePeer: null,
            de_peer_note: null,
            modalDePeerRequest: false,
            modalConfig: false,
            peerConfiguration: null,
            router_types: [],
            selected_router_type: 2,
            selected_peering_id: null,
            configs: null,
        }
    },
    created(){
        this.routerTypes();
    },
    methods:{
        doCopy: function () {
            var conf_text = document.getElementById('router_single_config').innerText;
            this.$copyText(conf_text).then(function () {
                alert('Copied')
            }, function () {
                alert('Can not copy')
            })
        },
        expendCard(event){
            var expend_btn = event.target.parentNode;
            var collapse_btn = event.target.parentNode;
            
            if(expend_btn.getElementsByClassName('peered_expend')[0].classList.contains('hidden')){
                expend_btn.getElementsByClassName('peered_expend')[0].classList.remove('hidden');
                collapse_btn.getElementsByClassName('peered_collapse')[0].classList.add('hidden');
            }else{
                expend_btn.getElementsByClassName('peered_expend')[0].classList.add('hidden');
                collapse_btn.getElementsByClassName('peered_collapse')[0].classList.remove('hidden');
            }
            
            var element = event.target.parentNode.parentNode;
            var ipv4_show = element.getElementsByClassName('policy_ipv4')[0];
            var ipv6_show = element.getElementsByClassName('policy_ipv6')[0];
            
            if(ipv4_show.classList.contains('hidden')){
                ipv4_show.classList.remove('hidden');
                ipv6_show.classList.remove('hidden');
            }else{
                ipv4_show.classList.add('hidden');
                ipv6_show.classList.add('hidden');
            }
           
        },
        configIxChange(){
            if(this.selected_router_type){
                ConfigService.getPeerConfig(this.user.id, this.selectedIx, this.networkId, this.selected_router_type, this.selected_peering_id).then(response => {
                    this.peerConfiguration = response.data;
                }).catch(error => {
                    if(error.response.data.error){
                        Vue.$toast.error(error.response.data.error, {
                            position: 'top-right'
                        });
                    }else{
                        Vue.$toast.error('Oooops! Something went wrong!', {
                            position: 'top-right'
                        });
                    }
                });
            }
        },
        async routerTypes(){
           await RouterService.getRouterTypes().then(response => {
                this.router_types = response.data;
                 this.router_types.sort(function(a, b) {
                    return a.name.localeCompare(b.name);
                });
            }).catch(error =>{
                if(error.response.status === 401){
                    this.$store.dispatch('logout');
                    this.$router.push('/')
                }
                if( (error.response.status !== 500 && error.response.status !== 400) && error.response.data.error){
                    Vue.$toast.error(error.response.data.error, {
                        position: 'top-right'
                    });
                }else if(error.response.status !== 500 && error.response.status !== 400){
                    Vue.$toast.error(error.response.data, {
                        position: 'top-right'
                    });
                }else if(error.response.status === 500 || error.response.status == 400){
                    Vue.$toast.error('Oooops! Something went wrong!', {
                        position: 'top-right'
                    });
                }
            });
        },
        hidePeerSettings(){
            var close_settings = document.getElementsByClassName('closePeerSettings');
            var settings = document.getElementsByClassName('peerSettings');
            close_settings.forEach(element => {
                element.classList.add('hidden');
            });

             settings.forEach(element => {
                element.classList.remove('hidden');
            });
        },
        closeConfig(){
            this.modalConfig = false;
            this.$emit('modal-change', this.modalConfig);
            this.peerConfiguration = [];
        },
        peerConfig(peering_id){
            this.modalConfig = true;
            this.$emit('modal-change', this.modalConfig);
            this.peerConfiguration = null;
            this.selected_peering_id = peering_id;
            ConfigService.getPeerConfig(this.user.id, this.selectedIx, this.networkId, this.selected_router_type, peering_id).then(response => {
                this.peerConfiguration = response.data;
            }).catch(error => {
                if(error.response.status === 401){
                    this.$store.dispatch('logout');
                    this.$router.push('/')
                }
                if( (error.response.status !== 500 && error.response.status !== 400) && error.response.data.error){
                    Vue.$toast.error(error.response.data.error, {
                        position: 'top-right'
                    });
                }else if(error.response.status !== 500 && error.response.status !== 400){
                    Vue.$toast.error(error.response.data, {
                        position: 'top-right'
                    });
                }else if(error.response.status === 500 || error.response.status == 400){
                    Vue.$toast.error('Oooops! Something went wrong!', {
                        position: 'top-right'
                    });
                }
            });
        },
        confirmDialog(value){
            if(value){
                const formData = new FormData;
                formData.append('peering_ids', this.selectedDePeer);
                formData.append('de_peer_note', this.de_peer_note);
                
                RequestService.dePeerSelected(this.user.id, this.networkId, formData).then(response =>{

                    if(response.data){
                        // show user message
                        Vue.$toast.success('Peer deleted!',{
                            position: 'top-right'
                        });

                        this.$emit('de-peer-selected', response.data);

                    }
                }).catch(error => {
                    if(error.response.data.error){
                        Vue.$toast.error(error.response.data.error, {
                            position: 'top-right'
                        });
                    }else{
                        Vue.$toast.error('Oooops! Something went wrong!', {
                            position: 'top-right'
                        });
                    }
                });
            }   
            this.selectedDePeer = null;
            this.modalDePeerRequest = false;
            this.$emit('modal-change', this.modalDePeerRequest);
        },
        removePeering(peering_id){
            this.selectedDePeer = peering_id;
            this.modalDePeerRequest = true;
            this.$emit('modal-change', this.modalDePeerRequest);
        },
        closePeerSettings(event){
            var parent_element = ''; 

            if(event.target.parentNode.parentNode.parentNode.parentNode.id == 'settings'){
                parent_element = event.target.parentNode.parentNode;
            }else if(event.target.parentNode.parentNode.parentNode.id == 'settings'){
                parent_element = event.target.parentNode.parentNode.parentNode;
            }else if(event.target.parentNode.parentNode.id == 'settings'){
                parent_element = event.target.parentNode.parentNode.parentNode;
            }
            
            if(parent_element != ''){
                var open_peer_settings = parent_element.getElementsByClassName('peerSettings')[0];
                var close_peer_settings = parent_element.getElementsByClassName('closePeerSettings')[0];

                if(close_peer_settings.classList.contains('hidden') == false){
                    open_peer_settings.classList.remove('hidden');
                    close_peer_settings.classList.add('hidden');
                }
            }
            
        },
        openPeerSettings(event){
            var parent_element = '';
            if(event.target.parentNode.parentNode.id == 'settings'){
                parent_element = event.target.parentNode.parentNode;
            }else if(event.target.parentNode.parentNode.parentNode.id == 'settings'){
                parent_element = event.target.parentNode.parentNode.parentNode;
            }
           
            if(parent_element != ''){
                var open_peer_settings = parent_element.getElementsByClassName('peerSettings')[0];
                var close_peer_settings = parent_element.getElementsByClassName('closePeerSettings')[0];

                if(open_peer_settings.classList.contains('hidden')  == false){
                    open_peer_settings.classList.add('hidden');
                    close_peer_settings.classList.remove('hidden');
                }
            }

        },
        updateMd5Password(event){

            var button = event.target;
            button.disabled = true;
            var button_text = document.getElementsByClassName('text_md5_update')[0];
            button_text.classList.add('hidden');
            var animate_spiner = document.getElementsByClassName('animate-spin')[0];
            animate_spiner.classList.remove('hidden');

            var formData = new FormData;
            formData.append('request_from_netID', this.networkId);
            formData.append('request_to_netID', this.md5PasswordNetId);
            formData.append('md5_password', this.md5Password);
            formData.append('ix_id', this.selectedIx);

            NetService.updateMd5Pass(this.user.id,this.networkId, formData).then(response => {

                if(response.data){
                    Vue.$toast.success('Updated!',{
                        position: 'top-right'
                    });

                    this.peered.map(item => {
                        if(item.net_id == response.data.to_network || item.net_id == response.data.from_network){
                            item.md5_password = response.data.md5_password;
                        }
                    })
                    button.disabled = true;
                    button_text.classList.add('hidden');
                    animate_spiner.classList.add('hidden');

                    this.closeMd5();
                    this.$emit('peered-nets', response.data);
                }
               
            }).catch(error => {
                if(error.response.status === 401){
                    this.$store.dispatch('logout');
                    this.$router.push('/')
                }
                if( (error.response.status !== 500 && error.response.status !== 400) && error.response.data.error){
                    Vue.$toast.error(error.response.data.error, {
                        position: 'top-right'
                    });
                }else if(error.response.status !== 500 && error.response.status !== 400){
                    Vue.$toast.error(error.response.data, {
                        position: 'top-right'
                    });
                }else if(error.response.status === 500 || error.response.status == 400){
                    Vue.$toast.error('Oooops! Something went wrong!', {
                        position: 'top-right'
                    });
                }
                button.disabled = true;
                button_text.classList.remove('hidden');
                animate_spiner.classList.add('hidden');
            });
        },
       async openMd5(password, network_id){
           if(password){
               this.md5Password = password;
           }
            this.md5PasswordNetId = network_id;
            this.modalMd5 = true;
            this.$emit('modal-change', this.modalMd5);
        },
        closeMd5(){
            this.modalMd5 = false;
            this.$emit('modal-change', this.modalMd5);
        },
        showInfoPrefix6(event){
            var parent = event.target.parentNode;
            var child = parent.childNodes[3];
            if(child.classList.contains('hidden')){
                child.classList.remove('hidden');
            }
        },
        hideInfoPrefix6(event){
            var parent = event.target.parentNode;
            var child = parent.childNodes[3];
            if(!child.classList.contains('hidden')){
                child.classList.add('hidden');
            }
        },
        showInfoPrefix4(event){
            var parent = event.target.parentNode;
            var child = parent.childNodes[3];
            if(child.classList.contains('hidden')){
                child.classList.remove('hidden');
            }
        },
        hideInfoPrefix4(event){
           var parent = event.target.parentNode;
            var child = parent.childNodes[3];
            if(!child.classList.contains('hidden')){
                child.classList.add('hidden');
            }
        },
        updateRequest(peering_id){

            const formData = new FormData;

            formData.append('net_id', this.networkId);
            formData.append('peering_id', peering_id);
            formData.append('policy_v4', this.selectedPolicyV4[peering_id]);
            formData.append('policy_v6', this.selectedPolicyV6[peering_id]);
            formData.append('policy_v4_out', this.selectedPolicyV4_out[peering_id]);
            formData.append('policy_v6_out', this.selectedPolicyV6_out[peering_id]);
            // formData.append('to_email', this.request_email); // for production
            formData.append('ix_id', this.selectedIx);

            var confirmText = "Confirm update policy?";
            if(confirm(confirmText)) {

                RequestService.updateRequest(this.user.id, this.networkId,formData).then(response => {
                    if(response.data && response.data.from_network){
                        Vue.$toast.success('Policy updated!',{
                            position: 'top-right'
                        });
                        this.$emit('peered-nets', response.data);
                    }
                }).catch(error => {
                    if(error.response.data.error){
                        Vue.$toast.error(error.response.data.error, {
                            position: 'top-right'
                        });
                    }else{
                        Vue.$toast.error('Oooops! Something went wrong!', {
                            position: 'top-right'
                        });
                    }
                });
            }
        },

        updatePp4(request_to_netID, event){

            var parentDiv = event.target.parentNode.parentNode;
            var childNode = parentDiv.getElementsByClassName('prefix4')[0];
            this.new_prefix_4 = childNode.value;

            const formData = new FormData;
            formData.append('request_to_netID', request_to_netID);
            formData.append('request_from_netID', this.networkId);
            formData.append('ix_id', this.selectedIx);
            formData.append('new_prefix_4', this.new_prefix_4);

            var confirmText = "Confirm update prefix 4?";
            if(confirm(confirmText)) {

                NetService.updatePeeringPrefix4(this.user.id,this.networkId, formData).then(response => {
                    if(response.data && response.data.from_network){
                        Vue.$toast.success('Updated!',{
                            position: 'top-right'
                        });
                        this.$emit('peered-nets', response.data);
                    }
                }).catch(error => {
                    if(error.response.data.error){
                        Vue.$toast.error(error.response.data.error, {
                            position: 'top-right'
                        });
                    }else{
                        Vue.$toast.error('Oooops! Something went wrong!', {
                            position: 'top-right'
                        });
                    }
                });
            }
        },

        updatePp6(request_to_netID, event){

            var parentDiv = event.target.parentNode.parentNode;
            var childNode = parentDiv.getElementsByClassName('prefix6')[0];

            this.new_prefix_6 = childNode.value;

            const formData = new FormData;

            formData.append('request_to_netID', request_to_netID);
            formData.append('request_from_netID', this.networkId);
            formData.append('ix_id', this.selectedIx);
            formData.append('new_prefix_6', this.new_prefix_6);

            var confirmText = "Would you like to update prefix6?";
            if(confirm(confirmText)) {

                NetService.updatePeeringPrefix6(this.user.id,this.networkId, formData).then(response => {
                    if(response.data && response.data.from_network){
                        Vue.$toast.success('Updated!',{
                            position: 'top-right'
                        });
                        this.$emit('peered-nets', response.data);
                    }
                }).catch(error => {
                  if(error.response.data.error){
                        Vue.$toast.error(error.response.data.error, {
                            position: 'top-right'
                        });
                    }else{
                        Vue.$toast.error('Oooops! Something went wrong!', {
                            position: 'top-right'
                        });
                    }
                });
            }
        },
    }
}
</script>