<template src="./template.html"></template>

<script>
export default {
    name: "PublicPolicyTable",
    props: ['public_policies'],
    data(){
        return{
            policies: []
        }
    },
    watch:{
        'public_policies': function(){
            this.policies = this.public_policies;
        }   
    },
    created(){
        this.policies = this.public_policies;
    },
    methods:{
        editPublicPolicy(id){
            this.$emit('edit-public-policy', id);
        },
        deletePublicPolicy(id){
            this.$emit('delete-public-policy', id);
        }
    }
}
</script>