<template src="./template.html"></template>

<style src='./style.css'></style>

<script>
import Editor from '@tinymce/tinymce-vue'

export default {
    name: 'TextEditor',
    props: ['email_templates', 'edit_email_name', 'edit_email_content'],
    components:{
        editor: Editor
    },
    data(){
        return{
            email_content: '',
            email_name: ''
        }
    },
    methods:{
        createEmailTemplate(){
            if(this.email_content != '' && this.email_name != ''){
                var template = {email_content: this.email_content, email_name: this.email_name};
                this.$emit('email-template-add', template);
                this.email_content = '';
                this.email_name = '';
            }
        }
    }
}
</script>