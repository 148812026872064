<template src="./template.html"></template>

<style src='./style.css'></style>

<script>
import Editor from '@tinymce/tinymce-vue'

export default {
    name: 'TEditor',
    props: ['email_content'],
    components:{
        editor: Editor
    },
    data(){
        return{
            content: this.email_content,
            timer: null,
        }
    },
    watch: {
        email_content: function(newVal) { // watch it
            this.content = newVal;
        },
        content: function(newVal) { // watch it
            if (this.timer) {
                clearTimeout(this.timer);
                this.timer = null;
            }
            this.timer = setTimeout(() => {
                this.$emit('change-email-content', newVal);
            }, 1000);
        },
    }
}
</script>