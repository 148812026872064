<template src="./template.html"></template>

<script>
import Vue from 'vue';

import SessionService from '../../services/user/SessionsService';
import RequestService from "@/services/user/RequestService";

export default {
    name: 'ManualAddSessionTable',
    props: ['user', 'networkId'],
    data(){
        return{
            peer_sessions: [],
            network_routers: [],
            numberOfSelected: 0,
            selectedRows: [],
        }
    },
    created(){
        this.getPeerSesions(this.user.id,this.networkId,this.$route.params.id);
    },
    methods:{
        bulkAddSession(){
            const formData = new FormData;
            formData.append('peers', JSON.stringify(this.selectedRows));

            RequestService.bulkManuallyAddSession(this.user.id, this.networkId, formData).then(response => {
                if(response.data.status == 'error'){
                    Vue.$toast.warning(response.data.message,{
                    position: 'top-right',
                    duration: 3000
                    });
                    
                }else {
                    Vue.$toast.success('Manually session added!', {
                    position: 'top-right'
                    });
                }
                this.getPeerSesions(this.user.id,this.networkId, this.$route.params.id);
                this.selectedRows = [];
                this.deSelectAllCheckbox();
            }).catch(error => {
                if(error.response.status === 401){
                    this.$store.dispatch('logout');
                    this.$router.push('/')
                }
                if( (error.response.status !== 500 && error.response.status !== 400) && error.response.data.error){
                    Vue.$toast.error(error.response.data.error, {
                        position: 'top-right'
                    });
                }else if(error.response.status !== 500 && error.response.status !== 400){
                    Vue.$toast.error(error.response.data, {
                        position: 'top-right'
                    });
                }else if(error.response.status === 500 || error.response.status == 400){
                    Vue.$toast.error('Oooops! Something went wrong!', {
                        position: 'top-right'
                    });
                }
            });
        },
        getPeerSesions(user_id, net_id, peer){
            SessionService.getAllPeerSessions(user_id, net_id, peer).then(response => {
                this.peer_sessions = response.data;

                this.peer_sessions.sort(function(a, b) {
                    return a.ix_name.localeCompare(b.ix_name);
                });
            }).catch(error => {
                if(error.response.status === 401){
                    this.$store.dispatch('logout');
                    this.$router.push('/')
                }
                if( (error.response.status !== 500 && error.response.status !== 400) && error.response.data.error){
                    Vue.$toast.error(error.response.data.error, {
                        position: 'top-right'
                    });
                }else if(error.response.status !== 500 && error.response.status !== 400){
                    Vue.$toast.error(error.response.data, {
                        position: 'top-right'
                    });
                }else if(error.response.status === 500 || error.response.status == 400){
                    Vue.$toast.error('Oooops! Something went wrong!', {
                        position: 'top-right'
                    });
                }
            });
        },
        deSelectAllCheckbox() {
            let columns = document.querySelectorAll('.rowCheckbox');
            this.numberOfSelected = 0;
            columns.forEach(column => {
                column.checked = false
            });
        },
        selectAllCheckbox($event) {
            let columns = document.querySelectorAll('.rowCheckbox');
            this.selectedRows = [];

            if ($event.target.checked == true) {
                columns.forEach(column => {
                    column.checked = true
                    var session = JSON.parse(column.name);
                    this.selectedRows.push(
                        {
                            router_id: parseInt(session.router_id),
                            local_router_id: parseInt(session.local_router_id),
                            ix_id: parseInt(session.ix_id), 
                            network_id: parseInt(session.network_id)
                        }
                    );
                });
            }else{
                columns.forEach(column => {
                    column.checked = false
                });
                this.selectedRows = [];
            }
            this.numberOfSelected = this.selectedRows.length;
        },
        getRowDetail($event, value){
            let rows = this.selectedRows;
            var session = JSON.parse(value);

           if (rows.filter(e => (e.router_id === session.router_id && e.local_router_id === session.local_router_id )).length > 0) {
                rows = rows.filter(e => (e.router_id != session.router_id && e.local_router_id != session.local_router_id));
            }else{
                rows.push(
                        {
                            router_id: parseInt(session.router_id),
                            local_router_id: parseInt(session.local_router_id),
                            ix_id: parseInt(session.ix_id), 
                            network_id: parseInt(session.network_id)
                        }
                    );
            }
            this.selectedRows = rows;
            this.numberOfSelected = this.selectedRows.length;
        },
    }
}
</script>