<template src="./template.html"></template>
<style src="./style.css"></style>

<script>
import Vue from 'vue';

// import Vue from 'vue';
import moment from 'moment';
import 'moment-timezone';
// import components
import DashboardTopNav from "../../components/DashboardTopNavComponent/DashboardTopNavComponent";
import SideBarNavigation from "../../components/SidebarNavigationComponent/SidebarNavigationComponent";
import Footer from '../../components/FooterDashboardComponent/FooterDashboardComponent';

// import services
import SessionsStatsService from '../../services/user/SessionStatsService';
import RequestService from '../../services/user/RequestService';
import ActvityLogStatsService from '../../services/user/ActivityLogService';

export default {
    name: 'Dashboard',
    components:{
        DashboardTopNav,
        SideBarNavigation,
        Footer
    },
    data(){
        return{
            page: null,
            user:{},
            networkId: null,
            network_id: null,
            data: [{value: 0}, {value: 0}],
            dataRequsts: [{value: 0}, {value: 0}],
            grid:{
                verticalLines: true,
                horizontalLines: true
            },
            totalSessions: 0,
            new_requests: 0,
            activitiesLog: [],
            total_activity: 0,
            pmgr_notifications: [],
            userLogingActivity: [],
            totalIpv4AndIpv6: {},
            total_private_sessions: 0,
            total_public_capacity: 0,
            total_public_ix_ports: 0,
            total_private_capacity: 0,
            total_private_ix_ports: 0,
            system_notifications: [],
        }
    },
   async created(){
       this.page = this.$route.name.toLowerCase();
         // get user first net_id
        if(this.$store.getters.user.id){
            if(localStorage.getItem('activeNet')){
                this.networkId = localStorage.getItem('activeNet');
            }else{
                if(this.$store.getters.user.networks[0]){
                    this.networkId  = this.$store.getters.user.networks[0].peeringdb_network_id;
                }
            }
            this.user  = {
                id: this.$store.getters.user.id,
                name: this.$store.getters.user.name,
            };
        }

        this.network_id = this.networkId; 

        if(this.networkId){
            this.getUserAcivities(this.user.id, this.networkId);
            this.getAllSessions(this.user.id, this.networkId);
            this.getAllPrivateSessions(this.user.id, this.networkId);
            this.getNewRequests(this.user.id, this.networkId);
            // User loging information
            this.getUserLogingInfo(this.user.id, this.networkId);
            this.getTotalIpv4AndIpv6(this.user.id, this.networkId);
            this.getPublicCapacity(this.user.id, this.networkId);
            this.getPublicIxPorts(this.user.id, this.networkId);
            this.getPrivateCapacity(this.user.id, this.networkId);
            this.getPrivateIxPorts(this.user.id, this.networkId);
        }
           
    },
    methods:{
        numberWithCommas(x) {
             return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            // return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
        },
         getPrivateCapacity(user_id, net){
            SessionsStatsService.getTotalNumOfPrivateCapacity(user_id, net).then(response => {
                const number = parseInt(response.data[0].total_capacity);
                if(number < 1000){
                    if(number == 0){
                        this.total_private_capacity = number;
                    }else{
                        this.total_private_capacity = number + 'M';
                    }
                    
                }else if(number >= 1000){
                    this.total_private_capacity = number / 1000;
                    if(this.total_private_capacity > 1000){
                        this.total_private_capacity = (Math.round( (this.total_private_capacity / 1000) * 100 + Number.EPSILON ) / 100) + "T";
                    }else{
                        this.total_private_capacity = (Math.round( (this.total_private_capacity) * 100 + Number.EPSILON ) / 100)  + 'G';
                    }
                }
            }).catch(error => {
                if(error.response.status === 401){
                    this.$store.dispatch('logout');
                    this.$router.push('/')
                }
                if( (error.response.status !== 500 && error.response.status !== 400) && error.response.data.error){
                    Vue.$toast.error(error.response.data.error, {
                        position: 'top-right'
                    });
                }else if(error.response.status !== 500 && error.response.status !== 400){
                    Vue.$toast.error(error.response.data, {
                        position: 'top-right'
                    });
                }else if(error.response.status === 500 || error.response.status == 400){
                    Vue.$toast.error('Oooops! Something went wrong!', {
                        position: 'top-right'
                    });
                }
            });
        },
        getPrivateIxPorts(user_id, net){
            SessionsStatsService.getTotalNumOfPrivateIxPorts(user_id, net).then(response => {
                this.total_private_ix_ports = response.data[0].total_ix_ports;
            }).catch(error => {
                if(error.response.status === 401){
                    this.$store.dispatch('logout');
                    this.$router.push('/')
                }
                if( (error.response.status !== 500 && error.response.status !== 400) && error.response.data.error){
                    Vue.$toast.error(error.response.data.error, {
                        position: 'top-right'
                    });
                }else if(error.response.status !== 500 && error.response.status !== 400){
                    Vue.$toast.error(error.response.data, {
                        position: 'top-right'
                    });
                }else if(error.response.status === 500 || error.response.status == 400){
                    Vue.$toast.error('Oooops! Something went wrong!', {
                        position: 'top-right'
                    });
                }
            });
        },
        roundToTwo(num) {    
            return +(Math.round(num + "e+2")  + "e-2");
        },
        getPublicCapacity(user_id, net){
            SessionsStatsService.getTotalNumOfPublicCapacity(user_id, net).then(response => {
                const number = parseInt(response.data[0].total_capacity);
                if(number < 1000){
                     if(number == 0){
                         this.total_public_capacity = number;
                     }else{
                         this.total_public_capacity = number + 'M';
                     }
                    
                }else if(number >= 1000){
                    this.total_public_capacity = number / 1000;
                    if(this.total_public_capacity > 1000){
                        this.total_public_capacity = (Math.round( (this.total_public_capacity / 1000) * 100 + Number.EPSILON ) / 100) + "T";
                    }else{
                        this.total_public_capacity = (Math.round( (this.total_public_capacity) * 100 + Number.EPSILON ) / 100)  + 'G';
                    }
                }
            }).catch(error => {
                if(error.response.status === 401){
                    this.$store.dispatch('logout');
                    this.$router.push('/')
                }
                if( (error.response.status !== 500 && error.response.status !== 400) && error.response.data.error){
                    Vue.$toast.error(error.response.data.error, {
                        position: 'top-right'
                    });
                }else if(error.response.status !== 500 && error.response.status !== 400){
                    Vue.$toast.error(error.response.data, {
                        position: 'top-right'
                    });
                }else if(error.response.status === 500 || error.response.status == 400){
                    Vue.$toast.error('Oooops! Something went wrong!', {
                        position: 'top-right'
                    });
                }
            });
        },
        getPublicIxPorts(user_id, net){
            SessionsStatsService.getTotalNumOfPublicIxPorts(user_id, net).then(response => {
                this.total_public_ix_ports = response.data[0].total_ix_ports;
            }).catch(error => {
                if(error.response.status === 401){
                    this.$store.dispatch('logout');
                    this.$router.push('/')
                }
                if( (error.response.status !== 500 && error.response.status !== 400) && error.response.data.error){
                    Vue.$toast.error(error.response.data.error, {
                        position: 'top-right'
                    });
                }else if(error.response.status !== 500 && error.response.status !== 400){
                    Vue.$toast.error(error.response.data, {
                        position: 'top-right'
                    });
                }else if(error.response.status === 500 || error.response.status == 400){
                    Vue.$toast.error('Oooops! Something went wrong!', {
                        position: 'top-right'
                    });
                }
            });
        },
        getTotalIpv4AndIpv6(user_id, net){
            SessionsStatsService.getSessionsStatsByIpv4AndIpv6(user_id, net).then(response => {
                this.totalIpv4AndIpv6 = response.data[0];
            }).catch(error => {
                if(error.response.status === 401){
                    this.$store.dispatch('logout');
                    this.$router.push('/')
                }
                if( (error.response.status !== 500 && error.response.status !== 400) && error.response.data.error){
                    Vue.$toast.error(error.response.data.error, {
                        position: 'top-right'
                    });
                }else if(error.response.status !== 500 && error.response.status !== 400){
                    Vue.$toast.error(error.response.data, {
                        position: 'top-right'
                    });
                }else if(error.response.status === 500 || error.response.status == 400){
                    Vue.$toast.error('Oooops! Something went wrong!', {
                        position: 'top-right'
                    });
                }
            });
        },
        getUserLogingInfo(user_id, net){
            ActvityLogStatsService.getUserLoging(user_id, net).then(response => {
                this.userLogingActivity = response.data.data;
            }).catch(error => {
                if(error.response.status === 401){
                    this.$store.dispatch('logout');
                    this.$router.push('/')
                }
                if( (error.response.status !== 500 && error.response.status !== 400) && error.response.data.error){
                    Vue.$toast.error(error.response.data.error, {
                        position: 'top-right'
                    });
                }else if(error.response.status !== 500 && error.response.status !== 400){
                    Vue.$toast.error(error.response.data, {
                        position: 'top-right'
                    });
                }else if(error.response.status === 500 || error.response.status == 400){
                    Vue.$toast.error('Oooops! Something went wrong!', {
                        position: 'top-right'
                    });
                }
            });
        },
        formatCreatedAt(date){
            return moment(date).tz("Europe/Berlin").fromNow();
        },
        getUserAcivities(user_id, net){
            ActvityLogStatsService.getUserAcivity(user_id, net).then(response => {
                this.activitiesLog = [];
                this.activitiesLog = response.data.data;
                this.total_activity = response.data.data.length;
            }).catch(error => {
                if(error.response.status === 401){
                    this.$store.dispatch('logout');
                    this.$router.push('/')
                }
                if( (error.response.status !== 500 && error.response.status !== 400) && error.response.data.error){
                    Vue.$toast.error(error.response.data.error, {
                        position: 'top-right'
                    });
                }else if(error.response.status !== 500 && error.response.status !== 400){
                    Vue.$toast.error(error.response.data, {
                        position: 'top-right'
                    });
                }else if(error.response.status === 500 || error.response.status == 400){
                    Vue.$toast.error('Oooops! Something went wrong!', {
                        position: 'top-right'
                    });
                }
            });
        },
        getNewRequests(user_id, net){
            RequestService.getNewRequestsDashboard(user_id, net).then(response => {
                this.new_requests = 0;
                if(response.data.length > 0){
                    response.data.forEach((item, index) => {
                        this.dataRequsts.push({value: index},{value: index + 1});
                        this.new_requests += index + 1;
                    });
                }else{
                    this.dataRequsts.push({value: 0},{value: 0});
                }
            }).catch(error => {
                if(error.response.status === 401){
                    this.$store.dispatch('logout');
                    this.$router.push('/')
                }
                if( (error.response.status !== 500 && error.response.status !== 400) && error.response.data.error){
                    Vue.$toast.error(error.response.data.error, {
                        position: 'top-right'
                    });
                }else if(error.response.status !== 500 && error.response.status !== 400){
                    Vue.$toast.error(error.response.data, {
                        position: 'top-right'
                    });
                }else if(error.response.status === 500 || error.response.status == 400){
                    Vue.$toast.error('Oooops! Something went wrong!', {
                        position: 'top-right'
                    });
                }
            });
        },
        getAllSessions(user_id, net){
            SessionsStatsService.getSessionsDashStats(user_id, net).then(response => {
                this.totalSessions = 0;
                if(response.data.data.length > 0){
                    response.data.data.forEach(item => {
                        this.data.push({value: 0},{value: item.quantity});
                        this.totalSessions += item.quantity;
                    });
                }else{
                    this.data.push({value: 0},{value: 0});
                }
            }).catch(error => {
                if(error.response.status === 401){
                    this.$store.dispatch('logout');
                    this.$router.push('/')
                }
                if( (error.response.status !== 500 && error.response.status !== 400) && error.response.data.error){
                    Vue.$toast.error(error.response.data.error, {
                        position: 'top-right'
                    });
                }else if(error.response.status !== 500 && error.response.status !== 400){
                    Vue.$toast.error(error.response.data, {
                        position: 'top-right'
                    });
                }else if(error.response.status === 500 || error.response.status == 400){
                    Vue.$toast.error('Oooops! Something went wrong!', {
                        position: 'top-right'
                    });
                }
            });
        },
        getAllPrivateSessions(user_id, net){
            SessionsStatsService.getPrivateSessionsDashStats(user_id, net).then(response => {
                this.total_private_sessions = 0;
                if(response.data.data.length > 0){
                    response.data.data.forEach(item => {
                        this.data.push({value: 0},{value: item.quantity});
                        this.total_private_sessions += item.quantity;
                    });
                }else{
                    this.data.push({value: 0},{value: 0});
                }
            }).catch(error => {
                if(error.response.status === 401){
                    this.$store.dispatch('logout');
                    this.$router.push('/')
                }
                if( (error.response.status !== 500 && error.response.status !== 400) && error.response.data.error){
                    Vue.$toast.error(error.response.data.error, {
                        position: 'top-right'
                    });
                }else if(error.response.status !== 500 && error.response.status !== 400){
                    Vue.$toast.error(error.response.data, {
                        position: 'top-right'
                    });
                }else if(error.response.status === 500 || error.response.status == 400){
                    Vue.$toast.error('Oooops! Something went wrong!', {
                        position: 'top-right'
                    });
                }
            });
        },
        notificationsChanged(notifications){
            this.pmgr_notifications = notifications.filter( notification => notification.type == 1);
            this.system_notifications = notifications.filter( notification => notification.type == 2);
        },
        // onChange user net
        async networkChanged(network_id){
            this.network_id = network_id;
            this.networkId = network_id;
            this.getUserAcivities(this.user.id, this.networkId);
            this.getAllSessions(this.user.id, this.networkId);
            this.getAllPrivateSessions(this.user.id, this.networkId);
            this.getNewRequests(this.user.id, this.networkId);
            // User loging information
            this.getUserLogingInfo(this.user.id, this.networkId);
            this.getTotalIpv4AndIpv6(this.user.id, this.networkId);
            this.getPublicCapacity(this.user.id, this.networkId);
            this.getPublicIxPorts(this.user.id, this.networkId);
            this.getPrivateCapacity(this.user.id, this.networkId);
            this.getPrivateIxPorts(this.user.id, this.networkId);
        },
    }
}
</script>