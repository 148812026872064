import UserService from '../../services/user/UserService';
// import user from '../../samples/user.json';

import {cookieMixin} from '../../mixins/cookieMixin';

const state = {
    userProfile: {
        id: (JSON.parse(localStorage.getItem('user'))) ? JSON.parse(localStorage.getItem('user')).id : null,
        email: (JSON.parse(localStorage.getItem('user'))) ? JSON.parse(localStorage.getItem('user')).email : null,
        networks: (JSON.parse(localStorage.getItem('user')) && JSON.parse(localStorage.getItem('user')).networks.length > 0) ? JSON.parse(localStorage.getItem('user')).networks : null,
        name: (JSON.parse(localStorage.getItem('user'))) ? JSON.parse(localStorage.getItem('user')).name : null,
    },
    status: (JSON.parse(localStorage.getItem('user'))) ? 'active' : null,
    token: cookieMixin.getCookie('token') || null,
    error: null
 };
 
 const getters = {
    status (state){
        return state.status;
    },

    user(state){
        return state.userProfile;
    },

    token(state){
        return state.token;
    },

    error(state){
        return state.error;
    },

    authStatus: state => state.status
 };
 
 const actions = {
    loginAction({commit}, payload){
        return new Promise((resolve, reject) => {
            commit('auth_request');
            
            var formData = new FormData;
            formData.append('code', payload.code);

            UserService.login(formData).then(resp => {

                const token = resp.data.token
                commit('setToken', token);
                localStorage.removeItem('auth_code');
                cookieMixin.setCookie('token', token);
                UserService.getMe().then(resp => {
                    const userProfile = resp.data;
                    if(userProfile.networks.length == 0){
                        userProfile.networks = null;
                    }
                    commit('setUser', userProfile);
                    commit('setStatus', 'active');
                    localStorage.setItem('user', JSON.stringify(userProfile));
                    if(localStorage.getItem('activeNet')){
                        localStorage.removeItem('activeNet');
                    }
                    resolve(resp);
    
                }).catch(err => {
                  commit('setError', err)
                  reject(err)
                });
            })
            .catch(err => {
              commit('setError', err)
              reject(err)
            });


          })
    },
    logout({commit}){
        commit('logout')
        // localStorage.removeItem('token');
        cookieMixin.deleteCookies();
        localStorage.removeItem('user');
    }
 };
 
 const mutations = {

    auth_request(state){
        state.status = 'loading';
    },

    setUser(state, payload){
        state.userProfile.id = payload.id;
        state.userProfile.name = payload.name;
        state.userProfile.email = payload.email;
        state.userProfile.networks = payload.networks;
    },
    setToken(state, payload){
        state.token = payload;
    },

    removeUser(state){
        state.userProfile = null;
    },

    setStatus(state, payload){
        state.status = payload;
    },

    setError(state, payload){
        state.error = payload;
    },

    logout(state){
        state.status = null;
        state.token = null;
        state.userProfile = null;
      }
 
 };
 
 export default {
    state,
    getters,
    actions,
    mutations
 }
