<template src='./template.html'></template>

<script>
import Vue from 'vue';

// import services
import RequestService from '../../services/user/RequestService';

export default {
    name: 'PendingCard',
    props: ['pending', 'networkId', 'selectedIx', 'user'],
    data(){
        return{

        }
    },
    methods:{
        cancelRequest(net_id){
            var formData = new FormData;
            formData.append('net_id', net_id);
            formData.append('ix_id', this.selectedIx);

            RequestService.cancelRequest(this.user.id, this.networkId, formData).then(response => {
                if(response.data){
                    Vue.$toast.success('Request deleted!',{
                        position: 'top-right'
                    });
                    this.$emit('pending-nets', response.data);
                }
            }).catch(error => {
                if(error.response.status === 401){
                    this.$store.dispatch('logout');
                    this.$router.push('/')
                }
                if( (error.response.status !== 500 && error.response.status !== 400) && error.response.data.error){
                    Vue.$toast.error(error.response.data.error, {
                        position: 'top-right'
                    });
                }else if(error.response.status !== 500 && error.response.status !== 400){
                    Vue.$toast.error(error.response.data, {
                        position: 'top-right'
                    });
                }else if(error.response.status === 500 || error.response.status == 400){
                    Vue.$toast.error('Oooops! Something went wrong!', {
                        position: 'top-right'
                    });
                }
            });
        },
    }
}
</script>