<template src="./template.html"></template>
<style src="./style.css"></style>

<script>
import config from '../../config.json';

export default {
    name: "TopNavBar",
    props: ['notShowMenu'],
    data(){
        return {
            showMenuLinks: true,
        }
    },
    created(){
        if(this.$route.query.code){
            this.$emit('oauth-code', this.$route.query.code); 
        }
    },
    mounted(){
        if(this.notShowMenu === true){
            this.showMenuLinks = false;
        }else{
            this.showMenuLinks = true;
        }
    },
    methods:{
        openNav(el){
            var nav = document.getElementsByClassName(el)[0];
            if(nav.classList.contains('hidden')){
               nav.classList.remove('hidden'); 
            }else{
                nav.classList.add('hidden');
            }
        },
        startOAuth(){
            window.location.href = config.peeringdb_authorization_url;
        }
    }
}
</script>