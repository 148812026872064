<template src="./template.html"></template>

<script>
import Vue from 'vue';

// import services
import PolicyServices from '../../services/user/PolicyService';

export default {
    name: 'EditSandboxPolicy',
    props:['selected_sandbox_policy', 'networkId', 'user'],
    data(){
        return{
            edit_policy:{},
            sandbox_policy_name: null,
            sandbox_policy_default: false, 
        }
    },
    created(){
        this.edit_policy = this.selected_sandbox_policy;

        this.sandbox_policy_name = this.edit_policy.policy_name;
        this.sandbox_policy_default = this.edit_policy.sandbox_default;
    },
    methods:{
        closeModal(){
            this.$emit('close-sandbox-modal', true);
        },
        updateSandbox(){
            this.sandbox_policy_name = this.sandbox_policy_name.replaceAll(/\s/g,'').toUpperCase();
            const formData = new FormData;
            this.sandbox_policy_default = (this.sandbox_policy_default) ? 1 : 0;
            formData.append('sandbox_policy_name', this.sandbox_policy_name);
            formData.append('sandbox_policy_default', this.sandbox_policy_default);
            formData.append('network_id', this.networkId);

            PolicyServices.updateSandboxPolicy(this.user.id, this.networkId, this.edit_policy.id, formData).then(response => {
                if(response.data && response.status == 200){
                    this.$emit('sandbox-updated', response.data);

                    Vue.$toast.success('Sandbox policy updated!',{
                        position: 'top-right'
                    });
                }
            }).catch(error => {
                if(error.response.status === 401){
                    this.$store.dispatch('logout');
                    this.$router.push('/')
                }
                if( (error.response.status !== 500 && error.response.status !== 400) && error.response.data.error){
                    Vue.$toast.error(error.response.data.error, {
                        position: 'top-right'
                    });
                }else if(error.response.status !== 500 && error.response.status !== 400){
                    Vue.$toast.error(error.response.data, {
                        position: 'top-right'
                    });
                }else if((error.response.status == 400)){
                    Vue.$toast.error(error.response.data.error, {
                        position: 'top-right'
                    });
                }
                else if((error.response.status === 500 || error.response.status == 400)){
                    Vue.$toast.error('Oooops! Something went wrong!', {
                        position: 'top-right'
                    });
                }
            });
        }
    }
}
</script>