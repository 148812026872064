import ApiService from "../ApiService";

export default {
    getUserAcivity(user_id, net_id){
        return ApiService.get(`user/${user_id}/net/${net_id}/activityLog/user/list`);
    },
    getUserAcivityStat(user_id, net_id){
        return ApiService.get(`user/${user_id}/net/${net_id}/activityLog/user/stats`);
    },
    getUserLoging(user_id, net_id){
        return ApiService.get(`user/${user_id}/net/${net_id}/activityLog/user/loging`);
    },
    getAllNetworkLogs(user_id, net_id, for_network, skip){
        return ApiService.get(`user/${user_id}/net/${net_id}/activityLog/network/list/${for_network}?skip=${skip}`);
    },
    getAllLogsForNet(user_id, net_id, skip){
        return ApiService.get(`user/${user_id}/net/${net_id}/activityLog/network/all?skip=${skip}`);
    },
    serchAllNetworkLogs(user_id, net_id, query, skip){
        return ApiService.get(`user/${user_id}/net/${net_id}/activityLog/network/search/${query}?skip=${skip}`);
    },
}