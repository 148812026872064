<template src="./template.html"></template>
<style src="./style.css" scoped></style>

<script>
import user from '../../samples/user.json';

export default {
    name: "SideBarNavigation",
    props: ['page'],
    data(){
        return{
            user: user,
            currentPage: this.page
        }
    },
    watch:{
        'currentPage': function(){
            this.setActive();
        }
    },
    mounted(){
        this.setActive();
    },
    methods:{
        closeSideBar(){
            var sidebar = document.getElementById('siderBarMain');
            if(sidebar.classList.contains('hidden') ) {
                sidebar.classList.remove('hidden');
            }else{
                sidebar.classList.add('hidden');
            }
        },
        setActive(){
            if(this.page != 'profile'){
                 var svgs = document.getElementsByClassName('tooltip');
                for(let i = 0; i < svgs.length; i++){
                    svgs[i].classList.remove('active');
                    svgs[i].classList.remove('active-stroke');
                }
                if(this.currentPage){
                    if(this.currentPage == 'stats'){
                        document.getElementsByClassName(''+this.currentPage+'')[0].classList.add('active-stroke');
                    }else{
                        document.getElementsByClassName(''+this.currentPage+'')[0].classList.add('active');
                    }
                
                }
            } 
        },
        logout(){
           localStorage.removeItem('user');
           localStorage.removeItem('token');
           this.$router.push('/');
        }
    }
}
</script>