<template src="./template.html"></template>

<script>
export default {
    name: "PrivatePolicyTable",
    props: ['private_policies'],
    data(){
        return{
            policies: []
        }
    },
    watch:{
        'private_policies': function(){
            this.policies = this.private_policies;
        }   
    },
    created(){
        this.policies = this.private_policies;
    },
    methods:{
        editPrivatePolicy(id){
            this.$emit('edit-private-policy', id);
        },
        deletePrivatePolicy(id){
            this.$emit('delete-private-policy', id);
        }
    }
}
</script>