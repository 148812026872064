import ApiService from "../services/ApiService";

export default {

    getAllIx(user_id, net) {
        return ApiService.get(`/user/${user_id}/net/${net}/netixs`, net);
    },
    getAllNetsForUser(user_id, net, ix) {
        return ApiService.get(`/user/${user_id}/netix/${ix}/nets`, net);
    },
    requestPeering(user_id, net_id, data){
        return ApiService.post(`/user/${user_id}/net/${net_id}/request/create`, data); 
    },
    updatePeeringPrefix4(user_id, net_id, data){
        return ApiService.post(`user/${user_id}/net/${net_id}/updatePrefix4`, data);
    },
    updatePeeringPrefix6(user_id, net_id, data){
        return ApiService.post(`user/${user_id}/net/${net_id}/updatePrefix6`, data);
    },
    updateMd5Pass(user_id, net_id, data){
        return ApiService.post(`user/${user_id}/net/${net_id}/updateMd5Password`, data);
    },
    getNetInfo(user_id, net, ix){
        return ApiService.get(`/user/${user_id}/netix/${ix}/net`, net); 
    },
    getNetEmail(user_id, net){
        return ApiService.get(`/user/${user_id}/net/${net}/getNetContact`); 
    },
    getPeeringOnAllLocations(user_id, netix_id, from_net, to_net ){
        return ApiService.get(`user/${user_id}/net/${from_net}/netix/${netix_id}/otherLocations/fromNet/${from_net}/toNet/${to_net}`); 
    },
    getListOfNets(user_id, net_id, query){
        return ApiService.get(`/user/${user_id}/net/${net_id}/netList/${query}`); 
    },
    getNetworkInfo(user_id, net_id, id){
        return ApiService.get(`/user/${user_id}/net/${net_id}/networkInfo/${id}`); 
    }
}
