<script>

import { HorizontalBar } from 'vue-chartjs'

export default {
  extends: HorizontalBar,
  props: {
    label:{
      type: String
    },
    chartdata: {
      type: Array,
    },
    options: {
      type: Object,
    }
  },
  watch:{
    chartdata: function(data){
    const names = data.map(d => d.name).reverse();
    const totals = data.map(d => d.total).reverse();

    this.renderChart({
      labels: names,
      datasets: [{
        backgroundColor: '#3898e8',
        label: this.label,
        data: totals
      }]
    },
      this.options
    )
    }
  },
  mounted () {

    const names = this.chartdata.map(d => d.name).reverse();
    const totals = this.chartdata.map(d => d.total).reverse();

    this.renderChart({
      labels: names,
      datasets: [{
        backgroundColor: '#3898e8',
        label: this.label,
        data: totals
      }]
    },
      this.options
    )
  }
}
</script>